import { Route, BrowserRouter, Routes } from 'react-router-dom';

import RequireAuth from '../components/layout/RequireAuth';
import RequireAdmin from '../components/layout/RequireAdmin';
import { useAuth } from '../context/Hooks';

import Layout from './Layout';
import LoginPage from './Auth/Login';
import ResetPasswordPage from './Auth/ResetPassword';
import ForgotPasswordPage from './Auth/ForgotPassword';
// Professional Pages
import RoomsPage from './Rooms';
import RoomDetailPage from './Rooms/Detail';
import BillingPage from './Billing';
import MyReservationsPage from './MyReservations';
import NewReservationPage from './Reservation/New';
import ConfirmReservationPage from './Reservation/Confirm';
import BillingHistoryPage from './Billing/History';
// Admin Pages
import AdminBillingPage from './Admin/Billing';
import AdminUserDetailPage from './Admin/Billing/Detail/User';
import AdminRoomDetailPage from './Admin/Billing/Detail/Room';
import AdminPasswordPage from './Admin/PasswordChange';
import AdminBillingHistoryPage from './Admin/Billing/Detail/History';

const IndexPage = () => {
  const { isAdmin } = useAuth();
  const checkIsAdmin = isAdmin();
  return checkIsAdmin ? <AdminBillingPage /> : <NewReservationPage />;
};
const Pages = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<LoginPage />} path="/login" />
      <Route element={<ForgotPasswordPage />} path="/forgotPassword" />
      <Route element={<ResetPasswordPage />} path="/resetPassword/:token" />
      <Route element={<ResetPasswordPage showWelcomeMessage />} path="/newPassword/:token" />
      <Route
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
        path="/"
      >
        <Route element={<IndexPage />} index />
        <Route element={<NewReservationPage />} path="/reserve/new/" />
        <Route element={<NewReservationPage />} path="/reserve/new/:roomId" />
        <Route element={<ConfirmReservationPage />} path="/reserve/confirm/" />
        <Route element={<RoomsPage />} path="/rooms" />
        <Route element={<RoomDetailPage />} path="/rooms/:id" />
        <Route element={<BillingPage />} path="/billing" />
        <Route element={<BillingHistoryPage />} path="/billing/history" />
        <Route element={<MyReservationsPage />} path="/myReservations" />
        <Route element={<RequireAdmin />} path="admin">
          <Route element={<AdminBillingPage />} path="billing" />
          <Route element={<AdminUserDetailPage />} path="billing/user/:id" />
          <Route element={<AdminBillingHistoryPage fromRoom={false} />} path="billing/user/:id/history" />
          <Route element={<AdminRoomDetailPage />} path="rooms/:id" />
          <Route element={<AdminBillingHistoryPage fromRoom />} path="rooms/:id/history" />
          <Route element={<RoomsPage fromAdmin />} path="rooms" />
          <Route element={<AdminPasswordPage />} path="changePassword" />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Pages;

import styled from 'styled-components';
import { Button } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

const StyledBackgroundContainer = styled.div`
  position: fixed;
  height: 40px;
  width: 100vw;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.primary4};
`;
const StyledButton = styled(Button)`
  border-radius: 31px;
  position: absolute;
  right: 1.5rem;
  bottom: 16px;
  box-shadow: 0px 3px 8px 2px rgb(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 3px 8px 2px rgb(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 8px 2px rgb(0, 0, 0, 0.1);
`;

const StyledButtonLabel = styled.span`
  color: white;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  width: 100%;
  cursor: pointer;
`;

const StyledArrowForwardOutlinedIcon = styled(ArrowForwardOutlinedIcon)`
  color: white;
`;

export { StyledBackgroundContainer, StyledButton, StyledButtonLabel, StyledArrowForwardOutlinedIcon };

import { AnimatePresence } from 'framer-motion';
import { Outlet } from 'react-router-dom';

import LoadingScreen from '../../components/layout/LoadingScreen';
import { useAuth } from '../../context/Hooks';

import Navbar from './Navbar';
import { StyledLayout } from './styles';

const Layout = () => {
  const {
    state: { currentUser },
    isAdmin,
  } = useAuth();

  if (!currentUser) return <LoadingScreen />;

  return (
    <StyledLayout isAdmin={isAdmin()}>
      <Navbar>
        <AnimatePresence exitBeforeEnter>
          <Outlet />
        </AnimatePresence>
      </Navbar>
    </StyledLayout>
  );
};

export default Layout;

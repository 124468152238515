import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  flex-direction: column;
`;

const StyledFixedContainer = styled.div`
  position: fixed;
  z-index: 4;
  background: #fff;
  top: 55px;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 3px 8px 2px rgb(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 3px 8px 2px rgb(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 8px 2px rgb(0, 0, 0, 0.1);
`;

const StyledChipsContainer = styled.div`
  margin-bottom: 1rem;
`;

const StyledSearchBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
`;

/* eslint-disable @typescript-eslint/naming-convention */

const sharedFontStyle = {
  fontFamily: 'Montserrat',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '36px',
  textAlign: 'center',
};
const datePickerStyles = {
  sx: {
    zIndex: 0,
    borderRadius: '10px',
    boxShadow: '0px 3px 8px 2px rgb(0 0 0 / 10%)',

    '& .MuiPickersDay-root': sharedFontStyle,

    '& .MuiPaper-root': {
      width: '100vw',
      borderRadius: '10px',
    },
  },
};

const StyledCalendar = styled.div(
  ({ theme }) => css`
    margin-top: 80px;

    .MuiCalendarPicker-root {
      overflow: hidden;
    }

    .MuiPickersCalendarHeader-label {
      font-family: 'Merryweather';
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      vertical-align: middle;
      text-transform: capitalize;
    }
    .MuiPickersDay-root {
      color: ${theme.colors.neutral2};
    }
    .MuiDayPicker-weekDayLabel {
      color: ${theme.colors.primary2};
    }
    .MuiDayPicker-weekDayLabel,
    .MuiPickersDay-root {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 14px;
      line-height: 36px;
      text-align: center;
    }
  `,
);

/* eslint-enable @typescript-eslint/naming-convention */
export {
  StyledContainer,
  StyledFixedContainer,
  StyledChipsContainer,
  StyledSearchBar,
  datePickerStyles,
  StyledCalendar,
};

import moment from 'moment';

import type { IReservation } from '../../context/Initials/reservations';
import type { ParsedCalendarEventsType, CalendarEventType } from '../api/admin-room-reservations';

// Devuelve un horario incrementado en media hora. EJ recibe 10:00 devuelve 10:30.
const increaseTimeByHalfHour = (time: string, index: number): string => {
  const baseMinutes = 30;
  return moment(time, 'HH:mm')
    .add(baseMinutes * index, 'minutes')
    .format('HH:mm');
};

// Devuelve un array con todos los bloques de turnos seleccionados a partir de lo que se ingreso en el modal. Ej:
const appointmentsFromTimeAndDuration = (time: string, duration: number): string[] => {
  const interval = 0.5;

  const length = duration / interval;

  return Array.from({ length }, (item, index) => increaseTimeByHalfHour(time, index));
};

// Calcula las opciones de duracion (Ej. 1 hora, 1.5 horas, etc) en base a la hora de inicio y toda la disponibilidad inmediata y consecutiva que haya
/* eslint-disable max-statements, fp/no-let,fp/no-loops,fp/no-mutation,fp/no-mutating-methods,@typescript-eslint/no-magic-numbers */
const getDurationOptions = (from: string, availability: string[]): string[] => {
  const fromIndex = availability.indexOf(from);
  const result = [];
  let newDuration = 0.5;
  let count = 1;
  if (fromIndex === -1 || availability[fromIndex + 1] !== increaseTimeByHalfHour(from, count)) return [];
  result.push('1');
  for (let index = fromIndex; index < availability.length; index += 1) {
    if (availability[index + 1] === increaseTimeByHalfHour(from, count)) {
      newDuration += 0.5;
      if (newDuration > 1) result.push(String(newDuration));
    } else break;
    count += 1;
  }
  return result;
};
/* eslint-enable max-statements, fp/no-let,fp/no-loops,fp/no-mutation,fp/no-mutating-methods,@typescript-eslint/no-magic-numbers */

// Elimina los bloques proximos a los margenes para que no sean seleccionables, ya que el minimo es una hora.
const trimLastAvaiabilityBlocks = (availability: string[]): string[] =>
  availability.filter((item, index) => availability[index + 1] === increaseTimeByHalfHour(item, 1));

// Busca y devuelve todos los bloques seleccionados actualmente en un consultorio y fecha determinada.
const getAllSelectedAppointments = (reservations: IReservation[], date: string, room: string): string[] | [] =>
  reservations
    .filter((item) => item.room === room && item.date === date)
    .flatMap((preReservation) => preReservation.appointments);

const isSelected = (selected: string[], value: string) => selected.includes(value);

const parseEvents = (events: CalendarEventType[]): ParsedCalendarEventsType => {
  const trimmedEvents = events.filter((item) => !item.start.includes('00:00:00') && !item.start.includes('06:30:00'));
  return Array.from(new Map(trimmedEvents.map((item) => [item.start, item])).values(), (event) => ({
    ...event,
    start: moment(event.start, moment.ISO_8601).toDate(),
    end: moment(event.end.replace('00:00:00', '23:59:59'), moment.ISO_8601).toDate(),
  }));
};

export {
  increaseTimeByHalfHour,
  appointmentsFromTimeAndDuration,
  getDurationOptions,
  trimLastAvaiabilityBlocks,
  getAllSelectedAppointments,
  isSelected,
  parseEvents,
};

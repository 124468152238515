import type { MyReservationsPayloadType } from './myReservations';
import type { ReservationsPayloadType } from './reservations';

const appActions = {
  startLoading: 'START_LOADING',
  finishLoading: 'FINISH_LOADING',
  setCurrentPage: 'SET_CURRENT_PAGE',
  launchModal: 'LAUNCH_MODAL',
  closeModal: 'CLOSE_MODAL',
  setModalName: 'SET_MODAL_NAME',
};

interface AppBasePayloadType {
  error?: string;
  currentPage?: string;
  modalName?: string;
}

type AppPayloadType = AppBasePayloadType & MyReservationsPayloadType & ReservationsPayloadType;
interface IAppAction {
  type: string;
  payload?: AppPayloadType;
}

export type { AppPayloadType, IAppAction };
export { appActions };

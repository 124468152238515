import type { IReservationsState } from '../Initials/reservations';
import type { IReservationsAction } from '../Actions/reservations';
import { reservationsActions } from '../Actions/reservations';

// eslint-disable-next-line complexity
const reservationsReducer = (state: IReservationsState, action: IReservationsAction): IReservationsState => {
  const { type, payload } = action;

  switch (type) {
    case reservationsActions.setAvailability:
      return { ...state, reservationsPage: { ...state.reservationsPage, availability: payload.availability } };
    case reservationsActions.setSuccess:
      return { ...state, reservationsPage: { ...state.reservationsPage, success: payload.successResponse } };
    case reservationsActions.setError:
      return { ...state, reservationsPage: { ...state.reservationsPage, error: payload.errorResponse } };
    case reservationsActions.selectDate:
      return { ...state, reservationsPage: { ...state.reservationsPage, date: payload.date } };
    case reservationsActions.selectRoom:
      return { ...state, reservationsPage: { ...state.reservationsPage, room: payload.room } };
    case reservationsActions.selectDuration:
      return { ...state, reservationsPage: { ...state.reservationsPage, duration: payload.duration } };
    case reservationsActions.selectTime:
      return { ...state, reservationsPage: { ...state.reservationsPage, time: payload.time } };
    case reservationsActions.setDisabledDays:
      return { ...state, reservationsPage: { ...state.reservationsPage, disabledDays: payload.disabledDays } };
    case reservationsActions.setReservations:
      return { ...state, reservationsPage: { ...state.reservationsPage, reservations: payload.reservations } };
    case reservationsActions.removeReservation:
      // eslint-disable-next-line no-case-declarations
      const data = Array.from(state.reservationsPage.reservations ?? []);
      // eslint-disable-next-line fp/no-mutating-methods
      data.splice(payload.id!, 1);
      return {
        ...state,

        reservationsPage: {
          ...state.reservationsPage,
          reservations: data,
        },
      };
    case reservationsActions.submitSelection:
      return {
        ...state,

        reservationsPage: {
          ...state.reservationsPage,

          reservations: state.reservationsPage.reservations
            ? [...state.reservationsPage.reservations, payload.reservation!]
            : [payload.reservation!],
        },
      };
    default:
      return state;
  }
};

export { reservationsReducer };

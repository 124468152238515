import { IconButton } from '@mui/material';
import styled from 'styled-components';

import Heading from '../../components/ui/Heading';
import Subtitle from '../../components/ui/Subtitle';

const StyledSubtitle = styled(Subtitle)`
  color: ${({ theme }) => theme.colors.neutral2};
`;

const StyledHeading = styled(Heading)`
  text-align: left;
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  text-align: left;
  padding-left: 1rem;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 2rem;
`;

export { StyledHeading, StyledSubtitle, StyledBox, StyledIconButton };

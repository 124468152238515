import { styled as styledMUI, Grid, Skeleton, Button } from '@mui/material';
import styled from 'styled-components';

import Heading from '../../../../../../components/ui/Heading';
import Paragraph from '../../../../../../components/ui/Paragraph';

const StyledRoomDetailContainer = styled.main`
  background-color: ${({ theme }) => theme.colors.primary4};
  width: 95%;
  margin: auto;
  border-radius: 20px;
`;

const StyledDetailRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0.75rem;
  flex-wrap: wrap;
  box-sizing: border-box;
`;

const StyledLeftCol = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
`;

const StyledRightCol = styled.div`
  flex: 1;
  margin: auto;
`;

const StyledHeading = styledMUI(Heading)`
  margin-bottom: 1rem;
  width: 100%;
  color:${({ theme }) => theme.palette.info.dark};
  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: left;
  }
`;
const StyledParagraph = styled(Paragraph)`
  margin-bottom: 1rem;
  max-width: 720px;
`;

const StyledImgGridItem = styledMUI(Grid)`
  justify-content: center;
  display: flex;
  padding: 0.5rem;
`;

const StyledAutoGrid = styledMUI(Grid)`
  width: 100%;
  aspect-ratio: 1.75/1;
`;

type StyledImgprops = {
  loaded: boolean;
};
const StyledImg = styled.img<StyledImgprops>`
  cursor: pointer;
  object-fit: cover;
  width: ${(props) => (props.loaded ? '100%' : '0%')};
  border-radius: 10px;
  aspect-ratio: 1.75/1;
`;

const StyledButtonLabel = styled.span`
  color: ${({ theme }) => theme.colors.primary1};
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  width: 100%;
  cursor: pointer;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 100%;
`;

const StyledButton = styled(Button)`
  width: 130px;
`;

export {
  StyledRoomDetailContainer,
  StyledHeading,
  StyledImgGridItem,
  StyledImg,
  StyledParagraph,
  StyledAutoGrid,
  StyledSkeleton,
  StyledButtonLabel,
  StyledDetailRow,
  StyledButton,
  StyledLeftCol,
  StyledRightCol,
};

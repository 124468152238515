import type { RoomData } from '../../../utils/api/rooms';

interface IAdminRoomsState {
  roomData?: RoomData;
  calendarReservation?: { id: string; professional: string; appointment: string; date: string };
  disableReservation?: {
    toDate?: string;
    fromDate?: string;
    success: boolean;
    error: boolean;
  };
}

const initialAdminRoomsState = {
  roomData: {
    name: '',
    description: '',
  },

  calendarReservation: { id: '', professional: '', appointment: '', date: '' },

  disableReservation: { duration: '', success: false, error: false },
};

export type { IAdminRoomsState };
export { initialAdminRoomsState };

import type { ControllerRenderProps, SubmitHandler } from 'react-hook-form';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Container } from '@mui/material';

import { useAuth } from '../../../context/Hooks';
import { authActions } from '../../../context/Actions/auth';
import { forgotPasswordFormSchema } from '../../../utils/schemas/auth';
import type { ForgotPasswordSchemaType } from '../../../utils/schemas/auth';
import type { RequestNewPasswordResponse } from '../../../utils/api/user';
import { requestNewPassword } from '../../../utils/api/user';
import RenderField from '../../../components/form/Field';
import Heading from '../../../components/ui/Heading';
import Subtitle from '../../../components/ui/Subtitle';
import Logo from '../../../assets/images/logo.png';
import {
  StyledLogo,
  StyledPage,
  StyledContainer,
  StyledFormContainer,
  StyledButtonWrapper,
  StyledButtonLabel,
} from '../styles';

type LoginFieldType = {
  field: ControllerRenderProps<ForgotPasswordSchemaType, 'email'>;
};

const baseInputProps = {
  label: { shrink: true },
};

/* eslint-disable react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop, @typescript-eslint/no-misused-promises  */
const ForgotPasswordPage = () => {
  const { t } = useTranslation('translation');
  const { state, dispatch } = useAuth();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ForgotPasswordSchemaType>({
    resolver: zodResolver(forgotPasswordFormSchema),
  });

  const propsByField = {
    email: baseInputProps,
  };

  const onSubmit: SubmitHandler<ForgotPasswordSchemaType> = async (data: ForgotPasswordSchemaType) => {
    try {
      const response = await requestNewPassword({ email: data.email });
      if (response.ok) {
        dispatch({
          type: authActions.newPasswordSuccessful,
        });
      } else {
        const result = (await response.json()) as RequestNewPasswordResponse;
        dispatch({
          type: authActions.newPasswordFailed,
          payload: { error: result.errors?.email[0] },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderLoginField = ({ field: { onChange, onBlur, value, name, ref: reference } }: LoginFieldType) => (
    <RenderField
      errors={errors[name]?.message}
      inputProps={propsByField[name]}
      inputType="text"
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      reference={reference}
      value={value}
    />
  );

  return (
    <StyledPage>
      <Container maxWidth="sm">
        <StyledContainer>
          <StyledLogo alt="Logo Casa Escondida" src={Logo} />
        </StyledContainer>
        <StyledFormContainer>
          <Heading content={t('auth.forgotPassword')} variant={2} />
          <Subtitle content={t('auth.fields.email')} variant={3} />
          <Controller control={control} defaultValue="" name="email" render={renderLoginField} />
          <StyledButtonWrapper>
            <Button fullWidth onClick={handleSubmit(onSubmit)} variant="contained">
              <StyledButtonLabel>{t('auth.fields.submitEmail')}</StyledButtonLabel>
            </Button>
          </StyledButtonWrapper>
          {Boolean(state.error) && (
            <Alert severity="error" variant="outlined">
              {state.error}
            </Alert>
          )}
          {Boolean(state.newPasswordSuccessful) && (
            <Alert severity="success" variant="outlined">
              {t('auth.forgotPasswordRequested')}
            </Alert>
          )}
        </StyledFormContainer>
      </Container>
    </StyledPage>
  );
};

export default ForgotPasswordPage;

/* eslint-enable react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop, @typescript-eslint/no-misused-promises  */

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import type { ControllerRenderProps, SubmitHandler } from 'react-hook-form';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Container, InputAdornment, IconButton, Link } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import Logo from '../../../assets/images/logo.png';
import { resetPassword } from '../../../utils/api/user';
import { resetPaswordFormSchema } from '../../../utils/schemas/auth';
import type { ResetPasswordFormSchemaType } from '../../../utils/schemas/auth';
import { useAuth } from '../../../context/Hooks';
import Subtitle from '../../../components/ui/Subtitle';
import Heading from '../../../components/ui/Heading';
import RenderField from '../../../components/form/Field';
import {
  StyledLogo,
  StyledPage,
  StyledContainer,
  StyledFormContainer,
  StyledButtonWrapper,
  StyledButtonLabel,
  StyledParagraph,
  StyledSignInLink,
} from '../styles';

type ResetPasswordFieldType = {
  field:
    | ControllerRenderProps<ResetPasswordFormSchemaType, 'password'>
    | ControllerRenderProps<ResetPasswordFormSchemaType, 'passwordConfirmation'>;
};

const getInputProps = (showPassword: boolean, togglePassword: () => void) => ({
  label: { shrink: true },

  adornment: {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={togglePassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
      </InputAdornment>
    ),
  },
});

/* eslint-disable react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop, @typescript-eslint/no-misused-promises  */
const ResetPasswordPage = ({ showWelcomeMessage = false }: { showWelcomeMessage?: boolean }) => {
  const { t } = useTranslation('translation');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>();
  const { togglePassword } = useAuth();

  const handlePasswordVisibility = () => {
    togglePassword(setShowPassword);
  };
  const { token } = useParams();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ResetPasswordFormSchemaType>({
    resolver: zodResolver(resetPaswordFormSchema),
  });

  const onSubmit: SubmitHandler<ResetPasswordFormSchemaType> = async (user: ResetPasswordFormSchemaType) => {
    try {
      if (token === undefined || token === '') return;
      const response = await resetPassword({ ...user, token });
      if (response.ok) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderResetPasswordField = ({
    field: { onChange, onBlur, value, name, ref: reference },
  }: ResetPasswordFieldType) => (
    <RenderField
      errors={errors[name]?.message}
      inputProps={getInputProps(showPassword, handlePasswordVisibility)}
      inputType={showPassword ? 'text' : 'password'}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      reference={reference}
      value={value}
    />
  );

  return (
    <StyledPage>
      <Container maxWidth="sm">
        <StyledContainer>
          <StyledLogo alt="Logo Casa Escondida" src={Logo} />
        </StyledContainer>
        <StyledFormContainer>
          {Boolean(showWelcomeMessage) && <Heading content={t('auth.reset.welcome')} variant={2} />}
          <StyledParagraph content={t('auth.reset.subtitle')} variant={1} />
          <Subtitle content={t('auth.fields.password')} variant={3} />
          <Controller control={control} defaultValue="" name="password" render={renderResetPasswordField} />
          <Subtitle content={t('auth.fields.passwordConfirmation')} variant={3} />
          <Controller control={control} defaultValue="" name="passwordConfirmation" render={renderResetPasswordField} />
          <StyledButtonWrapper>
            <Button fullWidth onClick={handleSubmit(onSubmit)} variant="contained">
              <StyledButtonLabel>{t('auth.reset.submit')}</StyledButtonLabel>
            </Button>
          </StyledButtonWrapper>
          {success === false && (
            <Alert severity="error" variant="outlined">
              {t('auth.newPasswordFailed')}
              <Link href="/forgotPassword" variant="body2">
                {t('auth.newPasswordFailedRetry')}
              </Link>
            </Alert>
          )}
          {Boolean(success) && (
            <>
              <Alert severity="success" variant="outlined">
                {t('auth.newPasswordChanged')}
              </Alert>
              <StyledSignInLink href="/login" variant="body2">
                {t('auth.signInLink')}
              </StyledSignInLink>
            </>
          )}
        </StyledFormContainer>
      </Container>
    </StyledPage>
  );
};

export default ResetPasswordPage;

/* eslint-enable react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop, @typescript-eslint/no-misused-promises  */

import { z as zod } from 'zod';

const ratesFormSchema = zod.object({
  hourPrice: zod.string().min(1, { message: 'form.errors.required' }),
  reducedPrice: zod.string().min(1, { message: 'form.errors.required' }),
});

const defaultRatesFormSchema = {
  hourPrice: '',
  reducedPrice: '',
};

type RatesFormSchemaType = zod.infer<typeof ratesFormSchema>;

export type { RatesFormSchemaType };
export { ratesFormSchema, defaultRatesFormSchema };

import type { TextComponentProps, TextIndex } from '../../../utils/types/text';

import { StyledSubtitle1, StyledSubtitle2, StyledSubtitle3, StyledSubtitle4 } from './styles';

const Subtitle = ({ className, content, variant }: TextComponentProps) => {
  const subtitles: TextIndex = {
    subtitle1: <StyledSubtitle1 className={className}>{content}</StyledSubtitle1>,
    subtitle2: <StyledSubtitle2 className={className}>{content}</StyledSubtitle2>,

    subtitle3: (
      <StyledSubtitle3 className={className} secondary>
        {content}
      </StyledSubtitle3>
    ),

    subtitle4: (
      <StyledSubtitle4 className={className} secondary>
        {content}
      </StyledSubtitle4>
    ),
  };

  return subtitles[`subtitle${variant}`];
};

export default Subtitle;

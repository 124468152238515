import { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Button } from '@mui/material';

import { appActions } from '../../../context/Actions/app';
import { useAppContext, useAuth } from '../../../context/Hooks';

import {
  StyledButtonLabel,
  StyledModalContainer,
  StyledModalBackground,
  StyledTitle,
  StyledContentWrapper,
  StyledCloseButton,
  StyledButtonWrapper,
} from './styles';

type ModalProps = {
  shouldClose?: boolean;
  isButtonDisabled?: boolean;
  buttonAction?: () => Promise<void> | void;
  buttonLabel: string;
  buttonLabelCancel?: string;
  hasCancelButton?: boolean;
  content: () => JSX.Element;
  handleClose?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  modalName?: string;
  title: string;
};

const Modal = ({
  shouldClose = true,
  buttonAction,
  buttonLabel,
  buttonLabelCancel,
  hasCancelButton = false,
  content,
  handleClose,
  title,
  isButtonDisabled = false,
}: ModalProps) => {
  const { dispatch } = useAppContext();
  const { isAdmin } = useAuth();

  const handleCloseClick = useCallback(() => {
    handleClose?.();
    dispatch({ type: appActions.closeModal });
  }, [handleClose, dispatch]);

  const handleButtonClick = useCallback(() => {
    const handler = async () => {
      await buttonAction?.();
      if (shouldClose) dispatch({ type: appActions.closeModal });
    };
    void handler();
  }, [buttonAction, shouldClose, dispatch]);

  /* eslint-disable fp/no-mutation */
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  /* eslint-enable fp/no-mutation */

  return (
    <StyledModalBackground isAdmin={isAdmin()}>
      <StyledModalContainer>
        <StyledCloseButton onClick={handleCloseClick} />
        <StyledTitle content={title} variant={2} />
        <StyledContentWrapper>{content()}</StyledContentWrapper>
        <StyledButtonWrapper>
          {Boolean(hasCancelButton) && (
            <Button onClick={handleCloseClick} variant="outlined">
              <StyledButtonLabel>{buttonLabelCancel}</StyledButtonLabel>
            </Button>
          )}
          <Button disabled={isButtonDisabled} onClick={handleButtonClick} variant="contained">
            <StyledButtonLabel primary>{buttonLabel}</StyledButtonLabel>
          </Button>
        </StyledButtonWrapper>
      </StyledModalContainer>
    </StyledModalBackground>
  );
};

const ModalPortal = ({
  buttonAction,
  buttonLabel,
  buttonLabelCancel,
  hasCancelButton = false,
  content,
  handleClose,
  isButtonDisabled,
  modalName = '',
  shouldClose,
  title,
}: ModalProps) => {
  const { state } = useAppContext();
  // eslint-disable-next-line unicorn/no-null
  if (!state.modalVisible || modalName !== state.modalName) return null;
  return createPortal(
    <Modal
      buttonAction={buttonAction}
      buttonLabel={buttonLabel}
      buttonLabelCancel={buttonLabelCancel}
      content={content}
      handleClose={handleClose}
      hasCancelButton={hasCancelButton}
      isButtonDisabled={isButtonDisabled}
      shouldClose={shouldClose}
      title={title}
    />,
    document.body,
  );
};

export type { ModalProps };
export default ModalPortal;

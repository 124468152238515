import styled from 'styled-components';

type LayoutProps = {
  isAdmin: boolean;
};

const StyledLayout = styled.main<LayoutProps>`
  width: ${({ isAdmin }) => (isAdmin ? 'calc(100% - 325px)' : '100vw')};
  min-height: 100vh;
  display: flex;
  right: 0;
  position: absolute;
`;

export { StyledLayout };

import { useState, useEffect, useCallback } from 'react';
import type { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow, TableBody, TableContainer, Table, Button, Input, InputAdornment, InputLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import Paragraph from '../../../../../components/ui/Paragraph';
import { getBillingByProfessionals } from '../../../../../utils/api/admin-billing';
import type { RowContentType } from '../../../../../utils/api/admin-billing';
import { useAdminHandlers } from '../../../../../context/Hooks';
import UserFormModal from '../../../Modals/UserFormModal';
import DeleteUserModal from '../../../Modals/DeleteModal';
import Row from '../Row';
import {
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
  StyledButtonLabel,
  StyledUpperTableBox,
  StyledFormControl,
} from '../../styles';

const UserBillingTable = () => {
  const { t } = useTranslation('translation');
  const [rows, setRows] = useState<RowContentType[]>();
  const [search, setSearch] = useState<string>();
  const { handleCreateUser } = useAdminHandlers();

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, []);

  useEffect(() => {
    const fetchRows = async () => {
      const response = await getBillingByProfessionals(search);
      setRows(response);
    };
    void fetchRows();
  }, [search]);

  return (
    <>
      <StyledUpperTableBox>
        <StyledFormControl variant="standard">
          <InputLabel htmlFor="search-by-name">
            <Paragraph content={t('admin.billing.searchByName')} variant={1} />
          </InputLabel>
          <Input
            endAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            id="search-by-name"
            onChange={handleSearch}
            value={search}
          />
        </StyledFormControl>
        <Button onClick={handleCreateUser} variant="contained">
          <StyledButtonLabel primary>{t('admin.createUser')}</StyledButtonLabel>
        </Button>
      </StyledUpperTableBox>
      <TableContainer component={StyledTableContainer}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <StyledTableCell align="center">{t('admin.billing.rows.name')}</StyledTableCell>
              <StyledTableCell align="center">{t('admin.billing.rows.profession')}</StyledTableCell>
              <StyledTableCell align="center">{t('admin.billing.rows.phone')}</StyledTableCell>
              <StyledTableCell align="center">{t('admin.billing.rows.amount')}</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {rows?.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UserFormModal />
      <DeleteUserModal />
    </>
  );
};

export default UserBillingTable;

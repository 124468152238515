import { appActions } from '../../Actions/app';
import { adminActions } from '../../Actions/admin';
import type { RoomData } from '../../../utils/api/rooms';
import { useAdminContext, useAppContext } from '..';
import { setAdminRoomData } from '../../../utils/services/rooms';

const handleOpenRoomDetail = (room: RoomData) => () => {
  setAdminRoomData(room);
};

const useAdminRoomHandlers = () => {
  const { dispatch: appDispatch } = useAppContext();
  const { dispatch: adminDispatch } = useAdminContext();

  const handleEditRoom =
    (data: {
      row: {
        roomId: number;
        name: string;
        description: string;
      };
    }) =>
    () => {
      adminDispatch({
        type: adminActions.setRoomData,

        payload: {
          roomData: { id: data.row.roomId, name: data.row.name, description: data.row.description },
        },
      });
      appDispatch({ type: appActions.setModalName, payload: { modalName: 'editRoom' } });
      appDispatch({ type: appActions.launchModal });
    };

  const handleUpdateTariff = () => {
    appDispatch({ type: appActions.setModalName, payload: { modalName: 'updateTariff' } });
    appDispatch({ type: appActions.launchModal });
  };

  return {
    handleEditRoom,
    handleOpenRoomDetail,
    handleUpdateTariff,
  };
};

export { useAdminRoomHandlers };

import { Box, Grid } from '@mui/material';
import styled from 'styled-components';

const StyledGridItem = styled(Grid)`
  justify-content: center;
  display: flex;
  width: 100%;
`;

type GridWrapperProps = { fromAdmin: boolean };

const StyledGridWrapper = styled(Box)<GridWrapperProps>`
  flex-grow: 1;
  margin: 1.5rem;
  position: relative;
  top: ${({ fromAdmin }) => (fromAdmin ? '165px' : '0px')};
`;
export { StyledGridItem, StyledGridWrapper };

import styled from 'styled-components';

import Subtitle from '../../ui/Subtitle';

const StyledLoadingScreenContainer = styled.main`
  width: 100%;
  height: calc(100vh - 64px);
  position: absolute;
  overflow: hidden;
  top: 64px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledLogo = styled.img`
  width: 200px;
  margin-bottom: 1rem;
`;

const StyledSubtitle = styled(Subtitle)`
  color: ${({ theme }) => theme.colors.neutral3};
`;

export { StyledLoadingScreenContainer, StyledLogo, StyledSubtitle };

import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

import Modal from '../../../../components/ui/Modal';
import { clearReservationsData } from '../../../../utils/services/reservations';
import { reservationsActions } from '../../../../context/Actions/reservations';
import { useAppContext } from '../../../../context/Hooks';

import { StyledParagraph } from './styles';

type ContentKeyType = 'error' | 'pending' | 'success';

const ConfirmModal = () => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const {
    dispatch,
    state: {
      reservationsPage: { success, error },
    },
  } = useAppContext();

  const transactionState = useMemo(() => {
    if (success ?? false) {
      return 'Success';
    }
    if (error ?? false) {
      return 'Error';
    }
    return 'Pending';
  }, [success, error]);

  const handleClose = useCallback(() => {
    if (transactionState === 'Success') navigate('/myReservations');
    if (transactionState === 'Error') navigate('/');
  }, [navigate, transactionState]);

  const handleModalAction = useCallback(() => {
    clearReservationsData();
    dispatch({ type: reservationsActions.setReservations, payload: { reservations: [] } });
    handleClose();
  }, [dispatch, handleClose]);

  const ModalConfirmContent = useCallback(() => {
    const contents = {
      success: <StyledParagraph content={t('reservations.confirm.modal.contentSuccess')} variant={1} />,
      error: <StyledParagraph content={t('reservations.confirm.modal.contentError')} variant={1} />,
      pending: <CircularProgress />,
    };
    const contentKey = transactionState.toLowerCase() as ContentKeyType;
    return contents[contentKey];
  }, [t, transactionState]);

  return (
    <Modal
      buttonAction={handleModalAction}
      buttonLabel={t(`reservations.confirm.modal.button${transactionState}`)}
      content={ModalConfirmContent}
      handleClose={handleClose}
      isButtonDisabled={transactionState === 'Pending'}
      title={t(`reservations.confirm.modal.title${transactionState}`)}
    />
  );
};

export default ConfirmModal;

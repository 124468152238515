import type { IAdminState } from '../../Initials/admin';
import type { IAdminAction } from '../../Actions/admin';

import { adminUsersReducer } from './users';
import { adminRoomsReducer, adminDisablingReservationReducer } from './rooms';
import { adminPaymentReducer } from './payment';

const adminReducersArray = [
  adminRoomsReducer,
  adminUsersReducer,
  adminPaymentReducer,
  adminDisablingReservationReducer,
];

type ReducerType =
  | typeof adminDisablingReservationReducer
  | typeof adminPaymentReducer
  | typeof adminRoomsReducer
  | typeof adminUsersReducer;

const combineReducers = (reducers: ReducerType[]) => (state: IAdminState, action: IAdminAction) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  reducers.reduce((newState: IAdminState, reducer: any) => reducer(newState, action), state);

const adminReducer = combineReducers(adminReducersArray);

export { adminReducer };

import type { TextComponentProps, TextIndex } from '../../../utils/types/text';

import { StyledHeading1, StyledHeading2, StyledHeading3 } from './styles';

const Heading = ({ className, content, variant }: TextComponentProps) => {
  const headings: TextIndex = {
    heading1: <StyledHeading1 className={className}>{content}</StyledHeading1>,
    heading2: <StyledHeading2 className={className}>{content}</StyledHeading2>,
    heading3: <StyledHeading3 className={className}>{content}</StyledHeading3>,

    heading4: (
      <StyledHeading1 className={className} secondary>
        {content}
      </StyledHeading1>
    ),

    heading5: (
      <StyledHeading2 className={className} secondary>
        {content}
      </StyledHeading2>
    ),

    heading6: (
      <StyledHeading3 className={className} secondary>
        {content}
      </StyledHeading3>
    ),
  };

  return headings[`heading${variant}`];
};

export default Heading;

import { getAuthData, removeAuthData } from '../services/auth';
import type { AuthData } from '../services/auth';

/* eslint-disable @typescript-eslint/naming-convention */

const BASE_URL = process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:3000';

const RESPONSE_STATUS = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  UNAUTHORIZED: 401,
  UNPROCESABLE_ENTITY: 426,
  SERVER_ERROR: 500,
};

type BaseResponseData = {
  ok?: boolean;
  errors?: { [key: string]: string[] };
  data?: { [key: string]: string };
};

type Method = 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT';

interface Options {
  withAuth?: boolean;
  body?: object;
}

const buildHeaders = (withAuth: boolean, tokens: AuthData) => {
  const baseHeaders = { 'Content-Type': 'application/json' };

  if (withAuth && tokens.accessToken) {
    return { ...baseHeaders, Authorization: tokens.accessToken };
  }

  return baseHeaders;
};

type TryFetchProps = { body: object | undefined; withAuth: boolean; method: Method; path: string };

const tryFetch = async ({ body, withAuth, method, path }: TryFetchProps) => {
  const headers = buildHeaders(withAuth, getAuthData());
  return await fetch(`${BASE_URL}/${path}`, {
    method,
    mode: 'cors',
    headers,
    body: body ? JSON.stringify(body) : undefined,
  });
};

const apiFetch = async (method: Method, path: string, options: Options = {}) => {
  const { body, withAuth = true } = options;
  try {
    const response = await tryFetch({ body, withAuth, method, path });
    if (String(response.status) === '401') {
      removeAuthData();
      window.location.replace('/login');
    }
    return response;
  } catch (error) {
    console.error(error);
    return await Promise.reject(error);
  }
};

export { BASE_URL, RESPONSE_STATUS, apiFetch };
export type { BaseResponseData };

/* eslint-enable @typescript-eslint/naming-convention */

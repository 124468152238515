// eslint-disable-next-line no-explicit-type-exports/no-explicit-type-exports
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import EN from './Locales/En';
import ES from './Locales/Es';

type Translation = {
  // eslint-disable-next-line @typescript-eslint/sort-type-union-intersection-members
  [key: string]: string | Translation | string[];
};

type Translations = { [key: string]: Translation };

const resources = {
  en: {
    translation: EN,
  },

  es: {
    translation: ES,
  },
};

void i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,

    lng: 'en',

    fallbackLng: 'es',

    interpolation: {
      escapeValue: false,
    },
  });

export type { Translations };
export default i18n;

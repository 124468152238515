import { useCallback, useEffect, useState } from 'react';
import type { MouseEvent as ReactMouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';

import PageContainer from '../../components/ui/PageContainer';
import BillingTotalAmount from '../../components/ui/BillingTotalAmount';
import BillingStack from '../../components/ui/Stack/Billing';
import { getCurrentBillingHighlights, getCurrentPeriodBilling, getCurrentDebt } from '../../utils/api/billing';
import type { BillingStackItemType } from '../../utils/api/billing';
import LoadingScreen from '../../components/layout/LoadingScreen';
import Heading from '../../components/ui/Heading';
import AnimatedPage from '../../components/ui/AnimatedPage';
import { getCurrentUserId } from '../../utils/services/auth';
import { dateFormat } from '../../utils/schemas/datetime';

import { StyledSubtitle, StyledBox, StyledHeading, StyledIconButton } from './styles';

const currentMonth = moment().format('MM');
const currentYear = moment().year();
const currentPeriod = `${currentMonth}/${currentYear}`;

// eslint-disable-next-line max-statements
const BillingPage = () => {
  const { t } = useTranslation('translation');
  const [currentBillingHighlights, setCurrentBillingHighlights] = useState<BillingStackItemType[]>();
  const [currentPeriodBilling, setCurrentPeriodBilling] = useState<BillingStackItemType[]>();
  const [anchorElement, setAnchorElement] = useState<HTMLElement>();
  const [debt, setDebt] = useState<string>();
  const navigate = useNavigate();

  const handleClick = useCallback((event: ReactMouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorElement(undefined);
  }, []);

  const handleBillingPage = useCallback(() => {
    navigate('history');
  }, [navigate]);

  useEffect(() => {
    const fetchCurrentBilling = async () => {
      const userId = getCurrentUserId();
      const highlightsResponse = await getCurrentBillingHighlights(userId);
      setCurrentBillingHighlights(highlightsResponse);
      const currentPeriodResponse = await getCurrentPeriodBilling(userId, moment().format(dateFormat));
      setCurrentPeriodBilling(currentPeriodResponse);
      const debtResponse = await getCurrentDebt(userId);
      setDebt(debtResponse);
    };
    void fetchCurrentBilling();
  }, []);

  if (!currentBillingHighlights || !currentPeriodBilling || debt === undefined) return <LoadingScreen />;

  return (
    <AnimatedPage>
      <PageContainer>
        <Heading content={t('billing.current')} variant={3} />
        <BillingStack items={currentBillingHighlights} />
        <BillingTotalAmount amount={debt} />
        <StyledBox>
          <StyledHeading content={t('billing.detail')} variant={3} />
          <StyledSubtitle content={`${t('billing.period')} ${currentPeriod}`} variant={3} />
          <StyledIconButton onClick={handleClick}>
            <MoreVertIcon />
          </StyledIconButton>
          <Menu anchorEl={anchorElement} onClose={handleClose} open={Boolean(anchorElement)}>
            <MenuItem onClick={handleBillingPage}>{t('billing.goHistory')}</MenuItem>
          </Menu>
        </StyledBox>
        <BillingStack hasSubtitle items={currentPeriodBilling} />
      </PageContainer>
    </AnimatedPage>
  );
};

export default BillingPage;

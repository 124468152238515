import styled from 'styled-components';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import Paragraph from '../../Paragraph';

const StyledCancelOutlinedIcon = styled(CancelOutlinedIcon)`
  color: ${({ theme }) => theme.colors.neutral2};
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.neutral2};
`;

export { StyledCancelOutlinedIcon, StyledParagraph };

interface IAdminPaymentState {
  paymentTarget: {
    id: number;
    name: string;
  };
}

const initialAdminPaymentState = {
  paymentTarget: { id: 0, name: '' },
};

export type { IAdminPaymentState };
export { initialAdminPaymentState };

import { z as zod } from 'zod';

const createUserFormSchema = zod.object({
  name: zod.string().min(1, { message: 'form.errors.required' }),
  lastname: zod.string().min(1, { message: 'form.errors.required' }),
  phone: zod.string().min(1, { message: 'form.errors.required' }),
  profession: zod.string().min(1, { message: 'form.errors.required' }),
  email: zod.string().email({ message: 'form.errors.email' }),
});

const editUserFormSchema = zod.object({
  name: zod.string().min(1, { message: 'form.errors.required' }),
  lastname: zod.string().min(1, { message: 'form.errors.required' }),
  phone: zod.string().min(1, { message: 'form.errors.required' }),
  profession: zod.string().min(1, { message: 'form.errors.required' }),
});

const defaultUserFormValues = {
  name: '',
  lastname: '',
  profession: '',
  phone: '',
  email: '',
};

type CreateUserFormSchemaType = zod.infer<typeof createUserFormSchema>;
type EditUserFormSchemaType = zod.infer<typeof editUserFormSchema>;
type UserFormType = CreateUserFormSchemaType | EditUserFormSchemaType;

export type { UserFormType, EditUserFormSchemaType, CreateUserFormSchemaType };
export { createUserFormSchema, editUserFormSchema, defaultUserFormValues };

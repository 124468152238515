import type { IReservation } from '../../context/Initials/reservations';

const getReservationsData = () => {
  const data = localStorage.getItem('reservations') ?? '';

  try {
    return JSON.parse(data) as IReservation[];
  } catch {
    return undefined;
  }
};

const setReservationsData = (data: IReservation[]) => {
  localStorage.setItem('reservations', JSON.stringify(data));
};

const removeReservationData = (id: number) => {
  const data = Array.from(getReservationsData()!);
  // eslint-disable-next-line fp/no-mutating-methods
  data.splice(id, 1);
  localStorage.setItem('reservations', JSON.stringify(data));
};

const clearReservationsData = () => {
  localStorage.removeItem('reservations');
};

export { getReservationsData, setReservationsData, removeReservationData, clearReservationsData };

import styled, { css } from 'styled-components';

import type { StyledTextComponent } from '../../../utils/types/text';

const sharedSubtitleProps = css<StyledTextComponent>`
  font-family: ${({ secondary = false }) => (secondary ? 'Montserrat' : 'Merriweather')};
  font-weight: ${({ secondary = false }) => (secondary ? '600' : '700')};
  margin: 0.5rem 0;
`;

const StyledSubtitle1 = styled.h1<StyledTextComponent>`
  font-size: 16px;
  line-height: 28px;
  ${sharedSubtitleProps};
`;

const StyledSubtitle2 = styled.h2<StyledTextComponent>`
  font-size: 14px;
  line-height: 22px;
  ${sharedSubtitleProps};
`;
const StyledSubtitle3 = styled.h3<StyledTextComponent>`
  font-size: 16px;
  line-height: 22px;
  ${sharedSubtitleProps};
`;
const StyledSubtitle4 = styled.h3<StyledTextComponent>`
  font-size: 14px;
  line-height: 22px;
  ${sharedSubtitleProps};
`;

export { StyledSubtitle1, StyledSubtitle2, StyledSubtitle3, StyledSubtitle4 };

import styled from 'styled-components';
import { Box, List } from '@mui/material';

const StyledLogo = styled.img`
  width: 140px;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
`;

const StyledDrawerBox = styled(Box)`
  margin: 23px 27px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledList = styled(List)`
  flex: 1;
`;

export { StyledLogo, StyledLogoContainer, StyledDrawerBox, StyledList };

import { styled, Box } from '@mui/material';

import Heading from '../Heading';

const StyledTotalAmount = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1.5rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 10px;
  height: 48px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 320px;
  }
`;

const StyledHeading = styled(Heading)`
  color: white;
`;

export { StyledTotalAmount, StyledHeading };

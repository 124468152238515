import type { IAdminRoomsState } from '../../Initials/admin/rooms';
import type { IAdminAction } from '../../Actions/admin';
import { adminRoomsActions } from '../../Actions/admin/rooms';

const adminDisablingReservationReducer = (state: IAdminRoomsState, action: IAdminAction): IAdminRoomsState => {
  const { type, payload } = action;

  switch (type) {
    case adminRoomsActions.setDisablingSuccess:
      return { ...state, disableReservation: { ...state.disableReservation!, success: true, error: false } };
    case adminRoomsActions.setDisablingError:
      return { ...state, disableReservation: { ...state.disableReservation!, success: false, error: true } };
    case adminRoomsActions.setDisablingDateFrom:
      return {
        ...state,
        disableReservation: { ...state.disableReservation!, fromDate: payload?.disableReservation?.fromDate ?? '' },
      };
    case adminRoomsActions.setDisablingDateTo:
      return {
        ...state,
        disableReservation: { ...state.disableReservation!, toDate: payload?.disableReservation?.toDate ?? '' },
      };
    default:
      return state;
  }
};

const adminRoomsReducer = (state: IAdminRoomsState, action: IAdminAction): IAdminRoomsState => {
  const { type, payload } = action;

  switch (type) {
    case adminRoomsActions.setRoomData:
      return { ...state, roomData: payload?.roomData };
    case adminRoomsActions.setCalendarReservation:
      return { ...state, calendarReservation: payload?.calendarReservation };
    case adminRoomsActions.clearRoomData:
      return {
        ...state,

        roomData: {
          name: '',
          description: '',
        },
      };
    case adminRoomsActions.clearCalendarReservation:
      return {
        ...state,

        calendarReservation: {
          id: '',
          professional: '',
          appointment: '',
          date: '',
        },
      };
    default:
      return state;
  }
};

export { adminRoomsReducer, adminDisablingReservationReducer };

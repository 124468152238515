import styled from 'styled-components';
import { FormControl, InputLabel } from '@mui/material';

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 1rem;
`;

const StyledInputLabel = styled(InputLabel)`
  background: white;
`;

export { StyledFormControl, StyledInputLabel };

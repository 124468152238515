import styled from 'styled-components';
import { styled as styledMUI, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledPreviewContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(43, 43, 43, 0.85);
  z-index: 1201; // Requerido para evitar solapado con drawer en admin.
`;

const StyledImageWrapper = styledMUI(Box)`
  position: relative;
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 75vw;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 70vh;
  }
  aspect-ratio: 1/1;
  display: block;
`;
const StyledImage = styled.img`
  width: 100%;
  position: relative;
  aspect-ratio: 1/1;
  object-fit: cover;
`;
const StyledCloseButton = styled(CloseIcon)`
  position: absolute;
  z-index: 3;
  cursor: pointer;
  top: -40px;
  display: block;
  right: -20px;
  width: 40px;
  height: 40px;
  color: white;
`;

export { StyledPreviewContainer, StyledImage, StyledCloseButton, StyledImageWrapper };

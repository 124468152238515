import { apiFetch } from '.';

type UserBillingDataType = {
  userId: number;
  name: string;
  lastname: string;
  profession: string;
  phone: string;
  amount: string;
  workedRounds: string;
  cancellations: string;
  bonifications: string;
  previousPeriod: string;
  paidAmount: string;
};

type RowContentType = {
  userId: number;
  name: string;
  lastname: string;
  profession: string;
  phone: string;
  amount: string;
  lastPeriodBilling: {
    id: number;
    amount: string;
    title: string;
  }[];
};

type RoomRowContentType = {
  roomId: number;
  name: string;
  description: string;
  amount: string;
  lastPeriodBilling: {
    workedRounds: string;
    cancellations: string;
    bonifications: string;
  };
};

type RowType = { row: RowContentType };

type BillingByProfessionalsResponse = {
  data: RowContentType[];
};

type BillingByRoomsResponse = {
  data: RoomRowContentType[];
};

type RoomRowType = {
  row: {
    roomId: number;
    name: string;
    description: string;
    amount: string;
    lastPeriodBilling: { workedRounds: string; cancellations: string; bonifications: string };
  };
};

const getBillingByProfessionals = async (search: string | undefined) => {
  const searchQuery = `?search=${search ?? ''}`;
  const response = await apiFetch(
    'GET',
    `/api/v1/professionals/billing_by_professional${search === undefined ? '' : searchQuery}`,
  );
  const result = (await response.json()) as BillingByProfessionalsResponse;
  return result.data;
};

const getBillingByRooms = async () => {
  const response = await apiFetch('GET', '/api/v1/rooms/billing_by_room');
  const result = (await response.json()) as BillingByRoomsResponse;
  return result.data;
};

export type { UserBillingDataType, RowType, RowContentType, RoomRowType, RoomRowContentType };
export { getBillingByProfessionals, getBillingByRooms };

import { Stack, InputAdornment } from '@mui/material';
import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';
import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import RenderField from '../../../../../components/form/Field';
import type { RateFieldType, RateFormProps } from '../Content';
import { StyledSubtitle } from '../styles';

const inputProps = {
  label: { shrink: true },

  adornment: {
    startAdornment: (
      <InputAdornment position="start">
        <AttachMoneySharpIcon />
      </InputAdornment>
    ),
  },
};

const RateModalFields = ({ control, errors }: RateFormProps) => {
  const { t } = useTranslation('translation');

  const renderRateField = useCallback(
    ({ field: { onChange, onBlur, value, name, ref: reference } }: RateFieldType) => {
      return (
        <RenderField
          errors={errors[name]?.message}
          inputProps={inputProps}
          inputType="text"
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          reference={reference}
          value={value}
        />
      );
    },
    [errors],
  );

  return (
    <Stack alignItems="center" spacing={2}>
      <StyledSubtitle content={t('admin.billing.modals.updateRate.fields.hourPrice')} variant={3} />
      <Controller control={control} name="hourPrice" render={renderRateField} />
      <StyledSubtitle content={t('admin.billing.modals.updateRate.fields.reducedPrice')} variant={3} />
      <Controller control={control} name="reducedPrice" render={renderRateField} />
    </Stack>
  );
};

export default RateModalFields;

import styled from 'styled-components';
import { Link, styled as styledMUI } from '@mui/material';

import Paragraph from '../../components/ui/Paragraph';

const StyledLogo = styled.img`
  width: 170px;
  margin: 1.5rem 0;
`;

const StyledPage = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.primary4};
`;
const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledFormContainer = styled(StyledContainer)`
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.1);
  padding: 28px;
  flex-direction: column;
`;

const StyledButtonWrapper = styled.div`
  max-width: 200px;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem auto 14px auto;
`;

const StyledButtonLabel = styled.span`
  color: white;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  width: 100%;
  cursor: pointer;
`;

const StyledParagraph = styledMUI(Paragraph)`
  color: ${({ theme }) => theme.palette.primary.main};
  `;

const StyledLink = styledMUI(StyledButtonLabel)`
  color: ${({ theme }) => theme.palette.primary.main}!important;
`;
const StyledSignInLink = styled(Link)`
  margin: 1rem;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Montserrat';
`;

export {
  StyledLogo,
  StyledPage,
  StyledFormContainer,
  StyledContainer,
  StyledButtonWrapper,
  StyledButtonLabel,
  StyledParagraph,
  StyledLink,
  StyledSignInLink,
};

import { createContext, useMemo, useReducer } from 'react';
import type { Dispatch } from 'react';

import { adminReducer } from '../Reducers/admin';
import { initialAdminState } from '../Initials/admin';
import type { IAdminState } from '../Initials/admin';
import type { IAdminAction } from '../Actions/admin';

interface IAdminContext {
  state: IAdminState;
  dispatch: Dispatch<IAdminAction>;
  mode: string;
  modalStatus: string;
}

const adminStateContext = createContext<IAdminContext | undefined>(undefined);

const AdminProvider = ({ children }: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(adminReducer, initialAdminState);

  const mode = useMemo(() => (state.isEditingUser ? 'edit' : 'create'), [state.isEditingUser]);

  const modalStatus = useMemo(() => {
    if (state.success) return 'Success';
    if (state.error) return 'Error';
    return 'Pending';
  }, [state.success, state.error]);

  const adminValues = useMemo(() => ({ state, dispatch, mode, modalStatus }), [state, dispatch, mode, modalStatus]);

  return <adminStateContext.Provider value={adminValues}>{children}</adminStateContext.Provider>;
};

export { AdminProvider, adminStateContext };

import { useCallback, useState } from 'react';
import type { PopoverOrigin } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, Toolbar, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import { useAuth, useAppContext } from '../../../context/Hooks';

import DrawerContent from './Drawer';
import { StyledBox, StyledDrawer, StyledHeading, StyledIconButton } from './styles';

const anchorOrigin = {
  vertical: 'top',

  horizontal: 'right',
};

const transformOrigin = {
  vertical: 'top',

  horizontal: 'right',
};

const modalProps = {
  keepMounted: true,
};

const Navbar = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation('translation');
  const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>(undefined);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const { logoutHelper, isAdmin } = useAuth();
  const {
    state: { currentPage },
  } = useAppContext();

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((state) => !state);
  }, []);

  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorElement(undefined);
  }, []);

  const handleLogout = useCallback(() => {
    void logoutHelper();
    setAnchorElement(undefined);
  }, [logoutHelper]);

  return (
    <StyledBox>
      {!isAdmin() && (
        <AppBar position="sticky">
          <Toolbar>
            <StyledIconButton onClick={handleDrawerToggle} size="large">
              <MenuIcon />
            </StyledIconButton>
            <StyledHeading content={currentPage ?? ''} variant={3} />
            <>
              <StyledIconButton onClick={handleMenu} size="large">
                <AccountCircleOutlinedIcon />
              </StyledIconButton>
              <Menu
                anchorEl={anchorElement}
                anchorOrigin={anchorOrigin as PopoverOrigin}
                keepMounted
                onClose={handleClose}
                open={Boolean(anchorElement)}
                transformOrigin={transformOrigin as PopoverOrigin}
              >
                <MenuItem onClick={handleLogout}>{t('layout.account.signOut')}</MenuItem>
              </Menu>
            </>
          </Toolbar>
        </AppBar>
      )}
      <Box component="nav">
        <StyledDrawer
          ModalProps={modalProps}
          container={document.body}
          onClose={handleDrawerToggle}
          open={mobileOpen}
          variant={isAdmin() ? 'permanent' : 'temporary'}
        >
          <DrawerContent handleDrawerToggle={handleDrawerToggle} />
        </StyledDrawer>
      </Box>
      {children}
    </StyledBox>
  );
};

export default Navbar;

import { useTranslation } from 'react-i18next';
import type { FieldErrorsImpl, Control, ControllerRenderProps } from 'react-hook-form';

import { useAdminContext } from '../../../../../context/Hooks';
import Paragraph from '../../../../../components/ui/Paragraph';
import ResponseAlert from '../../../../../components/ui/Modal/ResponseAlert';
import type { RegisterPaymentFormSchemaType } from '../../../../../utils/schemas/payment';
import RegisterPaymentFields from '../Fields';

type RegisterPaymentFieldType = {
  field: ControllerRenderProps<RegisterPaymentFormSchemaType, 'amount'>;
};

type ModalContentResponseProps = {
  content?: string;
  modalStatus: 'Error' | 'Success';
};

type RegisterPaymentFormProps = {
  control: Control<RegisterPaymentFormSchemaType>;
  errors: FieldErrorsImpl<RegisterPaymentFormSchemaType>;
  values?: {
    amount: string;
  };
};

const ModalContentResponse = ({ modalStatus, content = '' }: ModalContentResponseProps): JSX.Element => {
  const { t } = useTranslation('translation');
  return (
    <>
      <Paragraph content={t(`admin.billing.modals.registerPayment.content${modalStatus}`)} variant={1} />
      {content.length > 0 && <Paragraph content={content} variant={1} />}
    </>
  );
};

const RegisterPaymentForm = ({ control, errors, values }: RegisterPaymentFormProps) => {
  const { state } = useAdminContext();

  if (state.success)
    return (
      <ResponseAlert color="success" severity="success">
        <ModalContentResponse modalStatus="Success" />
      </ResponseAlert>
    );
  if (state.error)
    return (
      <ResponseAlert color="error" severity="error">
        <ModalContentResponse content={state.errors} modalStatus="Error" />
      </ResponseAlert>
    );

  return <RegisterPaymentFields control={control} errors={errors} values={values} />;
};

export type { RegisterPaymentFormProps, RegisterPaymentFieldType };
export default RegisterPaymentForm;

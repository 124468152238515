import { styled, Box, Drawer, IconButton } from '@mui/material';

import Heading from '../../../components/ui/Heading';

const StyledHeading = styled(Heading)`
  width: 100%;
  color: white;
  ${({ theme }) => theme.breakpoints.up('md')} {
    opacity: 0;
  }
`;

const StyledIconButton = styled(IconButton)`
  color: white;
`;

const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    box-sizing: border-box;
    width: 325px;
  }
`;

const StyledBox = styled(Box)`
  flex: 1;
`;

export { StyledHeading, StyledIconButton, StyledDrawer, StyledBox };

import { useLocation, Navigate } from 'react-router-dom';

import { getAuthData } from '../../../utils/services/auth';

const RequireAuth = ({ children }: React.PropsWithChildren) => {
  // Update this with JWT Token validation.
  const authData = getAuthData();
  const loc = useLocation();
  const navState = { from: loc };

  if (authData.accessToken.length === 0) {
    return <Navigate replace state={navState} to="/login" />;
  }
  return children as JSX.Element;
};

export default RequireAuth;

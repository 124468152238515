import type { RoomData } from '../api/rooms';

const getRoomsData = () => {
  const data = localStorage.getItem('rooms') ?? '';

  try {
    return JSON.parse(data) as RoomData[];
  } catch {
    return undefined;
  }
};

const getRoomData = (roomId: number) => getRoomsData()?.find((item) => item.id === roomId);

const setRoomsData = (data: RoomData[]) => {
  localStorage.setItem('rooms', JSON.stringify(data));
};

const updateRoomData = (data: RoomData) => {
  const roomsData = getRoomsData();
  if (roomsData) {
    const newData = Array.from(roomsData);
    const itemIndex = newData.findIndex((room) => room.id === data.id);
    // eslint-disable-next-line fp/no-mutation
    newData[itemIndex] = { ...newData[itemIndex], ...data };
    setRoomsData(newData);
  }
};

const removeRoomsData = () => {
  localStorage.removeItem('rooms');
};

const getAdminRoomData = () => {
  const data = localStorage.getItem('adminRoom') ?? '';

  try {
    return JSON.parse(data) as RoomData[];
  } catch {
    return undefined;
  }
};
const setAdminRoomData = (room: RoomData) => {
  localStorage.setItem('adminRoom', JSON.stringify(room));
};
const removeAdminRoomData = () => {
  localStorage.removeItem('adminRoom');
};

export {
  getRoomData,
  getRoomsData,
  updateRoomData,
  setRoomsData,
  removeRoomsData,
  getAdminRoomData,
  setAdminRoomData,
  removeAdminRoomData,
};

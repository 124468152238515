import { useCallback, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Skeleton } from '@mui/material';

import { getRoomPictureUrl } from '../../../../utils/helpers/rooms';

import { StyledCard, StyledCardAction, StyledHeading, StyledParagraph, StyledButton } from './styles';

type RoomCardProps = {
  action: (room: number) => void;
  actionTitle: string;
  description: string;
  title: string;
  id: number;
};

const RoomCard = ({ action, actionTitle, description, title, id }: RoomCardProps) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const handleClick = useCallback(() => {
    action(id);
  }, [action, id]);

  const handleImageLoad = useCallback(() => {
    setImageLoaded(true);
  }, []);

  const mainImage = getRoomPictureUrl(id, 0);

  return (
    <StyledCard>
      <CardActionArea onClick={handleClick}>
        {!imageLoaded && <Skeleton height={170} variant="rectangular" />}
        <CardMedia component="img" height={imageLoaded ? '170' : '0'} image={mainImage} onLoad={handleImageLoad} />
        <CardContent>
          <StyledHeading content={title} variant={3} />
          <StyledParagraph content={description} variant={2} />
        </CardContent>
      </CardActionArea>
      <StyledCardAction>
        <StyledButton color="primary" onClick={handleClick} size="small">
          {actionTitle}
        </StyledButton>
      </StyledCardAction>
    </StyledCard>
  );
};

export default RoomCard;

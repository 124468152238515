import { createContext, useCallback, useMemo, useReducer } from 'react';
import type { Dispatch } from 'react';

import { appReducer } from '../Reducers/app';
import { initialAppState } from '../Initials/app';
import { appActions } from '../Actions/app';
import type { IAppState } from '../Initials/app';
import type { IAppAction } from '../Actions/app';

interface IAppContext {
  state: IAppState;
  dispatch: Dispatch<IAppAction>;
  setPage: (page: string) => void;
}

const appStateContext = createContext<IAppContext | undefined>(undefined);

const AppProvider = ({ children }: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(appReducer, initialAppState);

  const setCurrentPageHelper = useCallback((page: string) => {
    dispatch({ type: appActions.setCurrentPage, payload: { currentPage: page } });
  }, []);

  const appValues = useMemo(
    () => ({ state, dispatch, setPage: setCurrentPageHelper }),
    [state, dispatch, setCurrentPageHelper],
  );

  return <appStateContext.Provider value={appValues}>{children}</appStateContext.Provider>;
};

export { AppProvider, appStateContext };

import { useTranslation } from 'react-i18next';
import type { FieldErrorsImpl, Control, ControllerRenderProps } from 'react-hook-form';

import { useAdminContext } from '../../../../../context/Hooks';
import Paragraph from '../../../../../components/ui/Paragraph';
import ResponseAlert from '../../../../../components/ui/Modal/ResponseAlert';
import type { EditRoomFormSchemaType } from '../../../../../utils/schemas/rooms';
import UserModalFields from '../Fields';

type EditRoomFieldType = {
  field:
    | ControllerRenderProps<EditRoomFormSchemaType, 'description'>
    | ControllerRenderProps<EditRoomFormSchemaType, 'name'>;
};

type ModalContentResponseProps = {
  content?: string;
  modalStatus: 'error' | 'success';
};

type RoomFormProps = {
  control: Control<EditRoomFormSchemaType>;
  errors: FieldErrorsImpl<EditRoomFormSchemaType>;
  values?: {
    name: string;
    description: string;
  };
};

const ModalContentResponse = ({ modalStatus, content = '' }: ModalContentResponseProps): JSX.Element => {
  const { t } = useTranslation('translation');
  return (
    <>
      <Paragraph content={t(`admin.billing.modals.editRoom.${modalStatus}`)} variant={1} />
      {content.length > 0 && <Paragraph content={content} variant={1} />}
    </>
  );
};

const RoomForm = ({ control, errors, values }: RoomFormProps) => {
  const { state } = useAdminContext();

  if (state.success)
    return (
      <ResponseAlert color="success" severity="success">
        <ModalContentResponse modalStatus="success" />
      </ResponseAlert>
    );
  if (state.error)
    return (
      <ResponseAlert color="error" severity="error">
        <ModalContentResponse content={state.errors} modalStatus="error" />
      </ResponseAlert>
    );

  return <UserModalFields control={control} errors={errors} values={values} />;
};

export type { RoomFormProps, EditRoomFieldType };
export default RoomForm;

import type { ThemeAttribute } from '.';

export const typography: ThemeAttribute = {
  heading1: '36px',
  heading2: '23px',
  heading3: '20px',
  subtitle1: '16px',
  subtitle2: '14px',
  caption: '12px',
};

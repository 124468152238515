import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../components/ui/Modal';
import { cancelReservation } from '../../../utils/api/reservations';
import { useAppContext } from '../../../context/Hooks';
import { appActions } from '../../../context/Actions/app';
import { myReservationsActions } from '../../../context/Actions/myReservations';

import { StyledParagraph } from './styles';

const ModalCancelContent = () => {
  const { t } = useTranslation('translation');

  return <StyledParagraph content={t('reservations.myReservations.modal.content')} variant={1} />;
};

const CancellationModal = () => {
  const { t } = useTranslation('translation');
  const {
    dispatch,

    state: {
      myReservationsPage: { myReservations, cancelable },
    },
  } = useAppContext();

  const handleModalAction = useCallback(async () => {
    if (cancelable && myReservations) {
      const result = await cancelReservation(cancelable.id);
      if (result.ok) {
        const updatedReservations = myReservations.map((reservation) =>
          reservation.id === cancelable.id ? { ...reservation, cancelled: true } : reservation,
        );
        dispatch({
          type: myReservationsActions.setMyReservations,

          payload: {
            myReservations: updatedReservations,
          },
        });
        dispatch({ type: appActions.closeModal });
      }
    }
  }, [cancelable, dispatch, myReservations]);

  return (
    <Modal
      buttonAction={handleModalAction}
      buttonLabel={t('reservations.myReservations.modal.button')}
      content={ModalCancelContent}
      title={t('reservations.myReservations.modal.title')}
    />
  );
};

export default CancellationModal;

import type { MyReservationsParsedItemType } from '../../utils/api/reservations';

const myReservationsActions = {
  setMyReservations: 'SET_MY_RESERVATIONS',
  setCancelable: 'SET_CANCELABLE',
};

type MyReservationsPayloadType = {
  cancelable?: MyReservationsParsedItemType;
  myReservations?: MyReservationsParsedItemType[];
};

interface IMyReservationsAction {
  type: string;
  payload: MyReservationsPayloadType;
}

export type { MyReservationsPayloadType, IMyReservationsAction };
export { myReservationsActions };

import { z as zod } from 'zod';

const registerPaymentFormSchema = zod.object({
  amount: zod.string().min(1, { message: 'form.errors.required' }),
});

const defaultRegisterPaymentValues = {
  amount: '',
};

type RegisterPaymentFormSchemaType = zod.infer<typeof registerPaymentFormSchema>;

export type { RegisterPaymentFormSchemaType };
export { registerPaymentFormSchema, defaultRegisterPaymentValues };

import styled from 'styled-components';
import { ListItemIcon, ListItemButton } from '@mui/material';

type ListItemProps = {
  secondary?: boolean;
};

const StyledListItemIcon = styled(ListItemIcon)<ListItemProps>`
  color: ${({ theme, secondary = false }) => (secondary ? theme.colors.neutral2 : theme.colors.primary1)};
  min-width: auto;
  margin-right: 1rem;
`;

const StyledListItemButton = styled(ListItemButton)`
  padding: 0.5rem 0;
`;

export { StyledListItemButton, StyledListItemIcon };

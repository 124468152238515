type UserDetailType = {
  userId: number;
  name: string;
  lastname: string;
  profession: string;
  phone: string;
  amount: string;
  lastPeriodBilling: {
    id: number;
    amount: string;
    title: string;
  }[];
};

const getUserDetailData = () => {
  const data = localStorage.getItem('userDetail') ?? '';

  try {
    return JSON.parse(data) as UserDetailType;
  } catch {
    return undefined;
  }
};

const setUserDetailData = (data: UserDetailType) => {
  localStorage.setItem('userDetail', JSON.stringify(data));
};

const clearUserDetailData = () => {
  localStorage.removeItem('userDetail');
};

export type { UserDetailType };

export { getUserDetailData, setUserDetailData, clearUserDetailData };

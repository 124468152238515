import { adminUsersActions } from './users';
import { adminPaymentActions } from './payment';
import { adminRoomsActions } from './rooms';
import type { AdminUsersPayloadType } from './users';
import type { AdminRoomsPayloadType } from './rooms';
import type { AdminPaymentPayloadType } from './payment';

const adminActions = {
  ...adminUsersActions,
  ...adminRoomsActions,
  ...adminPaymentActions,
};

type AdminPayloadType = AdminPaymentPayloadType & AdminRoomsPayloadType & AdminUsersPayloadType;

interface IAdminAction {
  type: string;
  payload?: AdminPayloadType;
}

export type { AdminPayloadType, IAdminAction };
export { adminActions };

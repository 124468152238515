import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import GridOffOutlinedIcon from '@mui/icons-material/GridOffOutlined';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { momentLocalizer } from 'react-big-calendar';
import { TextField } from '@mui/material';
import moment from 'moment';
import type { Moment } from 'moment';
import 'moment/locale/es';

import {
  calendarDateFormat,
  weekDays,
  calendarViews,
  daytimeThreshold,
  dateFormat,
} from '../../../../../../utils/schemas/datetime';
import ReservationDetailModal from '../../../../Modals/ReservationDetailModal';
import DisableReservationModal from '../../../../Modals/DisableReservationModal';
import { getCalendarEvents } from '../../../../../../utils/api/admin-room-reservations';
import type { ParsedCalendarEventsType } from '../../../../../../utils/api/admin-room-reservations';
import { parseEvents } from '../../../../../../utils/helpers/reservations';
import { useAdminHandlers } from '../../../../../../context/Hooks';

import {
  StyledCalendar,
  StyledInlineToolbar,
  StyledMonthAndYear,
  StyledMonthNavigation,
  StyledArrowButton,
  StyledNavigationWrapper,
  StyledButtonLabel,
  StyledButton,
} from './styles';

moment.locale('es', { weekday: 'dddd' });
const localizer = momentLocalizer(moment);
const min = new Date();
const max = new Date();
min.setHours(daytimeThreshold.startHour, daytimeThreshold.startMinute, 0);
max.setHours(daytimeThreshold.endHour, daytimeThreshold.endMinute, 0);

const RoomEventsCalendar = () => {
  const [selectedDay, setSelectedDay] = useState(moment().format(calendarDateFormat));
  const [events, setEvents] = useState<ParsedCalendarEventsType>();
  const { handleEventSelection, handleEventStyle, handleDisableReservation } = useAdminHandlers();
  const { t } = useTranslation('translation');
  const { id } = useParams();

  const handleNavigateBack = useCallback(() => {
    setSelectedDay(moment(selectedDay).subtract(weekDays, 'days').format(calendarDateFormat));
  }, [selectedDay]);

  const handleNavigateForward = useCallback(() => {
    setSelectedDay(moment(selectedDay).add(weekDays, 'days').format(calendarDateFormat));
  }, [selectedDay]);

  const handleDateSelection = useCallback((value: Moment | null) => {
    if (value) setSelectedDay(value.format(calendarDateFormat));
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      const response = await getCalendarEvents(
        Number(id),
        moment(selectedDay).startOf('week').format(dateFormat),
        moment(selectedDay).endOf('week').format(dateFormat),
      );
      setEvents(parseEvents(response));
    };
    void fetchEvents();
  }, [selectedDay, id]);

  return (
    <>
      <StyledInlineToolbar>
        <StyledNavigationWrapper>
          <StyledMonthAndYear content={moment(selectedDay).format('MMMM YYYY')} variant={1} />
          <StyledMonthNavigation>
            <StyledArrowButton onClick={handleNavigateBack}>
              <NavigateBeforeIcon />
            </StyledArrowButton>
            <StyledArrowButton onClick={handleNavigateForward}>
              <NavigateNextIcon />
            </StyledArrowButton>
          </StyledMonthNavigation>
        </StyledNavigationWrapper>
        <LocalizationProvider dateAdapter={AdapterMoment} locale="es">
          <MobileDatePicker
            inputFormat={calendarDateFormat}
            label="Ir a Fecha"
            onChange={handleDateSelection}
            // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop, react/jsx-props-no-spreading
            renderInput={(props) => <TextField {...props} />}
            value={selectedDay}
          />
        </LocalizationProvider>
        <StyledButton
          color="secondary"
          onClick={handleDisableReservation}
          startIcon={<GridOffOutlinedIcon />}
          variant="outlined"
        >
          <StyledButtonLabel>{t('admin.calendarDisable')}</StyledButtonLabel>
        </StyledButton>
      </StyledInlineToolbar>
      {Boolean(events) && (
        <StyledCalendar
          culture="es"
          date={selectedDay}
          defaultView="week"
          eventPropGetter={handleEventStyle}
          events={events}
          localizer={localizer}
          max={max}
          min={min}
          onSelectEvent={handleEventSelection}
          showMultiDayTimes={false}
          step={30}
          timeslots={1}
          toolbar={false}
          views={calendarViews}
        />
      )}
      <ReservationDetailModal />
      <DisableReservationModal />
    </>
  );
};

export default RoomEventsCalendar;

const adminPaymentActions = {
  setPaymentTarget: 'SET_PAYMENT_TARGET',
  clearPaymentTarget: 'CLEAR_PAYMENT_TARGET',
};

type AdminPaymentPayloadType = {
  paymentTarget?: {
    id: number;
    name: string;
  };
};

export type { AdminPaymentPayloadType };
export { adminPaymentActions };

import type { Noop, RefCallBack } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';

type RenderFieldType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void;
  onBlur: Noop;
  value: string;
  name: string;
  reference: RefCallBack;
  inputType: string;
  inputProps?: {
    label: {
      shrink: boolean;
    };
    adornment?: {
      endAdornment?: JSX.Element;
      startAdornment?: JSX.Element;
    };
  };
  errors?: string;
};

const RenderField = ({
  onChange,
  onBlur,
  value,
  name,
  reference,
  inputType,
  inputProps,
  errors = '',
}: RenderFieldType) => {
  const { t } = useTranslation('translation');
  return (
    <TextField
      InputLabelProps={inputProps?.label}
      InputProps={inputProps?.adornment}
      error={Boolean(errors)}
      fullWidth
      helperText={errors ? t(errors) : ''}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      ref={reference}
      type={inputType}
      value={value}
      variant="outlined"
    />
  );
};

export default RenderField;

import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LocalizationProvider, CalendarPicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Select, MenuItem, TextField, Stack } from '@mui/material';
import moment from 'moment';
import 'moment/locale/es';
import type { CalendarPickerView } from '@mui/x-date-pickers';
import type { SelectChangeEvent } from '@mui/material/Select';
import type { Moment, MomentInput } from 'moment';

import { useAppContext } from '../../../context/Hooks';
import { getRooms, getDisabledDays } from '../../../utils/api/rooms';
import { getRoomsData, setRoomsData } from '../../../utils/services/rooms';
import LoadingScreen from '../../../components/layout/LoadingScreen';
import { dateFormat } from '../../../utils/schemas/datetime';
import type { RoomData } from '../../../utils/api/rooms';
import { reservationsActions } from '../../../context/Actions/reservations';
import AnimatedPage from '../../../components/ui/AnimatedPage';

import ReservationModal from './ReservationModal';
import FloatingBottomBar from './FloatingBottomBar';
import AppointmentChips from './AppointmentChips';
import { StyledContainer, StyledCalendar, StyledFixedContainer } from './styles';
import CalendarDay from './CalendarDay';

// eslint-disable-next-line @typescript-eslint/naming-convention
const roomInputProps = { 'aria-label': 'Without label' };
const calendarViews = ['day'] as CalendarPickerView[];
const baseInputProps = { shrink: true, readOnly: true };
const dateNow: Moment = moment();

// eslint-disable-next-line max-statements
const NewReservationPage = () => {
  const { roomId } = useParams();
  const { t } = useTranslation('translation');
  const [date, setDate] = useState<Moment>(dateNow);
  const [pickerDate, setPickerDate] = useState({ year: dateNow.format('YYYY'), month: dateNow.format('MM') });
  const [rooms, setRooms] = useState<RoomData[] | undefined>(getRoomsData());
  const [room, setRoom] = useState<string>('');
  const { dispatch, setPage } = useAppContext();

  const handleRoomChange = useCallback(
    (event: SelectChangeEvent) => {
      setRoom(event.target.value);
      dispatch({ type: reservationsActions.selectRoom, payload: { room: event.target.value } });
    },
    [setRoom, dispatch],
  );

  const handleChange = useCallback(
    (value: unknown) => {
      const currentDate = moment(value as MomentInput);
      setDate(currentDate);
      dispatch({ type: reservationsActions.selectDate, payload: { date: currentDate.format(dateFormat) } });
    },
    [setDate, dispatch],
  );

  const handleMonthChange = useCallback((value: Moment) => {
    setPickerDate({ year: value.format('YYYY'), month: value.format('MM') });
  }, []);

  useEffect(() => {
    dispatch({ type: reservationsActions.selectRoom, payload: { room } });
    dispatch({ type: reservationsActions.selectDate, payload: { date: date.format(dateFormat) } });
  }, [dispatch, room, date]);

  useEffect(() => {
    const fetchDisabledDays = async () => {
      const disabledDays = await getDisabledDays(room, pickerDate.month, pickerDate.year);
      dispatch({ type: reservationsActions.setDisabledDays, payload: { disabledDays } });
    };
    if (room.length > 0) void fetchDisabledDays();
  }, [pickerDate, room, dispatch]);

  useEffect(() => {
    const fetchRooms = async () => {
      const response = await getRooms();
      setRooms(response);
      setRoomsData(response);
    };
    setPage(t('layout.navbar.newReservation'));
    if (roomId !== undefined && roomId.length > 0) setRoom(roomId);
    else if (rooms) setRoom(String(rooms[0].id));
    else void fetchRooms();
  }, [rooms, roomId, setPage, t]);

  if (!rooms || !room) return <LoadingScreen />;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale="es">
      <AnimatedPage>
        <StyledContainer>
          <StyledFixedContainer>
            <Stack alignItems="center" direction="row" spacing={1}>
              <Select autoWidth displayEmpty inputProps={roomInputProps} onChange={handleRoomChange} value={room}>
                {rooms.map((roomItem) => (
                  <MenuItem key={roomItem.id} value={String(roomItem.id)}>
                    {roomItem.name}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                InputLabelProps={baseInputProps}
                disabled
                id="date"
                name="date"
                onChange={handleChange}
                type="text"
                value={date.format(dateFormat)}
                variant="outlined"
              />
            </Stack>
          </StyledFixedContainer>
          <StyledCalendar>
            <CalendarPicker
              date={date}
              disablePast
              onChange={handleChange}
              onMonthChange={handleMonthChange}
              renderDay={CalendarDay}
              views={calendarViews}
            />
          </StyledCalendar>
          <AppointmentChips />
          <FloatingBottomBar />
        </StyledContainer>
        <ReservationModal />
      </AnimatedPage>
    </LocalizationProvider>
  );
};

export default NewReservationPage;

const dateFormat = 'DD/MM/YYYY';
const calendarDateFormat = 'YYYY-MM-DD';
const weekDays = 7;
const calendarViews = { week: true };
const daytimeThreshold = {
  startHour: 7,
  startMinute: 0,
  endHour: 23,
  endMinute: 59,
};

export { dateFormat, calendarDateFormat, weekDays, calendarViews, daytimeThreshold };

import type { IReservation } from '../../context/Initials/reservations';

import { apiFetch } from '.';

type AppointmentStatusType = 'available' | 'margin' | 'taken';

type AppointmentType = {
  id: number;
  value: string;
  status: AppointmentStatusType;
};

type MyReservationsItemType = {
  id: number;
  room: string;
  date: string;
  startTime: string;
  endTime: string;
  cancelled: boolean;
};
type MyReservationsParsedItemType = MyReservationsItemType & { handleClick?: () => void };

type AvailableAppointmentsResponseData = {
  data: AppointmentType[];
};
type MyReservationsResponseData = {
  data: MyReservationsItemType[];
};

const getAppointments = async (roomId: string, date: string) => {
  const response = await apiFetch('GET', `/api/v1/rooms/${roomId}/available_reservations?date=${date}`);
  const result = (await response.json()) as AvailableAppointmentsResponseData;
  return result.data;
};

const reserveAppointments = async (data: IReservation[]) => {
  const response = await apiFetch('POST', '/api/v1/create_reservations', { body: { reservations: data } });
  const status = String(response.status);
  if (status === '204') return { success: true };
  return { success: false };
};

const getMyReservations = async () => {
  const response = await apiFetch('GET', '/api/v1/professionals/reservations');
  const result = (await response.json()) as MyReservationsResponseData;
  return result.data;
};

const cancelReservation = async (id: number) => await apiFetch('DELETE', `/api/v1/blocks/${id}/cancel`);

export type { MyReservationsParsedItemType, MyReservationsItemType, AppointmentType, AppointmentStatusType };
export { getAppointments, getMyReservations, cancelReservation, reserveAppointments };

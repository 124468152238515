import { styled as styledMUI, Grid, Skeleton } from '@mui/material';
import styled from 'styled-components';

import Heading from '../../../components/ui/Heading';
import Paragraph from '../../../components/ui/Paragraph';

const StyledRoomDetailContainer = styled.main`
  padding: 2rem;
`;

const StyledHeading = styledMUI(Heading)`
  margin-bottom: 1rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 2rem;
    text-align: left;
  }
`;
const StyledParagraph = styledMUI(Paragraph)`
  margin-bottom: 1rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 2rem;
  }
`;

const StyledImgGridItem = styledMUI(Grid)`
  justify-content: center;
  display: flex;
  padding: 0.5rem;
  aspect-ratio: 1/1;
`;

const StyledAutoGrid = styledMUI(Grid)`
margin: 0 auto;
`;

type StyledImgprops = {
  loaded: boolean;
};
const StyledImg = styled.img<StyledImgprops>`
  cursor: pointer;
  object-fit: cover;
  width: ${(props) => (props.loaded ? '100%' : '0%')};
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 100%;
`;

export {
  StyledRoomDetailContainer,
  StyledHeading,
  StyledImgGridItem,
  StyledImg,
  StyledParagraph,
  StyledAutoGrid,
  StyledSkeleton,
};

import { useCallback } from 'react';

import type { AppointmentStatusType } from '../../../utils/api/reservations';

import { StyledChip } from './styles';

type AppointmentChipProps = {
  color?: 'default' | 'error' | 'info' | 'primary' | 'secondary' | 'success' | 'warning';
  id: number;
  isDisabled?: boolean;
  label: string;
  onClick: (id: number) => void;
  status: AppointmentStatusType;
  variant?: 'filled' | 'outlined';
};

const AppointmentChip = ({ color, id, isDisabled = false, label, onClick, status, variant }: AppointmentChipProps) => {
  const handleSelection = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  return (
    <StyledChip
      color={color}
      disabled={isDisabled}
      label={label}
      onClick={handleSelection}
      status={status}
      variant={variant}
    />
  );
};

export default AppointmentChip;

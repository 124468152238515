import styled from 'styled-components';

import Paragraph from '../../../components/ui/Paragraph';

type ButtonLabelType = {
  secondary?: boolean;
};

const StyledButtonLabel = styled.span<ButtonLabelType>`
  color: ${({ secondary = false, theme }) => (secondary ? theme.colors.primary1 : 'white')};
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  width: 100%;
  cursor: pointer;
`;

const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.neutral2};
`;

export { StyledButtonLabel, StyledParagraph };

import { initialAdminUsersState } from './users';
import { initialAdminPaymentState } from './payment';
import { initialAdminRoomsState } from './rooms';
import type { IAdminUsersState } from './users';
import type { IAdminRoomsState } from './rooms';
import type { IAdminPaymentState } from './payment';

type IAdminState = IAdminPaymentState & IAdminRoomsState & IAdminUsersState;

const initialAdminState = {
  ...initialAdminRoomsState,
  ...initialAdminUsersState,
  ...initialAdminPaymentState,
};

export type { IAdminState };
export { initialAdminState };

import { useTranslation } from 'react-i18next';
import type { FieldErrorsImpl, Control, ControllerRenderProps } from 'react-hook-form';

import { useAdminContext } from '../../../../../context/Hooks';
import Paragraph from '../../../../../components/ui/Paragraph';
import ResponseAlert from '../../../../../components/ui/Modal/ResponseAlert';
import type { RatesFormSchemaType } from '../../../../../utils/schemas/rates';
import RateModalFields from '../Fields';

type RateFieldType = {
  field:
    | ControllerRenderProps<RatesFormSchemaType, 'hourPrice'>
    | ControllerRenderProps<RatesFormSchemaType, 'reducedPrice'>;
};

type ModalContentResponseProps = {
  content?: string;
  modalStatus: 'error' | 'success';
};

type RateFormProps = {
  control: Control<RatesFormSchemaType>;
  errors: FieldErrorsImpl<RatesFormSchemaType>;
  values?: {
    hourPrice: string;
    reducedPrice: string;
  };
};

const ModalContentResponse = ({ modalStatus, content = '' }: ModalContentResponseProps): JSX.Element => {
  const { t } = useTranslation('translation');
  return (
    <>
      <Paragraph content={t(`admin.billing.modals.updateRate.${modalStatus}`)} variant={1} />
      {content.length > 0 && <Paragraph content={content} variant={1} />}
    </>
  );
};

const RateForm = ({ control, errors, values }: RateFormProps) => {
  const { state } = useAdminContext();

  if (state.success)
    return (
      <ResponseAlert color="success" severity="success">
        <ModalContentResponse modalStatus="success" />
      </ResponseAlert>
    );
  if (state.error)
    return (
      <ResponseAlert color="error" severity="error">
        <ModalContentResponse content={state.errors} modalStatus="error" />
      </ResponseAlert>
    );

  return <RateModalFields control={control} errors={errors} values={values} />;
};

export type { RateFormProps, RateFieldType };
export default RateForm;

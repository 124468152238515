import type { AlertProps } from '@mui/material';

import { StyledAlert } from './styles';

const ResponseAlert = ({ children, severity, color }: AlertProps) => (
  <StyledAlert color={color} severity={severity}>
    {children}
  </StyledAlert>
);

export default ResponseAlert;

import styled from 'styled-components';

const StyledParagraph1 = styled.p`
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  line-height: 24px;
  margin: 0;
`;

const StyledParagraph2 = styled.p`
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 20px;
  margin: 0;
`;

export { StyledParagraph1, StyledParagraph2 };

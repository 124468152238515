import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import type { Moment } from 'moment';

import PageContainer from '../../../../../components/ui/PageContainer';
import BillingStack from '../../../../../components/ui/Stack/Billing';
import { getBillingHistory, getRoomBillingHistory } from '../../../../../utils/api/billing';
import type { BillingStackItemType } from '../../../../../utils/api/billing';
import LoadingScreen from '../../../../../components/layout/LoadingScreen';
import AnimatedPage from '../../../../../components/ui/AnimatedPage';
import Paragraph from '../../../../../components/ui/Paragraph';
import { getCurrentUserId } from '../../../../../utils/services/auth';
import { calendarDateFormat, dateFormat } from '../../../../../utils/schemas/datetime';
import { getUserDetailData } from '../../../../../utils/services/detail';
import AdminHeader from '../../../../../components/ui/AdminHeader';
import { getRoomData } from '../../../../../utils/services/rooms';
import { useAuth } from '../../../../../context/Hooks';

import { StyledVisualizationBox, StyledBox, StyledTextField, StyledPagination, StyledBoldParagraph } from './styles';

// eslint-disable-next-line max-statements
const AdminBillingHistoryPage = ({ fromRoom = false }: { fromRoom: boolean }) => {
  const { t } = useTranslation('translation');
  const { id } = useParams();
  const { isAdmin } = useAuth();
  const roomData = getRoomData(Number(id));
  const userData = getUserDetailData();
  const [billingHistory, setBillingHistory] = useState<BillingStackItemType[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [selectedDay, setSelectedDay] = useState<string>();

  const headerTitle = useMemo(() => {
    return fromRoom ? roomData?.name ?? '' : `${userData?.name ?? ''} ${userData?.lastname ?? ''}`;
  }, [roomData, userData, fromRoom]);

  const handleDateSelection = useCallback((value: Moment | null) => {
    if (value) setSelectedDay(value.format(calendarDateFormat));
  }, []);

  const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  }, []);

  useEffect(() => {
    if ((fromRoom && !roomData) || !userData) window.location.replace('/');
  }, [fromRoom, roomData, userData]);

  useEffect(() => {
    const fetchBillingHistory = async () => {
      const userId = getCurrentUserId();
      const billingHistoryResponse = fromRoom
        ? await getRoomBillingHistory(Number(id), currentPage, moment(selectedDay ?? undefined).format(dateFormat))
        : await getBillingHistory(
            isAdmin() ? Number(id) : userId,
            currentPage,
            moment(selectedDay ?? undefined).format(dateFormat),
          );
      setBillingHistory(billingHistoryResponse.data);
      setTotalPages(billingHistoryResponse.totalPages);
    };
    void fetchBillingHistory();
  }, [currentPage, fromRoom, id, isAdmin, selectedDay]);

  if (!billingHistory) return <LoadingScreen />;

  return (
    <AnimatedPage>
      <AdminHeader subtitle={t('billing.history')} title={headerTitle} />
      <PageContainer>
        <StyledBox>
          <StyledVisualizationBox alignLeft>
            <Paragraph content={t('billing.visualized.period')} variant={1} />
            <StyledBoldParagraph content={moment(selectedDay).format('MM-YYYY')} variant={1} />
            <Paragraph content={t('billing.visualized.day')} variant={1} />
            <StyledBoldParagraph
              content={`${moment(selectedDay).format('DD')} ${moment(selectedDay).format('MMMM')}`}
              variant={1}
            />
          </StyledVisualizationBox>
          <StyledVisualizationBox>
            <LocalizationProvider dateAdapter={AdapterMoment} locale="es">
              <MobileDatePicker
                inputFormat={calendarDateFormat}
                label={t('billing.date')}
                onChange={handleDateSelection}
                // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop, react/jsx-props-no-spreading
                renderInput={(props) => <StyledTextField {...props} />}
                value={selectedDay}
              />
            </LocalizationProvider>
          </StyledVisualizationBox>
        </StyledBox>
        <BillingStack hasSubtitle items={billingHistory} />
        <StyledPagination color="primary" count={totalPages} onChange={handlePageChange} page={currentPage} />
      </PageContainer>
    </AnimatedPage>
  );
};

export default AdminBillingHistoryPage;

import { useCallback, useEffect, useState } from 'react';
import type { SyntheticEvent, ReactEventHandler, MouseEvent as ReactMouseEvent } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';

import AnimatedPage from '../../../components/ui/AnimatedPage';
import StickyButton from '../../../components/ui/StickyButton';
import ImagePreview from '../../../components/ui/ImagePreview';
import LoadingScreen from '../../../components/layout/LoadingScreen';
import { getRoomData } from '../../../utils/services/rooms';
import { getRoomPictureUrl } from '../../../utils/helpers/rooms';
import type { RoomData } from '../../../utils/api/rooms';

import {
  StyledRoomDetailContainer,
  StyledHeading,
  StyledParagraph,
  StyledImgGridItem,
  StyledAutoGrid,
  StyledImg,
  StyledSkeleton,
} from './styles';

type ImageLoadedType = {
  [key: string]: boolean;
};

// eslint-disable-next-line max-statements
const RoomDetailPage = () => {
  const { id } = useParams();
  const [room, setRoom] = useState<RoomData | undefined>(undefined);
  const [showPreview, setShowPreview] = useState<string>('');
  const [imageLoaded, setImageLoaded] = useState<ImageLoadedType>({
    image0: false,
    image1: false,
    image2: false,
    image3: false,
  });
  const [imageSource, setImageSource] = useState<string[]>([]);

  const numberId = Number(id);

  const handleImageClick = useCallback((event: ReactMouseEvent<HTMLImageElement>) => {
    const target = event.target as HTMLImageElement;
    setShowPreview(target.src);
  }, []);

  const handleClose = useCallback(() => {
    setShowPreview('');
  }, []);

  const handleImageLoad: ReactEventHandler<HTMLImageElement> = useCallback(
    (event: SyntheticEvent<HTMLImageElement>) => {
      const imageId = Number((event.target as Element).id);
      setImageLoaded((previous) => ({ ...previous, [`image${imageId}`]: true }));
    },
    [],
  );

  useEffect(() => {
    if (numberId) setRoom(getRoomData(numberId));
  }, [numberId]);

  useEffect(() => {
    if (imageSource.length === 0) {
      const numberArray: number[] = Array.from(Array.from({ length: 4 }).keys());
      numberArray.forEach((item: number) => {
        setImageSource((previous) => [...previous, getRoomPictureUrl(numberId, item)]);
      });
    }
  }, [imageSource, numberId]);

  if (!room) return <LoadingScreen />;
  return (
    <AnimatedPage>
      <StyledRoomDetailContainer>
        <Grid container>
          <StyledAutoGrid item md={5} sm={6} xs={12}>
            <StyledHeading content={room.name} variant={3} />
            <StyledParagraph content={room.description ?? ''} variant={2} />
          </StyledAutoGrid>
          <StyledAutoGrid item md={4} sm={6} xs={12}>
            <Grid container>
              {imageSource.length > 0 &&
                imageSource.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <StyledImgGridItem item key={index} xs={6}>
                    {!imageLoaded[`image${index}`] && <StyledSkeleton variant="rectangular" />}
                    <StyledImg
                      alt="Consultorio"
                      id={String(index)}
                      loaded={imageLoaded[`image${index}`]}
                      onClick={handleImageClick}
                      onLoad={handleImageLoad}
                      src={item}
                    />
                  </StyledImgGridItem>
                ))}
            </Grid>
          </StyledAutoGrid>
        </Grid>
        <StickyButton
          content="rooms.reserve"
          icon={<InsertInvitationOutlinedIcon />}
          path={`/reserve/new/${id ?? ''}`}
        />
        {Boolean(showPreview) && <ImagePreview handleClose={handleClose} image={showPreview} />}
      </StyledRoomDetailContainer>
    </AnimatedPage>
  );
};

export default RoomDetailPage;

import { useTheme } from 'styled-components';
import moment from 'moment';

import { appActions } from '../../Actions/app';
import { adminActions } from '../../Actions/admin';
import { useAdminContext, useAppContext } from '..';
import type { CalendarReservationType } from '../../Actions/admin/rooms';
import type { CalendarEventType } from '../../../utils/api/admin-room-reservations';

const useAdminRoomCalendarHandlers = () => {
  const { dispatch: appDispatch } = useAppContext();
  const { dispatch: adminDispatch } = useAdminContext();
  const theme = useTheme();

  const handleReservationDetail = (data: CalendarReservationType) => {
    adminDispatch({
      type: adminActions.setCalendarReservation,

      payload: {
        calendarReservation: data,
      },
    });
    appDispatch({ type: appActions.setModalName, payload: { modalName: 'reservationDetail' } });
    appDispatch({ type: appActions.launchModal });
  };

  const handleEventSelection = (event: object) => {
    const selection = event as CalendarEventType;
    if (selection.title === 'Reserva')
      handleReservationDetail({
        id: String(selection.id),
        professional: selection.professional,
        date: moment(selection.start).format('dddd DD MMMM'),
        appointment: `${moment(selection.start).format('kk:mm')}-${moment(selection.end).format('kk:mm')}`,
      });
  };

  const handleDisableReservation = () => {
    appDispatch({ type: appActions.setModalName, payload: { modalName: 'disableReservation' } });
    appDispatch({ type: appActions.launchModal });
  };

  const handleEventStyle = (event: object) => {
    const calendarEvent = event as CalendarEventType;
    const disabledOpacity = 0.65;
    return {
      style: {
        ...(calendarEvent.title !== 'Reserva' && {
          backgroundColor: calendarEvent.title === 'Deshabilitado' ? theme.colors.neutral3 : theme.colors.primary4,
          opacity: calendarEvent.title === 'Deshabilitado' ? disabledOpacity : 1,
          cursor: 'not-allowed',
        }),
      },
    };
  };

  return {
    handleEventStyle,
    handleReservationDetail,
    handleDisableReservation,
    handleEventSelection,
  };
};

export { useAdminRoomCalendarHandlers };

import styled from 'styled-components';
import { Stack } from '@mui/material';

import Subtitle from '../Subtitle';
import Paragraph from '../Paragraph';

type StyledStackItemProps = {
  hasSubtitle?: boolean;
};

const StyledStackBox = styled.dl`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: 1.5rem 18px;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.primary4};
`;
const StyledStackItem = styled.dt<StyledStackItemProps>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  width: calc(100% - 32px);
  margin: 0 1rem;
  position: relative;

  &::after {
    display: block;
    clear: both;
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: ${({ hasSubtitle = false }) => (hasSubtitle ? '0.5rem' : '1rem')};
    left: 0;
    border-bottom: 1px solid black;
  }
`;

const StyledStack = styled(Stack)`
  flex: 1;
`;

const StyledSubtitle = styled(Subtitle)`
  color: ${({ theme }) => theme.colors.neutral2};
  text-align: left;
`;
const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.neutral2};
`;

const StyledEmptyBilling = styled(StyledParagraph)`
  padding: 1rem 0;
  text-align: center;
`;

export { StyledStackBox, StyledStackItem, StyledStack, StyledSubtitle, StyledParagraph, StyledEmptyBilling };

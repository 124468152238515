import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { SubmitHandler } from 'react-hook-form';

import Modal from '../../../../components/ui/Modal';
import { useAdminContext, useAppContext } from '../../../../context/Hooks';
import { editRoom } from '../../../../utils/api/rooms';
import { adminActions } from '../../../../context/Actions/admin';
import { appActions } from '../../../../context/Actions/app';
import { editRoomFormSchema } from '../../../../utils/schemas/rooms';
import { updateRoomData } from '../../../../utils/services/rooms';
import type { EditRoomFormSchemaType } from '../../../../utils/schemas/rooms';

import RoomForm from './Content';

const RoomFormModal = () => {
  const { t } = useTranslation('translation');
  const {
    state: { roomData },
    dispatch,
    modalStatus,
  } = useAdminContext();
  const { dispatch: appDispatch } = useAppContext();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<EditRoomFormSchemaType>({
    resolver: zodResolver(editRoomFormSchema),
  });

  const modalContent = useCallback(() => <RoomForm control={control} errors={errors} />, [errors, control]);

  const handleCloseModal = useCallback(() => {
    appDispatch({ type: appActions.setModalName, payload: { modalName: '' } });
    dispatch({ type: adminActions.clearRoomData });
    dispatch({ type: adminActions.clearError });
    if (modalStatus === 'Success') window.location.reload();
  }, [modalStatus, appDispatch, dispatch]);

  const buttonAction = useCallback(async () => {
    const onSubmit: SubmitHandler<EditRoomFormSchemaType> = async (room: EditRoomFormSchemaType) => {
      try {
        const response = await editRoom({ ...room, id: roomData?.id });
        if (response.ok) {
          dispatch({ type: adminActions.setSuccess });
          updateRoomData({ ...roomData, ...room });
        } else {
          dispatch({ type: adminActions.setError, payload: { errors: '' } });
        }
      } catch (responseError) {
        console.error(responseError);
      }
    };
    if (modalStatus === 'Pending' && roomData) {
      await handleSubmit(onSubmit)();
    } else {
      handleCloseModal();
      dispatch({ type: appActions.closeModal });
      if (modalStatus === 'Success') window.location.reload();
    }
  }, [modalStatus, roomData, dispatch, handleSubmit, handleCloseModal]);

  useEffect(() => {
    if (roomData) reset(roomData);
  }, [reset, roomData]);

  return (
    <Modal
      buttonAction={buttonAction}
      buttonLabel={t(`admin.billing.modals.editRoom.buttonLabel${modalStatus}`)}
      content={modalContent}
      handleClose={handleCloseModal}
      modalName="editRoom"
      shouldClose={modalStatus !== 'Pending'}
      title={t(`admin.billing.modals.editRoom.title`)}
    />
  );
};

export default RoomFormModal;

import styled, { css } from 'styled-components';

import type { StyledTextComponent } from '../../../utils/types/text';

const sharedHeadingProps = css<StyledTextComponent>`
  font-family: ${({ secondary = false }) => (secondary ? 'Montserrat' : 'Merriweather')};
  font-weight: ${({ secondary = false }) => (secondary ? '600' : '700')};
  text-align: center;
  letter-spacing: 1.25px;
  margin: 0;
`;

const StyledHeading1 = styled.h1<StyledTextComponent>`
  font-size: 36px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.primary1};
  ${sharedHeadingProps};
`;

const StyledHeading2 = styled.h2<StyledTextComponent>`
  font-size: 23px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.primary1};
  ${sharedHeadingProps};
`;
const StyledHeading3 = styled.h3<StyledTextComponent>`
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.primary2};
  ${sharedHeadingProps};
`;

export { StyledHeading1, StyledHeading2, StyledHeading3 };

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from '../../../../context/Hooks';
import { setReservationsData } from '../../../../utils/services/reservations';

import { StyledBackgroundContainer, StyledButton, StyledButtonLabel, StyledArrowForwardOutlinedIcon } from './styles';

const FloatingBottomBar = () => {
  const { t } = useTranslation('translation');
  const {
    state: {
      reservationsPage: { reservations },
    },
  } = useAppContext();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    setReservationsData(reservations!);
    navigate('/reserve/confirm/');
  }, [navigate, reservations]);

  return (
    <StyledBackgroundContainer>
      <StyledButton
        disabled={!reservations || reservations.length === 0}
        endIcon={<StyledArrowForwardOutlinedIcon />}
        onClick={handleClick}
        variant="contained"
      >
        <StyledButtonLabel>{t('reservations.bottomBar')}</StyledButtonLabel>
      </StyledButton>
    </StyledBackgroundContainer>
  );
};

export default FloatingBottomBar;

import { Avatar, Box, Button } from '@mui/material';
import styled from 'styled-components';

import Heading from '../Heading';
import Subtitle from '../Subtitle';

type StyledHeaderProps = { scrolled: boolean };

const StyledAdminHeader = styled(Box)<StyledHeaderProps>`
  width: 100%;
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.colors.primary2};
  position: fixed;
  z-index: 5;
  display: flex;
  align-items: end;
  height: 100px;
  max-height: ${({ scrolled }) => (scrolled ? '40px' : '100px')};
  transition: max-height 0.4s ease-in-out;
`;

const StyledHeading = styled(Heading)`
  color: white;
  text-align: left;
`;
const StyledSubtitle = styled(Subtitle)`
  color: white;
  text-align: left;
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 2rem;
`;

const StyledAvatar = styled(Avatar)`
  height: 100%;
  width: auto;
  margin: auto 0 auto 1.5rem;
  aspect-ratio: 1/1;
  background-color: #fcd87e;
  font-family: 'Montserrat';
`;

const StyledButtonLabel = styled.span`
  color: ${({ theme }) => theme.colors.primary1};
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  position: fixed;
  right: 2rem;
  top: 5rem;
  &,
  &:hover {
    background-color: white;
  }
`;

export { StyledAdminHeader, StyledHeading, StyledSubtitle, StyledCol, StyledAvatar, StyledButtonLabel, StyledButton };

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { SubmitHandler } from 'react-hook-form';

import Modal from '../../../../components/ui/Modal';
import { useAdminContext, useAppContext } from '../../../../context/Hooks';
import { registerPayment } from '../../../../utils/api/payment';
import { adminActions } from '../../../../context/Actions/admin';
import { appActions } from '../../../../context/Actions/app';
import { registerPaymentFormSchema } from '../../../../utils/schemas/payment';
import type { RegisterPaymentFormSchemaType } from '../../../../utils/schemas/payment';

import RegisterPaymentForm from './Content';

const RegisterPaymentModal = () => {
  const { t } = useTranslation('translation');
  const {
    state: { paymentTarget },
    dispatch,
    modalStatus,
  } = useAdminContext();
  const { dispatch: appDispatch } = useAppContext();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<RegisterPaymentFormSchemaType>({
    resolver: zodResolver(registerPaymentFormSchema),
  });

  const modalContent = useCallback(() => <RegisterPaymentForm control={control} errors={errors} />, [errors, control]);

  const handleCloseModal = useCallback(() => {
    appDispatch({ type: appActions.setModalName, payload: { modalName: '' } });
    dispatch({ type: adminActions.clearPaymentTarget });
    dispatch({ type: adminActions.clearError });
  }, [appDispatch, dispatch]);

  const buttonAction = useCallback(async () => {
    const onSubmit: SubmitHandler<RegisterPaymentFormSchemaType> = async (formData: RegisterPaymentFormSchemaType) => {
      const response = await registerPayment({ amount: formData.amount, userId: paymentTarget.id });
      if (response.errors) {
        dispatch({ type: adminActions.setError, payload: { errors: response.errors.amount?.join(',') } });
      } else {
        dispatch({ type: adminActions.setSuccess });
      }
    };
    if (modalStatus === 'Pending') {
      await handleSubmit(onSubmit)();
    } else {
      handleCloseModal();
      dispatch({ type: appActions.closeModal });
    }
  }, [modalStatus, paymentTarget, dispatch, handleSubmit, handleCloseModal]);

  return (
    <Modal
      buttonAction={buttonAction}
      buttonLabel={t(`admin.billing.modals.registerPayment.buttonLabel${modalStatus}`)}
      content={modalContent}
      handleClose={handleCloseModal}
      modalName="registerPayment"
      shouldClose={modalStatus !== 'Pending'}
      title={t(`admin.billing.modals.registerPayment.title`)}
    />
  );
};

export default RegisterPaymentModal;

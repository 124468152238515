import { initialReservationsState } from './reservations';
import type { IReservationsState } from './reservations';
import { initialMyReservationsState } from './myReservations';
import type { IMyReservationsState } from './myReservations';

interface IAppBaseState {
  loading: boolean;
  error?: string;
  currentPage?: string;
  modalVisible: boolean;
  modalName?: string;
}

type IAppState = IAppBaseState & IMyReservationsState & IReservationsState;

const initialAppState = {
  loading: false,
  currentPage: '',
  modalVisible: false,
  modalName: '',
  ...initialReservationsState,
  ...initialMyReservationsState,
};

export type { IAppState };
export { initialAppState };

import type { IReservation } from '../Initials/reservations';

const reservationsActions = {
  selectDate: 'SELECT_DATE',
  selectRoom: 'SELECT_ROOM',
  selectDuration: 'SELECT_DURATION',
  selectTime: 'SELECT_TIME',
  submitSelection: 'SUBMIT_SELECTION',
  setAvailability: 'SET_AVAILABILITY',
  removeReservation: 'REMOVE_RESERVATION',
  setReservations: 'SET_RESERVATIONS',
  setSuccess: 'SET_SUCCESS',
  setError: 'SET_ERROR',
  setDisabledDays: 'SET_DISABLED_DAYS',
};

type ReservationsPayloadType = {
  duration?: string;
  date?: string;
  room?: string;
  time?: string;
  reservation?: IReservation;
  availability?: string[];
  id?: number;
  reservations?: IReservation[];
  successResponse?: boolean;
  errorResponse?: boolean;
  disabledDays?: string[];
};

interface IReservationsAction {
  type: string;
  payload: ReservationsPayloadType;
}

export type { ReservationsPayloadType, IReservationsAction };
export { reservationsActions };

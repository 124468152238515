import styled from 'styled-components';
import { Box, styled as styledMUI } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Heading from '../Heading';

type ModalBackgroundProps = { isAdmin: boolean };
const StyledModalBackground = styled.main<ModalBackgroundProps>`
  position: fixed;
  width: ${({ isAdmin }) => (isAdmin ? 'calc(100vw - 325px)' : '100vw')};
  height: ${({ isAdmin }) => (isAdmin ? '100vh' : 'calc(100vh - 45px)')};
  top: ${({ isAdmin }) => (isAdmin ? '0' : '56px')};
  z-index: ${({ isAdmin }) => (isAdmin ? '5' : '4')};
  right: 0;
  background-color: #2b2b2bd9;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledModalContainer = styledMUI(Box)`
  width: 80%;
  max-width: 500px;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: white;
  animation: fadeIn 0.5s ease;
  ${({ theme }) => theme.breakpoints.between('lg', 'xl')} {
    max-height: 90vh;
    overflow: auto;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    flex-direction: revert;
  }

  @keyframes fadeIn {
    0% { opacity: 0.2; }
    10% { opacity: 0.3; }
    30% { opacity: 0.5; }
    50% { opacity: 0.75; }
    70% { opacity: 0.85; }
    90% { opacity: 0.95; }
    100% { opacity: 1; }
  }
`;

type ButtonLabelProps = { primary?: boolean };

const StyledButtonLabel = styled.span<ButtonLabelProps>`
  color: ${({ primary = false, theme }) => (primary ? 'white' : theme.colors.primary1)};
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  width: 100%;
  cursor: pointer;
`;

const StyledTitle = styled(Heading)`
  &::after {
    display: block;
    clear: both;
    content: '';
    width: 140px;
    height: 4px;
    position: relative;
    bottom: 0;
    left: 0;
    margin: 0.5rem auto;
    border-bottom: 4px solid ${({ theme }) => theme.colors.primary2};
  }
`;

const StyledCloseButton = styled(CloseIcon)`
  position: absolute;
  display: block;
  z-index: 4;
  cursor: pointer;
  top: 1rem;
  right: 1rem;
  width: 20px;
  height: 20px;
`;

const StyledContentWrapper = styled.div`
  width: 80%;
  margin: 1rem 0;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;

export {
  StyledModalContainer,
  StyledModalBackground,
  StyledTitle,
  StyledContentWrapper,
  StyledButtonLabel,
  StyledCloseButton,
  StyledButtonWrapper,
};

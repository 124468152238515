import { useCallback, useEffect, useState } from 'react';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import type { Moment } from 'moment';

import PageContainer from '../../../components/ui/PageContainer';
import BillingStack from '../../../components/ui/Stack/Billing';
import { getBillingHistory } from '../../../utils/api/billing';
import type { BillingStackItemType } from '../../../utils/api/billing';
import LoadingScreen from '../../../components/layout/LoadingScreen';
import Heading from '../../../components/ui/Heading';
import AnimatedPage from '../../../components/ui/AnimatedPage';
import Paragraph from '../../../components/ui/Paragraph';
import { getCurrentUserId } from '../../../utils/services/auth';
import { calendarDateFormat, dateFormat } from '../../../utils/schemas/datetime';

import { StyledBox, StyledTextField, StyledPagination, StyledVisualizationBox, StyledBoldParagraph } from './styles';

const BillingHistoryPage = () => {
  const { t } = useTranslation('translation');
  const [billingHistory, setBillingHistory] = useState<BillingStackItemType[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [selectedDay, setSelectedDay] = useState<string>();

  const handleDateSelection = useCallback((value: Moment | null) => {
    if (value) setSelectedDay(value.format(calendarDateFormat));
  }, []);

  const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  }, []);

  useEffect(() => {
    const fetchBillingHistory = async () => {
      const userId = getCurrentUserId();
      const billingHistoryResponse = await getBillingHistory(
        userId,
        currentPage,
        moment(selectedDay ?? undefined).format(dateFormat),
      );
      setBillingHistory(billingHistoryResponse.data);
      setTotalPages(billingHistoryResponse.totalPages);
    };
    void fetchBillingHistory();
  }, [currentPage, selectedDay]);

  if (!billingHistory) return <LoadingScreen />;

  return (
    <AnimatedPage>
      <PageContainer>
        <Heading content={t('billing.history')} variant={3} />
        <StyledBox>
          <StyledVisualizationBox alignLeft>
            <Paragraph content={t('billing.visualized.period')} variant={1} />
            <StyledBoldParagraph content={moment(selectedDay).format('MM-YYYY')} variant={1} />
            <Paragraph content={t('billing.visualized.day')} variant={1} />
            <StyledBoldParagraph
              content={`${moment(selectedDay).format('DD')} ${moment(selectedDay).format('MMMM')}`}
              variant={1}
            />
          </StyledVisualizationBox>
          <StyledVisualizationBox>
            <LocalizationProvider dateAdapter={AdapterMoment} locale="es">
              <MobileDatePicker
                inputFormat="DD-MM-YYYY"
                label={t('billing.date')}
                onChange={handleDateSelection}
                // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop, react/jsx-props-no-spreading
                renderInput={(props) => <StyledTextField {...props} />}
                value={selectedDay}
              />
            </LocalizationProvider>
          </StyledVisualizationBox>
        </StyledBox>
        <BillingStack hasSubtitle items={billingHistory} />
        <StyledPagination color="primary" count={totalPages} onChange={handlePageChange} page={currentPage} />
      </PageContainer>
    </AnimatedPage>
  );
};

export default BillingHistoryPage;

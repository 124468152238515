import type { ThemeAttribute } from '.';

export const colors: ThemeAttribute = {
  primary1: '#E09900',
  primary2: '#FFB600',
  primary3: '#FCD87E',
  primary4: '#FAEFD3',
  secondary1: '#2C0046',
  secondary2: '#550087',
  secondary3: '#A778C2',
  secondary4: '#F8EDFE',
  neutral1: '#2B2B2B',
  neutral2: '#505050',
  neutral3: '#A3A3A3',
  neutral4: '#FFFFFF',
};

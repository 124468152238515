import styled from 'styled-components';
import { Select, FormControl, InputLabel } from '@mui/material';

import Paragraph from '../../../../components/ui/Paragraph';
import Subtitle from '../../../../components/ui/Subtitle';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 1rem;
`;

const StyledInputLabel = styled(InputLabel)`
  background: white;
`;

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`;

const StyledInfoLabel = styled(Subtitle)`
  align-self: center;
  margin: 0;
  text-align: center;
  padding: 0.75rem 0;
`;

const StyledInfoValue = styled(Paragraph)`
  text-align: center;
  width: 100%;
`;

export { StyledFormControl, StyledInputLabel, StyledSelect, StyledParagraph, StyledInfoLabel, StyledInfoValue };

import { Pagination, TextField } from '@mui/material';
import styled from 'styled-components';

import Paragraph from '../../../components/ui/Paragraph';

const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 1.5rem 0 0.25rem 0;
`;

const StyledTextField = styled(TextField)`
  max-width: 300px;
  margin-left: 1rem;
`;

const StyledPagination = styled(Pagination)`
  padding: 1rem 0 2rem 0;
`;

type VisualizationBoxProps = {
  alignLeft?: boolean;
};
const StyledVisualizationBox = styled.div<VisualizationBoxProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 1rem;
  align-items: ${(props) => (props.alignLeft ?? false ? 'flex-start' : 'flex-end')};
`;

const StyledBoldParagraph = styled(Paragraph)`
  font-weight: bold;
`;
export { StyledBox, StyledTextField, StyledPagination, StyledVisualizationBox, StyledBoldParagraph };

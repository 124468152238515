const roomPicturesBaseUrl = 'https://casaescondidastaging-bucket83908e77-1ne54su5ph4kj.s3.amazonaws.com/public/rooms/';

const indexToLetter = {
  picture0: 'a',
  picture1: 'b',
  picture2: 'c',
  picture3: 'd',
};

type IndexToLetterType = typeof indexToLetter;

const getRoomPictureUrl = (room: number, index: number) => {
  const pictureNumber: string = indexToLetter[`picture${index}` as keyof IndexToLetterType];
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const roomNumber = room >= 3 ? room + 1 : room;
  return roomPicturesBaseUrl.concat(`room${roomNumber}-${pictureNumber}-min.jpg`);
};

export { getRoomPictureUrl };

import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import RenderField from '../../../../../components/form/Field';
import { useAdminContext } from '../../../../../context/Hooks';
import type { CreateUserFieldType, CreateUserFormProps } from '../Content';
import { StyledSubtitle } from '../styles';

const inputProps = {
  label: { shrink: true },
};

const UserModalFields = ({ control, errors }: CreateUserFormProps) => {
  const { t } = useTranslation('translation');
  const {
    state: { isEditingUser },
  } = useAdminContext();

  const renderCreateUserField = useCallback(
    ({ field: { onChange, onBlur, value, name, ref: reference } }: CreateUserFieldType) => {
      return (
        <RenderField
          errors={errors[name]?.message}
          inputProps={inputProps}
          inputType="text"
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          reference={reference}
          value={value}
        />
      );
    },
    [errors],
  );

  return (
    <Stack alignItems="center" spacing={2}>
      <StyledSubtitle content={t('admin.billing.modals.createUser.fields.name')} variant={3} />
      <Controller control={control} name="name" render={renderCreateUserField} />
      <StyledSubtitle content={t('admin.billing.modals.createUser.fields.lastname')} variant={3} />
      <Controller control={control} name="lastname" render={renderCreateUserField} />
      <StyledSubtitle content={t('admin.billing.modals.createUser.fields.profession')} variant={3} />
      <Controller control={control} name="profession" render={renderCreateUserField} />
      <StyledSubtitle content={t('admin.billing.modals.createUser.fields.phone')} variant={3} />
      <Controller control={control} name="phone" render={renderCreateUserField} />
      {!isEditingUser && (
        <>
          <StyledSubtitle content={t('admin.billing.modals.createUser.fields.email')} variant={3} />
          <Controller control={control} name="email" render={renderCreateUserField} />
        </>
      )}
    </Stack>
  );
};

export default UserModalFields;

interface IReservation {
  room: string;
  date: string;
  appointments: string[];
}

interface IReservationsState {
  reservationsPage: {
    date?: string;
    room?: string;
    time?: string;
    duration?: string;
    availability?: string[];
    reservations?: IReservation[];
    success?: boolean;
    error?: boolean;
    disabledDays?: string[];
  };
}

const initialReservationsState = {
  reservationsPage: {
    time: '',
    room: '',
    date: '',
    duration: '',
    availability: [],
    reservations: [],
    success: false,
    error: false,
    disabledDays: [],
  },
};

export type { IReservationsState, IReservation };
export { initialReservationsState };

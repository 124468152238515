import styled from 'styled-components';
import { Stack } from '@mui/material';

import Heading from '../../../../../components/ui/Heading';
import Paragraph from '../../../../../components/ui/Paragraph';

const StyledAccountBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;
`;

const StyledStack = styled(Stack)`
  margin-left: 1rem;
`;

const StyledHeading = styled(Heading)`
  text-align: left;
`;

const StyledParagraph = styled(Paragraph)`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
  height: 2rem;
  white-space: nowrap;
`;
export { StyledAccountBox, StyledStack, StyledHeading, StyledParagraph };

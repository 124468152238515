const adminRoomsActions = {
  setRoomData: 'SET_ROOM_DATA',
  clearRoomData: 'CLEAR_ROOM_DATA',
  setCalendarReservation: 'SET_CALENDAR_RESERVATION',
  clearCalendarReservation: 'CLEAR_CALENDAR_RESERVATION',
  setDisablingSuccess: 'SET_DISABLING_SUCCESS',
  setDisablingError: 'SET_DISABLING_ERROR',
  setDisablingDateFrom: 'SET_DISABLING_DATE_FROM',
  setDisablingDateTo: 'SET_DISABLING_DATE_TO',
};

type CalendarReservationType = {
  id: string;
  appointment: string;
  professional: string;
  date: string;
};

type DisableReservationType = {
  fromDate?: string;
  toDate?: string;
};

type AdminRoomsPayloadType = {
  roomData?: { id: number; name: string; description: string };
  calendarReservation?: CalendarReservationType;
  disableReservation?: DisableReservationType;
  errors?: string;
};

export type { AdminRoomsPayloadType, CalendarReservationType, DisableReservationType };
export { adminRoomsActions };

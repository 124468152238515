import styled from 'styled-components';
import { PickersDay } from '@mui/x-date-pickers';

type PickerType = { hasSelections: boolean };

const StyledPickersDay = styled(PickersDay)<PickerType>`
  opacity: ${({ disabled }) => (disabled ?? false ? '0.1' : '1')};
  background-color: ${({ hasSelections, theme }) => (hasSelections ? theme.colors.primary3 : 'initial')};
  position: relative;

  &::before {
    display: ${({ hasSelections }) => (hasSelections ? 'block' : 'none')};
    width: 3px;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.secondary1};
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 100%;
  }
`;
export { StyledPickersDay };

const generateLastPeriodBillingStackData = (lastPeriodBilling: { [key: string]: string }) =>
  Object.keys(lastPeriodBilling).map((key, index) => ({
    id: index,
    amount: lastPeriodBilling[key],
    title: key,
  }));

type GeneratedLastPeriodBillingData = {
  id: number;
  amount: string;
  title: string;
}[];

export type { GeneratedLastPeriodBillingData };
export { generateLastPeriodBillingStackData };

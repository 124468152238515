import { StyledPreviewContainer, StyledImage, StyledCloseButton, StyledImageWrapper } from './styles';

type ImagePreviewProps = {
  image: string;
  handleClose: () => void;
};

const ImagePreview = ({ image, handleClose }: ImagePreviewProps) => {
  return (
    <StyledPreviewContainer>
      <StyledImageWrapper>
        <StyledImage src={image} />
        <StyledCloseButton onClick={handleClose} />
      </StyledImageWrapper>
    </StyledPreviewContainer>
  );
};

export default ImagePreview;

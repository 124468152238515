import styled, { css } from 'styled-components';
import { Chip } from '@mui/material';
import type { DefaultTheme } from 'styled-components';

import type { AppointmentStatusType } from '../../../utils/api/reservations';

type StyledChipProps = {
  theme: DefaultTheme;
  status: AppointmentStatusType;
};

const StyledChip = styled(Chip)(
  ({ theme, status }: StyledChipProps) => css`
    width: 22%;
    min-width: 73px;
    margin: 6px 3px;
    height: 40px;
    font-size: 16px;
    line-height: 22px;
    font-family: 'Montserrat';
    font-weight: 600;
    cursor: pointer;
    color: black;
    background-color: ${status === 'margin' ? theme.colors.primary4 : undefined};
  `,
);

export { StyledChip };

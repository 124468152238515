import type { Translations } from '../..';

import auth from './authES.json';
import admin from './adminES.json';
import form from './formES.json';
import layout from './layoutES.json';
import rooms from './roomsES.json';
import reservations from './reservationsES.json';
import billing from './billingES.json';

const translation: Translations = { admin, auth, billing, form, layout, rooms, reservations };

export default translation;

import styled from 'styled-components';

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: calc(100% - 2rem);
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
`;

export { StyledPageContainer };

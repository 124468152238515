import { styled, Card, CardActions, Button } from '@mui/material';

import Heading from '../../Heading';
import Paragraph from '../../Paragraph';

const StyledCard = styled(Card)`
  width: 80vw;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 350px;
  }
  border-radius: 4px;
  box-shadow: 0px 3px 8px 2px rgb(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 3px 8px 2px rgb(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 8px 2px rgb(0, 0, 0, 0.1);
`;

const StyledCardAction = styled(CardActions)`
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const StyledHeading = styled(Heading)`
  color: black;
  text-align: left;
  margin-bottom: 12px;
`;
const StyledParagraph = styled(Paragraph)`
  color: black;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: 1rem;
  white-space: nowrap;
`;

const StyledButton = styled(Button)`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
`;

export { StyledCard, StyledCardAction, StyledHeading, StyledParagraph, StyledButton };

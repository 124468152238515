import { useTranslation } from 'react-i18next';
import Avatar from '@mui/material/Avatar';

import { useAuth } from '../../../../../context/Hooks';

import { StyledStack, StyledParagraph, StyledHeading, StyledAccountBox } from './styles';

const DrawerAccount = () => {
  const { t } = useTranslation('translation');
  const {
    state: { currentUser },
    isAdmin,
  } = useAuth();
  const user = {
    email: currentUser?.email ?? '',
    firstname: currentUser?.name ?? '',
    lastname: currentUser?.lastname ?? '',
  };
  return (
    <StyledAccountBox>
      {!isAdmin() && <Avatar>{`${String(user.firstname).charAt(0)}${String(user.lastname).charAt(0)}`}</Avatar>}
      <StyledStack>
        <StyledHeading content={t('layout.navbar.greeting', { name: String(user.firstname) })} variant={6} />
        <StyledParagraph content={user.email} variant={2} />
      </StyledStack>
    </StyledAccountBox>
  );
};

export default DrawerAccount;

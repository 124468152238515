import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { authStateContext } from '../Providers/Auth';
import { appStateContext } from '../Providers/App';
import { adminStateContext } from '../Providers/Admin';

import { useAdminUserHandlers } from './Admin/user';
import { useAdminRoomHandlers } from './Admin/rooms';
import { useAdminRoomCalendarHandlers } from './Admin/calendar';

const useAuth = () => {
  const context = useContext(authStateContext);
  if (!context) throw new Error('useAuth must be used in AuthProvider');
  return context;
};

const useAppContext = () => {
  const context = useContext(appStateContext);
  if (!context) throw new Error('useAppContext must be used in AppProvider');
  return context;
};
const useAdminContext = () => {
  const context = useContext(adminStateContext);
  if (!context) throw new Error('useAdminContext must be used in AdminProvider');
  return context;
};

const useAdminHandlers = () => {
  const adminUserHandlers = useAdminUserHandlers();
  const adminRoomHandlers = useAdminRoomHandlers();
  const adminRoomCalendarHandlers = useAdminRoomCalendarHandlers();
  const navigate = useNavigate();

  const handleHistoryLaunch = () => {
    navigate('history');
  };

  return {
    ...adminUserHandlers,
    ...adminRoomHandlers,
    ...adminRoomCalendarHandlers,
    handleHistoryLaunch,
  };
};

export { useAuth, useAppContext, useAdminContext, useAdminHandlers };

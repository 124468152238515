import { apiFetch } from '.';

/* eslint-disable @typescript-eslint/naming-convention, camelcase */

type AuthResponseData = {
  data: {
    access_token: string;
    refresh_token: string;
  };
  ok?: boolean;
  errors?: { login: string };
};

type CurrentUserResponse = {
  data: {
    active: boolean;
    email: string;
    lastname: string;
    name: string;
    phone: string;
    profession: string;
    type: string;
  };
};

const login = async (email: string, password: string) => {
  const body = { auth: { email, password } };
  return await apiFetch('POST', 'auth/login', { body });
};

const logout = async () => {
  return await apiFetch('DELETE', 'auth/logout');
};

const refresh = async (refreshToken: string) => {
  const body = { auth: { refresh_token: refreshToken } };
  return await apiFetch('PATCH', 'auth/refresh', { body });
};

const currentUser = async () => {
  const response = await apiFetch('GET', 'auth/me');
  return (await response.json()) as CurrentUserResponse;
};

export type { AuthResponseData };
export { login, logout, refresh, currentUser };

/* eslint-enable @typescript-eslint/naming-convention, camelcase */

import { useParams } from 'react-router-dom';

import AdminHeader from '../../../../../components/ui/AdminHeader';
import AnimatedPage from '../../../../../components/ui/AnimatedPage';
import { getRoomData } from '../../../../../utils/services/rooms';
import RoomFormModal from '../../../Modals/RoomFormModal';

import RoomEventsCalendar from './Calendar';
import RoomDetailBox from './DetailBox';
import { StyledRoomDetailSection } from './styles';
import AdminRoomDetailTable from './Table';

const AdminRoomDetailPage = () => {
  const { id } = useParams();
  const roomData = getRoomData(Number(id));
  return (
    <AnimatedPage>
      <AdminHeader title={roomData?.name ?? ''} />
      <StyledRoomDetailSection>{Boolean(roomData) && <RoomDetailBox room={roomData!} />}</StyledRoomDetailSection>
      <StyledRoomDetailSection>
        <RoomEventsCalendar />
      </StyledRoomDetailSection>
      <StyledRoomDetailSection>
        <AdminRoomDetailTable />
      </StyledRoomDetailSection>
      <RoomFormModal />
    </AnimatedPage>
  );
};

export default AdminRoomDetailPage;

import { InputAdornment, Stack } from '@mui/material';
import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';

import RenderField from '../../../../../components/form/Field';
import { useAdminContext } from '../../../../../context/Hooks';
import type { RegisterPaymentFieldType, RegisterPaymentFormProps } from '../Content';
import { StyledParagraph, StyledSubtitle } from '../styles';

const inputProps = {
  label: { shrink: true },

  adornment: {
    startAdornment: (
      <InputAdornment position="end">
        <AttachMoneySharpIcon />
      </InputAdornment>
    ),
  },
};

const RegisterPaymentFields = ({ control, errors }: RegisterPaymentFormProps) => {
  const { t } = useTranslation('translation');
  const {
    state: { paymentTarget },
  } = useAdminContext();

  const renderPaymentField = useCallback(
    ({ field: { onChange, onBlur, value, name, ref: reference } }: RegisterPaymentFieldType) => {
      return (
        <RenderField
          errors={errors[name]?.message}
          inputProps={inputProps}
          inputType="text"
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          reference={reference}
          value={value}
        />
      );
    },
    [errors],
  );

  return (
    <Stack alignItems="center" spacing={2}>
      <StyledSubtitle content={t('admin.billing.modals.registerPayment.fields.professional')} variant={3} />
      <StyledParagraph content={paymentTarget.name} variant={1} />
      <StyledSubtitle content={t('admin.billing.modals.registerPayment.fields.amount')} variant={3} />
      <Controller control={control} name="amount" render={renderPaymentField} />
    </Stack>
  );
};

export default RegisterPaymentFields;

import type { IAdminUsersState } from '../../Initials/admin/users';
import type { IAdminAction } from '../../Actions/admin';
import { adminUsersActions } from '../../Actions/admin/users';

const adminUsersReducer = (state: IAdminUsersState, action: IAdminAction): IAdminUsersState => {
  const { type, payload } = action;

  switch (type) {
    case adminUsersActions.setNewUserData:
      return { ...state, userData: payload?.userData };
    case adminUsersActions.setDeletableUser:
      return { ...state, deletableUser: payload?.deletableUser as { userId: string; fullname: string } };
    case adminUsersActions.setSuccess:
      return { ...state, success: true };
    case adminUsersActions.setEditingUser:
      return { ...state, isEditingUser: true };
    case adminUsersActions.setError:
      return { ...state, error: true, errors: payload?.errors };
    case adminUsersActions.clearSuccess:
      return { ...state, success: false };
    case adminUsersActions.clearError:
      return { ...state, success: false, error: false, errors: '' };
    case adminUsersActions.clearEditingUser:
      return { ...state, isEditingUser: false };
    case adminUsersActions.clearUserData:
      return {
        ...state,
        isEditingUser: false,
        deletableUser: { userId: '', fullname: '' },

        userData: {
          email: '',
          phone: '',
          name: '',
          lastname: '',
          profession: '',
        },
      };
    default:
      return state;
  }
};

export { adminUsersReducer };

const authActions = {
  loginPending: 'LOGIN_PENDING',
  loginFailed: 'LOGIN_FAILED',
  loginSuccessful: 'LOGIN_SUCCESSFUL',
  logoutPending: 'LOGOUT_PENDING',
  logoutSuccessful: 'LOGOUT_SUCCESSFUL',
  newPasswordSuccessful: 'NEW_PASSWORD_SUCCESSFUL',
  newPasswordFailed: 'NEW_PASSWORD_FAILED',
  setCurrentUser: 'SET_CURRENT_USER',
};

interface IAuthPayload {
  accessToken?: string;
  refreshToken?: string;
  error?: string;
  currentUser?: {
    active: boolean;
    type: string;
    email: string;
    lastname: string;
    name: string;
    phone: string;
    profession: string;
  };
}

interface IAuthAction {
  type: string;
  payload?: IAuthPayload;
}

export type { IAuthPayload, IAuthAction };
export { authActions };

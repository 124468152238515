import styled from 'styled-components';
import { Select, FormControl, InputLabel } from '@mui/material';

import Paragraph from '../../../../components/ui/Paragraph';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 1rem;
`;

const StyledInputLabel = styled(InputLabel)`
  background: white;
`;

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`;

export { StyledFormControl, StyledInputLabel, StyledSelect, StyledParagraph };

import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import AppointmentStack from '../../components/ui/Stack/Appointments';
import Heading from '../../components/ui/Heading';
import Paragraph from '../../components/ui/Paragraph';
import PageContainer from '../../components/ui/PageContainer';
import { appActions } from '../../context/Actions/app';
import { myReservationsActions } from '../../context/Actions/myReservations';
import { useAppContext } from '../../context/Hooks';
import { getMyReservations } from '../../utils/api/reservations';
import LoadingScreen from '../../components/layout/LoadingScreen';
import AnimatedPage from '../../components/ui/AnimatedPage';

import CancellationModal from './CancellationModal';

const MyReservationsPage = () => {
  const { t } = useTranslation('translation');
  const {
    dispatch,
    state: {
      myReservationsPage: { myReservations },
    },
  } = useAppContext();

  const hasReservations = useMemo(() => (myReservations && myReservations.length > 0) ?? false, [myReservations]);

  useEffect(() => {
    const fetchMyReservations = async () => {
      const response = await getMyReservations();
      const items = response.map((reservation) => ({
        ...reservation,
        startTime: moment(reservation.startTime).format('HH:mm'),
        endTime: moment(reservation.endTime).format('HH:mm'),

        handleClick: () => {
          dispatch({ type: myReservationsActions.setCancelable, payload: { cancelable: reservation } });
          dispatch({ type: appActions.launchModal });
        },
      }));
      dispatch({ type: myReservationsActions.setMyReservations, payload: { myReservations: items } });
    };
    void fetchMyReservations();
  }, [dispatch]);

  if (!myReservations) return <LoadingScreen />;

  return (
    <AnimatedPage>
      <PageContainer>
        <Heading content={t('reservations.myReservations.title')} variant={3} />
        {hasReservations ? (
          <AppointmentStack items={myReservations} />
        ) : (
          <Paragraph content={t('reservations.emptyReservations')} variant={2} />
        )}
        <CancellationModal />
      </PageContainer>
    </AnimatedPage>
  );
};

export default MyReservationsPage;

import { Paper, Tab, Tabs, TableHead, TableRow, TableCell, Box, FormControl } from '@mui/material';
import styled, { css } from 'styled-components';

import Paragraph from '../../../components/ui/Paragraph';
import BillingStack from '../../../components/ui/Stack/Billing';
import Subtitle from '../../../components/ui/Subtitle';

const StyledTabs = styled(Tabs)`
  margin: 0rem 0rem 2rem 3rem;
`;

const StyledTab = styled(Tab)`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
`;

const StyledTableContainer = styled(Paper)`
  border-radius: 10px;
  width: calc(100% - 6rem);
  margin: auto;
`;

const StyledTableHead = styled(TableHead)`
  background-color: ${({ theme }) => theme.colors.primary3};
`;

const StyledBorderlessTableRow = styled(TableRow)`
  border-bottom: unset;
`;

const StyledTableCell = styled(TableCell)`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 14px;
  line-height: 1rem;
  text-align: center;
  color: black;
`;

const StyledTableCellVertical = styled(StyledTableCell)`
  padding: 0;
`;

const collapsedContentStyles = css`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

const StyledCollapsedContent = styled(Box)`
  ${collapsedContentStyles};
  width: calc(100% - 2rem); ;
`;

const StyledCollapsedContentInner = styled(Box)`
  ${collapsedContentStyles};
  width: 75%;
  margin: 0 auto;
`;

const StyledBillingStack = styled(BillingStack)`
  margin: 1rem 0 !important;
`;

const StyledSubtitle = styled(Subtitle)`
  align-self: flex-start;
`;

type ButtonLabelProps = {
  primary?: boolean;
};
const StyledButtonLabel = styled.span<ButtonLabelProps>`
  color: ${({ theme, primary = false }) => (primary ? 'white' : theme.colors.primary1)};
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  width: 100%;
  cursor: pointer;
`;

const StyledRowButtonsContainer = styled.div`
  display: flex;
  align-self: flex-end;
  gap: 2rem;
  margin: 1rem 3rem;
`;

const StyledPageContent = styled.div`
  width: 100%;
  top: 164px;
  position: relative;
  padding-bottom: 2rem;
`;

const StyledUpperTableBox = styled.div`
  display: flex;
  width: calc(100% - 7rem);
  justify-content: space-between;
  align-items: flex-end;
  margin: 3rem auto 1rem auto;
`;

const StyledFormControl = styled(FormControl)`
  width: 380px;
`;

const StyledEmptyBilling = styled(Paragraph)`
  padding: 1rem 3rem;
`;

export {
  StyledTabs,
  StyledTab,
  StyledBorderlessTableRow,
  StyledTableCell,
  StyledTableCellVertical,
  StyledTableContainer,
  StyledTableHead,
  StyledCollapsedContent,
  StyledCollapsedContentInner,
  StyledBillingStack,
  StyledSubtitle,
  StyledButtonLabel,
  StyledRowButtonsContainer,
  StyledPageContent,
  StyledUpperTableBox,
  StyledFormControl,
  StyledEmptyBilling,
};

interface IAdminUsersState {
  userData?: {
    id?: number;
    email?: string;
    phone: string;
    name: string;
    lastname: string;
    profession: string;
  };
  deletableUser: { userId: string; fullname: string };
  isEditingUser: boolean;
  success: boolean;
  error: boolean;
  errors?: string;
}

const initialAdminUsersState = {
  success: false,
  error: false,
  errors: '',
  isEditingUser: false,
  deletableUser: { userId: '', fullname: '' },

  userData: {
    email: '',
    phone: '',
    name: '',
    lastname: '',
    profession: '',
  },
};

export type { IAdminUsersState };
export { initialAdminUsersState };

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { AlertColor } from '@mui/material';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import Modal from '../../../../components/ui/Modal';
import type { DisableReservationType } from '../../../../context/Actions/admin/rooms';
import { disableRoom } from '../../../../utils/api/admin-room-reservations';
import { useAppContext, useAdminContext } from '../../../../context/Hooks';
import { appActions } from '../../../../context/Actions/app';
import ResponseAlert from '../../../../components/ui/Modal/ResponseAlert';
import Paragraph from '../../../../components/ui/Paragraph';
import { adminActions } from '../../../../context/Actions/admin';

import DisableReservationForm from './Content';

const DisableReservationModal = () => {
  const { t } = useTranslation('translation');
  const {
    dispatch,
    modalStatus,
    state: { disableReservation },
  } = useAdminContext();
  const { fromDate, toDate } = disableReservation as DisableReservationType;
  const { dispatch: appDispatch } = useAppContext();
  const { id: roomId } = useParams();

  const isDisabled =
    fromDate === undefined || toDate === undefined || moment(toDate).diff(moment(fromDate), 'days') < 0;

  const modalContent = useCallback(() => {
    const alertColor = modalStatus.toLowerCase() as AlertColor;
    return modalStatus === 'Pending' ? (
      <DisableReservationForm />
    ) : (
      <ResponseAlert color={alertColor} severity={alertColor}>
        <Paragraph content={t(`admin.billing.modals.disableReservation.content${modalStatus}`)} variant={1} />
      </ResponseAlert>
    );
  }, [modalStatus, t]);

  const handleClose = useCallback(() => {
    if (modalStatus === 'Success') window.location.reload();
    dispatch({ type: adminActions.clearSuccess });
    dispatch({ type: adminActions.clearError });
    appDispatch({ type: appActions.setModalName, payload: { modalName: '' } });
  }, [appDispatch, dispatch, modalStatus]);

  const handleModalAction = useCallback(async () => {
    const fetchDisableReservation = async () => {
      if (roomId === undefined) window.location.replace('/');
      else {
        const response = await disableRoom({ roomId, fromDate: fromDate!, toDate: toDate! });
        if (response.ok) {
          dispatch({ type: adminActions.setSuccess });
        } else {
          dispatch({ type: adminActions.setError });
        }
      }
    };
    if (modalStatus === 'Pending') {
      await fetchDisableReservation();
    } else {
      handleClose();
    }
  }, [modalStatus, roomId, fromDate, toDate, dispatch, handleClose]);

  return (
    <Modal
      buttonAction={handleModalAction}
      buttonLabel={t(`admin.billing.modals.disableReservation.buttonLabel${modalStatus}`)}
      content={modalContent}
      handleClose={handleClose}
      isButtonDisabled={isDisabled}
      modalName="disableReservation"
      shouldClose={modalStatus !== 'Pending'}
      title={t('admin.billing.modals.disableReservation.title')}
    />
  );
};

export default DisableReservationModal;

import { apiFetch } from '.';

type BillingStackItemType = {
  id: number;
  amount: string;
  title: string;
  date?: string;
  detail?: string;
};

type AvailableAppointmentsResponseData = {
  data: BillingStackItemType[];
};

type PaginatedResponse = {
  pagy: {
    count: number;
    items: number;
    page: number;
    pages: number;
  };
};

type DebtResponse = {
  data: { amount: string };
};

type BillingHistoryResponse = AvailableAppointmentsResponseData & PaginatedResponse;

const getCurrentBillingHighlights = async (userId: number) => {
  const response = await apiFetch('GET', `api/v1/professionals/${userId}/resume_current_billing_period`);
  const result = (await response.json()) as AvailableAppointmentsResponseData;
  return result.data;
};

const getCurrentPeriodBilling = async (userId: number, date: string) => {
  const response = await apiFetch('GET', `/api/v1/professionals/${userId}/detail_of_month?date=${date}`);
  const result = (await response.json()) as AvailableAppointmentsResponseData;
  return result.data;
};

const getBillingHistory = async (userId: number, page: number, date?: string) => {
  const response = await apiFetch(
    'GET',
    `/api/v1/professionals/${userId}/detail_of_month?page=${page}${date === undefined ? '' : '&date='}${date ?? ''}`,
  );
  const result = (await response.json()) as BillingHistoryResponse;
  return { data: result.data, page: result.pagy.page, totalPages: result.pagy.pages };
};

const getRoomBillingHistory = async (roomId: number, page: number, date?: string) => {
  const response = await apiFetch(
    'GET',
    `/api/v1/rooms/${roomId}/detail_of_month?page=${page}${date === undefined ? '' : '&date='}${date ?? ''}`,
  );
  const result = (await response.json()) as BillingHistoryResponse;
  return { data: result.data, page: result.pagy.page, totalPages: result.pagy.pages };
};

const getCurrentDebt = async (userId: number) => {
  const response = await apiFetch('GET', `/api/v1/professionals/${userId}/professional_debt`);
  const result = (await response.json()) as DebtResponse;
  return result.data.amount;
};

export type { BillingStackItemType };
export {
  getCurrentBillingHighlights,
  getCurrentPeriodBilling,
  getBillingHistory,
  getRoomBillingHistory,
  getCurrentDebt,
};

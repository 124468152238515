import { apiFetch } from '.';

/* eslint-disable @typescript-eslint/naming-convention */

type PaymentData = {
  userId: number;
  amount: string;
};

type PaymentResponse = {
  data?: {
    id: number;
    profile_id: number;
    amount: number;
    created_at: string;
    updated_at: string;
  };
  errors?: {
    amount?: string[];
  };
};

const registerPayment = async (data: PaymentData) => {
  const response = await apiFetch('POST', `api/v1/professionals/${data.userId}/create_payment/`, {
    body: { amount: data.amount },
  });
  return (await response.json()) as PaymentResponse;
};

export type { PaymentResponse, PaymentData };
export { registerPayment };

/* eslint-enable @typescript-eslint/naming-convention */

import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { SubmitHandler } from 'react-hook-form';

import Modal from '../../../../components/ui/Modal';
import { useAdminContext } from '../../../../context/Hooks';
import { createUser, editUser } from '../../../../utils/api/user';
import { editUserFormSchema, createUserFormSchema } from '../../../../utils/schemas/users';
import { adminUsersActions } from '../../../../context/Actions/admin/users';
import { appActions } from '../../../../context/Actions/app';
import type { UserFormType, EditUserFormSchemaType, CreateUserFormSchemaType } from '../../../../utils/schemas/users';

import UserForm from './Content';

const UserFormModal = () => {
  const { t } = useTranslation('translation');
  const {
    state: { userData, isEditingUser },
    dispatch,
    mode,
    modalStatus,
  } = useAdminContext();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<UserFormType>({
    resolver: zodResolver(isEditingUser ? editUserFormSchema : createUserFormSchema),
  });

  const modalContent = useCallback(
    () => <UserForm control={control} errors={errors} values={userData} />,
    [errors, control, userData],
  );

  const userRequest = useCallback(
    async (user: UserFormType) =>
      isEditingUser
        ? await editUser({ ...(user as EditUserFormSchemaType), id: userData?.id })
        : await createUser({ ...(user as CreateUserFormSchemaType) }),
    [isEditingUser, userData],
  );

  const handleCloseModal = useCallback(() => {
    dispatch({ type: adminUsersActions.clearUserData });
    dispatch({ type: adminUsersActions.clearError });
  }, [dispatch]);

  const buttonAction = useCallback(async () => {
    const onSubmit: SubmitHandler<UserFormType> = async (user: UserFormType) => {
      try {
        const response = await userRequest(user);
        if (response.ok) {
          dispatch({ type: adminUsersActions.setSuccess });
        } else {
          dispatch({
            type: adminUsersActions.setError,
            payload: { errors: '' },
          });
        }
      } catch (responseError) {
        console.error(responseError);
      }
    };
    if (modalStatus === 'Pending') {
      await handleSubmit(onSubmit)();
    } else {
      handleCloseModal();
      dispatch({ type: appActions.closeModal });
      if (modalStatus === 'Success') window.location.reload();
    }
  }, [modalStatus, userRequest, dispatch, handleSubmit, handleCloseModal]);

  useEffect(() => {
    if (userData) reset(userData);
  }, [reset, userData]);

  return (
    <Modal
      buttonAction={buttonAction}
      buttonLabel={t(`admin.billing.modals.${mode}User.buttonLabel${modalStatus}`)}
      content={modalContent}
      handleClose={handleCloseModal}
      shouldClose={modalStatus !== 'Pending'}
      title={t(`admin.billing.modals.${mode}User.title`)}
    />
  );
};

export default UserFormModal;

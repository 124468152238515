import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Logo from '../../../assets/images/logo.png';

import { StyledLoadingScreenContainer, StyledLogo, StyledSubtitle } from './styles';

const LoadingScreen = () => {
  const { t } = useTranslation('translation');
  return (
    <StyledLoadingScreenContainer>
      <StyledLogo alt="Logo" src={Logo} />
      <CircularProgress />
      <StyledSubtitle content={t('layout.loading')} variant={3} />
    </StyledLoadingScreenContainer>
  );
};

export default LoadingScreen;

import styled from 'styled-components';
import { Select, FormControl, InputLabel } from '@mui/material';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 1rem;
`;

const StyledInputLabel = styled(InputLabel)`
  background: white;
`;

export { StyledFormControl, StyledInputLabel, StyledSelect };

import { Button, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import BillingTotalAmount from '../../../../../components/ui/BillingTotalAmount';
import { getUserDetailData } from '../../../../../utils/services/detail';
import type { UserDetailType } from '../../../../../utils/services/detail';
import LoadingScreen from '../../../../../components/layout/LoadingScreen';
import { getCurrentPeriodBilling } from '../../../../../utils/api/billing';
import type { BillingStackItemType } from '../../../../../utils/api/billing';
import Heading from '../../../../../components/ui/Heading';
import AdminHeader from '../../../../../components/ui/AdminHeader';
import AnimatedPage from '../../../../../components/ui/AnimatedPage';
import { useAdminHandlers } from '../../../../../context/Hooks';
import RegisterPaymentModal from '../../../Modals/RegisterPaymentModal';
import {
  StyledBillingStack,
  StyledBorderlessTableRow,
  StyledButtonLabel,
  StyledCollapsedContentInner,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledUpperTableBox,
  StyledPageContent,
  StyledEmptyBilling,
} from '../../styles';
import { dateFormat } from '../../../../../utils/schemas/datetime';

const AdminUserDetailPage = () => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<UserDetailType>();
  const [currentPeriodBillingData, setCurrentPeriodBillingData] = useState<BillingStackItemType[]>();
  const { handleRegisterPayment, handleHistoryLaunch } = useAdminHandlers();

  useEffect(() => {
    const fetchBillingData = async (userId: number) => {
      const response = await getCurrentPeriodBilling(userId, moment().format(dateFormat));
      setCurrentPeriodBillingData(response);
    };
    const data = getUserDetailData();
    if (data) {
      setUserInfo(data);
      void fetchBillingData(data.userId);
    } else navigate('/admin/billing');
  }, [navigate]);

  if (!userInfo || !currentPeriodBillingData) return <LoadingScreen />;

  return (
    <AnimatedPage>
      <AdminHeader
        initials={`${userInfo.name.charAt(0)}${userInfo.lastname.charAt(0)}`}
        isProfile
        subtitle={`${userInfo.profession} | ${userInfo.phone}`}
        title={`${userInfo.name} ${userInfo.lastname}`}
      />
      <StyledPageContent>
        <StyledUpperTableBox>
          <Heading content={t('billing.lastPeriod')} variant={3} />
          <Button
            onClick={handleRegisterPayment({ id: userInfo.userId, name: `${userInfo.name} ${userInfo.lastname}` })}
            variant="contained"
          >
            <StyledButtonLabel primary>{t('admin.registerPayment')}</StyledButtonLabel>
          </Button>
        </StyledUpperTableBox>
        <StyledCollapsedContentInner>
          <StyledBillingStack items={userInfo.lastPeriodBilling} />
          <BillingTotalAmount amount={userInfo.amount} />
        </StyledCollapsedContentInner>
        <StyledUpperTableBox>
          <Heading content={t('billing.detail')} variant={3} />
          <Button onClick={handleHistoryLaunch} variant="outlined">
            <StyledButtonLabel>{t('admin.viewHistory')}</StyledButtonLabel>
          </Button>
        </StyledUpperTableBox>
        <TableContainer component={StyledTableContainer}>
          <Table>
            <StyledTableHead>
              <TableRow>
                <StyledTableCell align="center">{t('admin.billing.rows.date')}</StyledTableCell>
                <StyledTableCell align="center">{t('admin.billing.rows.action')}</StyledTableCell>
                <StyledTableCell align="center">{t('admin.billing.rows.detail')}</StyledTableCell>
                <StyledTableCell align="center">{t('admin.billing.rows.amount')}</StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </StyledTableHead>
            {currentPeriodBillingData.length > 0 ? (
              <TableBody>
                {currentPeriodBillingData.map((row) => (
                  <StyledBorderlessTableRow key={row.id}>
                    <StyledTableCell align="center">{row.date}</StyledTableCell>
                    <StyledTableCell align="center">{t(`billing.billingStack.${row.title}`)}</StyledTableCell>
                    <StyledTableCell align="left">{row.detail!}</StyledTableCell>
                    <StyledTableCell align="right">{row.amount}</StyledTableCell>
                  </StyledBorderlessTableRow>
                ))}
              </TableBody>
            ) : (
              <StyledEmptyBilling content={t('billing.billingStack.emptyBilling')} variant={1} />
            )}
          </Table>
        </TableContainer>
      </StyledPageContent>
      <RegisterPaymentModal />
    </AnimatedPage>
  );
};

export default AdminUserDetailPage;

import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import RenderField from '../../../../../components/form/Field';
import type { EditRoomFieldType, RoomFormProps } from '../Content';
import { StyledSubtitle } from '../styles';

const inputProps = {
  label: { shrink: true },
};

const EditRoomFields = ({ control, errors }: RoomFormProps) => {
  const { t } = useTranslation('translation');

  const renderRoomField = useCallback(
    ({ field: { onChange, onBlur, value, name, ref: reference } }: EditRoomFieldType) => {
      return (
        <RenderField
          errors={errors[name]?.message}
          inputProps={inputProps}
          inputType="text"
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          reference={reference}
          value={value}
        />
      );
    },
    [errors],
  );

  return (
    <Stack alignItems="center" spacing={2}>
      <StyledSubtitle content={t('admin.billing.modals.editRoom.fields.name')} variant={3} />
      <Controller control={control} name="name" render={renderRoomField} />
      <StyledSubtitle content={t('admin.billing.modals.editRoom.fields.description')} variant={3} />
      <Controller control={control} name="description" render={renderRoomField} />
    </Stack>
  );
};

export default EditRoomFields;

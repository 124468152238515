import styled from 'styled-components';
import { Select, FormControl, InputLabel } from '@mui/material';

import Subtitle from '../../../../components/ui/Subtitle';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 1rem;
`;

const StyledInputLabel = styled(InputLabel)`
  background: white;
`;

const StyledSubtitle = styled(Subtitle)`
  align-self: flex-start;
  padding-left: 1rem;
  margin: 0;
`;

export { StyledFormControl, StyledSubtitle, StyledInputLabel, StyledSelect };

import { useTranslation } from 'react-i18next';
import type { FieldErrorsImpl, Control, ControllerRenderProps } from 'react-hook-form';

import { useAdminContext } from '../../../../../context/Hooks';
import Paragraph from '../../../../../components/ui/Paragraph';
import ResponseAlert from '../../../../../components/ui/Modal/ResponseAlert';
import type { UserFormType, CreateUserFormSchemaType } from '../../../../../utils/schemas/users';
import UserModalFields from '../Fields';

type CreateUserFieldType = {
  field:
    | ControllerRenderProps<UserFormType, 'email'>
    | ControllerRenderProps<UserFormType, 'lastname'>
    | ControllerRenderProps<UserFormType, 'name'>
    | ControllerRenderProps<UserFormType, 'phone'>
    | ControllerRenderProps<UserFormType, 'profession'>;
};

type CreateUserContentType = 'error' | 'success';
type ModalContentResponseProps = {
  content?: string;
  modalStatus: CreateUserContentType;
};

const ModalContentResponse = ({ modalStatus, content = '' }: ModalContentResponseProps): JSX.Element => {
  const { t } = useTranslation('translation');
  const { mode } = useAdminContext();
  return (
    <>
      <Paragraph content={t(`admin.billing.modals.${mode}User.${modalStatus}`)} variant={1} />
      {content.length > 0 && <Paragraph content={content} variant={1} />}
    </>
  );
};

const CreateUserContentSuccess = () => (
  <ResponseAlert color="success" severity="success">
    <ModalContentResponse modalStatus="success" />
  </ResponseAlert>
);
const CreateUserContentError = ({ error }: { error: string }) => (
  <ResponseAlert color="error" severity="error">
    <ModalContentResponse content={error} modalStatus="error" />
  </ResponseAlert>
);

type CreateUserFormProps = {
  control: Control<UserFormType>;
  errors: FieldErrorsImpl<CreateUserFormSchemaType>;
  values?: {
    name: string;
    lastname: string;
    phone: string;
    profession: string;
    email?: string;
  };
};

const UserFormContent = ({ control, errors, values }: CreateUserFormProps) => {
  const { state } = useAdminContext();

  if (state.success) return <CreateUserContentSuccess />;
  if (state.error) return <CreateUserContentError error={state.errors!} />;

  return <UserModalFields control={control} errors={errors} values={values} />;
};

export type { CreateUserContentType, CreateUserFormProps, CreateUserFieldType };
export default UserFormContent;

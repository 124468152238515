import { AuthProvider } from './context/Providers/Auth';
import { AppProvider } from './context/Providers/App';
import { AdminProvider } from './context/Providers/Admin';
import Theme from './utils/theme';
import Pages from './pages';
import './utils/i18n';

const App = (): JSX.Element => (
  <Theme>
    <AuthProvider>
      <AppProvider>
        <AdminProvider>
          <Pages />
        </AdminProvider>
      </AppProvider>
    </AuthProvider>
  </Theme>
);

export default App;

import { useCallback, useState } from 'react';
import type { ControllerRenderProps, SubmitHandler } from 'react-hook-form';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Container, InputAdornment, IconButton, Link } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { changePassword } from '../../../utils/api/user';
import { resetPaswordFormSchema } from '../../../utils/schemas/auth';
import type { ResetPasswordFormSchemaType } from '../../../utils/schemas/auth';
import Subtitle from '../../../components/ui/Subtitle';
import AnimatedPage from '../../../components/ui/AnimatedPage';
import AdminHeader from '../../../components/ui/AdminHeader';
import RenderField from '../../../components/form/Field';
import { removeAuthData } from '../../../utils/services/auth';

import { StyledButtonLabel, StyledContainer, StyledButtonWrapper } from './styles';

type ResetPasswordFieldType = {
  field:
    | ControllerRenderProps<ResetPasswordFormSchemaType, 'password'>
    | ControllerRenderProps<ResetPasswordFormSchemaType, 'passwordConfirmation'>;
};

/* eslint-disable react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop, @typescript-eslint/no-misused-promises  */
const ResetPasswordPage = () => {
  const { t } = useTranslation('translation');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>();

  const togglePassword = useCallback(() => {
    setShowPassword((previousState) => !previousState);
  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ResetPasswordFormSchemaType>({
    resolver: zodResolver(resetPaswordFormSchema),
  });

  const inputProps = {
    label: { shrink: true },

    adornment: {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={togglePassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
        </InputAdornment>
      ),
    },
  };

  const onSubmit: SubmitHandler<ResetPasswordFormSchemaType> = async (user: ResetPasswordFormSchemaType) => {
    try {
      const response = await changePassword({ ...user });
      if (response.ok) {
        setSuccess(true);
        const logoutTime = 3500;
        setTimeout(() => {
          removeAuthData();
          window.location.replace('/login');
        }, logoutTime);
      } else {
        setSuccess(false);
      }
      // Parsear respuestas...
    } catch (error) {
      console.error(error);
    }
  };

  const renderResetPasswordField = ({
    field: { onChange, onBlur, value, name, ref: reference },
  }: ResetPasswordFieldType) => (
    <RenderField
      errors={errors[name]?.message}
      inputProps={inputProps}
      inputType={showPassword ? 'text' : 'password'}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      reference={reference}
      value={value}
    />
  );

  return (
    <AnimatedPage>
      <AdminHeader title={t('admin.headers.password')} />
      <StyledContainer>
        <Container maxWidth="sm">
          <Subtitle content={t('auth.fields.newPassword')} variant={3} />
          <Controller control={control} defaultValue="" name="password" render={renderResetPasswordField} />
          <Subtitle content={t('auth.fields.newPasswordConfirmation')} variant={3} />
          <Controller control={control} defaultValue="" name="passwordConfirmation" render={renderResetPasswordField} />
          <StyledButtonWrapper>
            <Button fullWidth onClick={handleSubmit(onSubmit)} variant="contained">
              <StyledButtonLabel>{t('auth.reset.submit')}</StyledButtonLabel>
            </Button>
          </StyledButtonWrapper>
          {success === false && (
            <Alert severity="error" variant="outlined">
              {t('auth.newPasswordFailed')}
              <Link href="/forgotPassword" variant="body2">
                {t('auth.newPasswordFailedRetry')}
              </Link>
            </Alert>
          )}
          {Boolean(success) && (
            <Alert severity="success" variant="outlined">
              {t('auth.newPasswordChanged')}
            </Alert>
          )}
        </Container>
      </StyledContainer>
    </AnimatedPage>
  );
};

export default ResetPasswordPage;

/* eslint-enable react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop, @typescript-eslint/no-misused-promises  */

import { Button, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import LoadingScreen from '../../../../../../components/layout/LoadingScreen';
import { getRoomBillingHistory } from '../../../../../../utils/api/billing';
import type { BillingStackItemType } from '../../../../../../utils/api/billing';
import Heading from '../../../../../../components/ui/Heading';
import { useAdminHandlers } from '../../../../../../context/Hooks';
import {
  StyledBorderlessTableRow,
  StyledButtonLabel,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledUpperTableBox,
  StyledEmptyBilling,
} from '../../../styles';
import { dateFormat } from '../../../../../../utils/schemas/datetime';

const AdminRoomDetailTable = () => {
  const { t } = useTranslation('translation');
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentPeriodBillingData, setCurrentPeriodBillingData] = useState<BillingStackItemType[]>();
  const { handleHistoryLaunch } = useAdminHandlers();

  useEffect(() => {
    const fetchBillingData = async (roomId: number) => {
      const response = await getRoomBillingHistory(roomId, 1, moment().format(dateFormat));
      setCurrentPeriodBillingData(response.data);
    };
    void fetchBillingData(Number(id));
  }, [navigate, id]);

  if (!currentPeriodBillingData) return <LoadingScreen />;

  return (
    <>
      <StyledUpperTableBox>
        <Heading content={t('billing.detail')} variant={3} />
        <Button onClick={handleHistoryLaunch} variant="outlined">
          <StyledButtonLabel>{t('admin.viewHistory')}</StyledButtonLabel>
        </Button>
      </StyledUpperTableBox>
      <TableContainer component={StyledTableContainer}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <StyledTableCell align="center">{t('admin.billing.rows.date')}</StyledTableCell>
              <StyledTableCell align="center">{t('admin.billing.rows.action')}</StyledTableCell>
              <StyledTableCell align="center">{t('admin.billing.rows.detail')}</StyledTableCell>
              <StyledTableCell align="center">{t('admin.billing.rows.value')}</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </StyledTableHead>
          {currentPeriodBillingData.length > 0 ? (
            <TableBody>
              {currentPeriodBillingData.map((row) => (
                <StyledBorderlessTableRow key={row.id}>
                  <StyledTableCell align="center">{row.date}</StyledTableCell>
                  <StyledTableCell align="center">{t(`billing.billingStack.${row.title}`)}</StyledTableCell>
                  <StyledTableCell align="left">{row.detail ?? 'billing detail sample content'}</StyledTableCell>
                  <StyledTableCell align="right">{row.amount}</StyledTableCell>
                </StyledBorderlessTableRow>
              ))}
            </TableBody>
          ) : (
            <StyledEmptyBilling content={t('billing.billingStack.emptyBilling')} variant={1} />
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default AdminRoomDetailTable;

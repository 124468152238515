import { useTranslation } from 'react-i18next';

import type { BillingStackItemType } from '../../../../utils/api/billing';
import {
  StyledStack,
  StyledStackBox,
  StyledStackItem,
  StyledSubtitle,
  StyledParagraph,
  StyledEmptyBilling,
} from '../styles';

type BillingStackProps = {
  className?: string;
  items: BillingStackItemType[];
  hasSubtitle?: boolean;
};

const BillingStack = ({ className, items, hasSubtitle = false }: BillingStackProps) => {
  const { t } = useTranslation('translation');
  return (
    <StyledStackBox className={className}>
      {items.map((item) => (
        <StyledStackItem hasSubtitle={hasSubtitle} key={item.id}>
          <StyledStack spacing={0}>
            <StyledSubtitle content={t(`billing.billingStack.${item.title}`)} variant={3} />
            {Boolean(item.date ?? false) && <StyledParagraph content={item.date ?? ''} variant={2} />}
          </StyledStack>
          <StyledParagraph content={item.amount} variant={1} />
        </StyledStackItem>
      ))}
      {items.length === 0 && <StyledEmptyBilling content={t('billing.billingStack.emptyBilling')} variant={2} />}
    </StyledStackBox>
  );
};

export type { BillingStackItemType };

export default BillingStack;

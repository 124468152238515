import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../components/ui/Modal';
import ResponseAlert from '../../../../components/ui/Modal/ResponseAlert';
import type { CalendarReservationType } from '../../../../context/Actions/admin/rooms';
import { adminUsersActions } from '../../../../context/Actions/admin/users';
import { appActions } from '../../../../context/Actions/app';
import { useAdminContext, useAppContext } from '../../../../context/Hooks';
import { cancelReservation } from '../../../../utils/api/reservations';

import { StyledParagraph, StyledInfoLabel, StyledInfoValue } from './styles';

type ContentKeyType = 'error' | 'pending' | 'success';

const ReservationDetailModal = () => {
  const { t } = useTranslation('translation');
  const { dispatch: appDispatch } = useAppContext();
  const {
    state: { calendarReservation },
    dispatch,
    modalStatus,
  } = useAdminContext();

  const { id: calendarReservationId, appointment, professional, date } = calendarReservation as CalendarReservationType;

  const handleCloseModal = useCallback(() => {
    dispatch({ type: adminUsersActions.clearError });
    dispatch({ type: adminUsersActions.clearSuccess });
    appDispatch({ type: appActions.setModalName, payload: { modalName: '' } });
    if (modalStatus === 'Success') window.location.reload();
  }, [appDispatch, dispatch, modalStatus]);

  const handleButtonAction = useCallback(async () => {
    const onSubmit = async (id: string) => {
      try {
        const response = await cancelReservation(Number(id));
        if (response.ok) {
          dispatch({ type: adminUsersActions.setSuccess });
        } else {
          dispatch({ type: adminUsersActions.setError, payload: { errors: '' } });
        }
      } catch (responseError) {
        console.error(responseError);
      }
    };
    if (modalStatus === 'Pending') {
      await onSubmit(calendarReservationId);
    } else handleCloseModal();
  }, [modalStatus, dispatch, calendarReservationId, handleCloseModal]);

  const ReservationDetailModalContent = useCallback(() => {
    const contents = {
      success: (
        <ResponseAlert color="success" severity="success">
          <StyledParagraph content={t('admin.billing.modals.reservationDetail.success')} variant={1} />
        </ResponseAlert>
      ),

      error: (
        <ResponseAlert color="error" severity="error">
          <StyledParagraph content={t('admin.billing.modals.reservationDetail.error')} variant={1} />
        </ResponseAlert>
      ),

      pending: (
        <>
          <StyledInfoLabel content={t('admin.billing.modals.reservationDetail.labels.professional')} variant={3} />
          <StyledInfoValue content={professional} variant={1} />
          <StyledInfoLabel content={t('admin.billing.modals.reservationDetail.labels.detail')} variant={3} />
          <StyledInfoValue content={date} variant={1} />
          <StyledInfoValue content={appointment} variant={2} />
        </>
      ),
    };
    const contentKey = modalStatus.toLowerCase() as ContentKeyType;
    return contents[contentKey];
  }, [t, modalStatus, professional, appointment, date]);

  return (
    <Modal
      buttonAction={handleButtonAction}
      buttonLabel={t(`admin.billing.modals.reservationDetail.buttonLabel${modalStatus}`)}
      content={ReservationDetailModalContent}
      handleClose={handleCloseModal}
      modalName="reservationDetail"
      shouldClose={modalStatus !== 'Pending'}
      title={t('admin.billing.modals.reservationDetail.title')}
    />
  );
};

export default ReservationDetailModal;

import { useNavigate } from 'react-router-dom';

import { appActions } from '../../Actions/app';
import { adminActions } from '../../Actions/admin';
import type { RowContentType } from '../../../utils/api/admin-billing';
import { useAdminContext, useAppContext } from '..';
import { setUserDetailData } from '../../../utils/services/detail';

const useAdminUserHandlers = () => {
  const { dispatch: appDispatch } = useAppContext();
  const { dispatch: adminDispatch } = useAdminContext();
  const navigate = useNavigate();

  const handleCreateUser = () => {
    appDispatch({ type: appActions.launchModal });
  };

  const handleEditUser = (row: RowContentType) => () => {
    adminDispatch({
      type: adminActions.setNewUserData,

      payload: {
        userData: {
          id: row.userId,
          name: row.name,
          lastname: row.lastname,
          profession: row.profession,
          phone: row.phone,
          email: '',
        },
      },
    });
    adminDispatch({ type: adminActions.setEditingUser });
    appDispatch({ type: appActions.launchModal });
  };

  const handleDeleteUser =
    ({ userId, fullname }: { userId: string; fullname: string }) =>
    () => {
      adminDispatch({
        type: adminActions.setDeletableUser,
        payload: { deletableUser: { userId, fullname } },
      });
      appDispatch({ type: appActions.setModalName, payload: { modalName: 'deleteUser' } });
      appDispatch({ type: appActions.launchModal });
    };

  // Register Payment modal launching handler

  const handleRegisterPayment =
    ({ id, name }: { id: number; name: string }) =>
    () => {
      appDispatch({ type: appActions.launchModal });
      appDispatch({ type: appActions.setModalName, payload: { modalName: 'registerPayment' } });
      adminDispatch({
        type: adminActions.setPaymentTarget,
        payload: { paymentTarget: { id, name } },
      });
    };

  // User Detail Page launching handler

  const handleOpenUserDetail = (row: RowContentType) => () => {
    setUserDetailData(row);
    navigate(`/admin/billing/user/${row.userId}`);
  };

  return { handleCreateUser, handleEditUser, handleDeleteUser, handleRegisterPayment, handleOpenUserDetail };
};

export { useAdminUserHandlers };

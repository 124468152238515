import { useLocation, Navigate, Outlet } from 'react-router-dom';

import LoadingScreen from '../LoadingScreen';
import { useAuth } from '../../../context/Hooks';

const RequireAdmin = () => {
  const {
    state: { currentUser },
    isAdmin,
  } = useAuth();
  const loc = useLocation();
  const navState = { from: loc };

  if (!currentUser) return <LoadingScreen />;

  if (!isAdmin()) {
    return <Navigate replace state={navState} to="/" />;
  }
  return <Outlet />;
};

export default RequireAdmin;

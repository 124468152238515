import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TableRow, IconButton, Collapse, Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';

import BillingTotalAmount from '../../../../../components/ui/BillingTotalAmount';
import {
  StyledBorderlessTableRow,
  StyledTableCell,
  StyledTableCellVertical,
  StyledCollapsedContent,
  StyledBillingStack,
  StyledCollapsedContentInner,
  StyledSubtitle,
  StyledButtonLabel,
  StyledRowButtonsContainer,
} from '../../styles';
import { generateLastPeriodBillingStackData } from '../../../../../utils/helpers/billing';
import type { RoomRowType } from '../../../../../utils/api/admin-billing';
import { useAdminHandlers } from '../../../../../context/Hooks';

const Row = ({ row }: RoomRowType) => {
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation('translation');
  const { handleEditRoom } = useAdminHandlers();
  const billingStackItems = generateLastPeriodBillingStackData(row.lastPeriodBilling);

  const handleRowOpening = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  const handleOpenDetail = useCallback(() => {
    navigate(`/admin/rooms/${row.roomId}`);
  }, [navigate, row]);

  return (
    <>
      <StyledBorderlessTableRow>
        <StyledTableCell align="center">{row.name}</StyledTableCell>
        <StyledTableCell align="center">{row.amount}</StyledTableCell>
        <StyledTableCell>
          <IconButton onClick={handleEditRoom({ row })} size="small">
            <ModeEditOutlinedIcon />
          </IconButton>
          <IconButton onClick={handleRowOpening} size="small">
            {opened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
      </StyledBorderlessTableRow>
      <TableRow>
        <StyledTableCellVertical colSpan={6}>
          <Collapse in={opened} timeout="auto" unmountOnExit>
            <StyledCollapsedContent>
              <StyledCollapsedContentInner>
                <StyledSubtitle content={t('billing.lastPeriod')} variant={1} />
                <StyledBillingStack items={billingStackItems} />
                <BillingTotalAmount amount={row.amount} />
              </StyledCollapsedContentInner>
              <StyledRowButtonsContainer>
                <Button id={String(row.roomId)} onClick={handleOpenDetail} variant="text">
                  <StyledButtonLabel>{t('billing.openDetail')}</StyledButtonLabel>
                </Button>
              </StyledRowButtonsContainer>
            </StyledCollapsedContent>
          </Collapse>
        </StyledTableCellVertical>
      </TableRow>
    </>
  );
};

export default Row;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow, TableBody, TableContainer, Table } from '@mui/material';

import { StyledTableContainer, StyledTableHead, StyledTableCell } from '../../styles';
import { getBillingByRooms } from '../../../../../utils/api/admin-billing';
import type { RoomRowContentType } from '../../../../../utils/api/admin-billing';
import RoomFormModal from '../../../Modals/RoomFormModal';
import Row from '../Row';

const RoomBillingTable = () => {
  const { t } = useTranslation('translation');
  const [rows, setRows] = useState<RoomRowContentType[]>();

  useEffect(() => {
    const fetchRows = async () => {
      const response = await getBillingByRooms();
      setRows(response);
    };
    void fetchRows();
  }, []);

  return (
    <>
      <TableContainer component={StyledTableContainer}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <StyledTableCell align="center">{t('admin.billing.rows.room')}</StyledTableCell>
              <StyledTableCell align="center">{t('admin.billing.rows.amount')}</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {rows?.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <RoomFormModal />
    </>
  );
};

export default RoomBillingTable;

import { useEffect, useState, useCallback } from 'react';

import { useAppContext } from '../../../../context/Hooks';
import { appActions } from '../../../../context/Actions/app';
import { getAppointments } from '../../../../utils/api/reservations';
import { getAllSelectedAppointments, isSelected } from '../../../../utils/helpers/reservations';
import type { AppointmentType, AppointmentStatusType } from '../../../../utils/api/reservations';
import { reservationsActions } from '../../../../context/Actions/reservations';
import AppointmentChip from '../../../../components/ui/Chip';

import { StyledChipsContainer } from './styles';

const AppointmentChips = () => {
  const [appointments, setAppointments] = useState<AppointmentType[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const {
    state: {
      reservationsPage: { room, date, reservations },
    },
    dispatch,
  } = useAppContext();

  const getVariant = useCallback(
    (value: string, status: AppointmentStatusType) => {
      const match = selected.find((item) => item === value);
      return (match ?? '') || status === 'taken' || status === 'margin' ? 'filled' : 'outlined';
    },
    [selected],
  );

  const getColor = useCallback((status: AppointmentStatusType) => {
    if (status === 'margin') return 'default';
    if (status === 'taken') return 'info';
    return 'primary';
  }, []);

  const handleClick = useCallback(
    (id: number) => {
      const newSelection = appointments.find((item) => item.id === id);
      if (newSelection) {
        if (isSelected(selected, newSelection.value)) return;
        dispatch({ type: appActions.launchModal });
        dispatch({ type: reservationsActions.selectTime, payload: { time: newSelection.value } });
      }
    },
    [appointments, dispatch, selected],
  );

  useEffect(() => {
    if (reservations && room !== undefined && date !== undefined) {
      const newAppointments = getAllSelectedAppointments(reservations, date, room);
      setSelected(newAppointments);
    }
  }, [reservations, date, room]);

  useEffect(() => {
    const fetchAppointments = async () => {
      const response = await getAppointments(room!, date!);
      setAppointments(response);
      const availableAppointments = response.filter((item) => item.status === 'available').map((item) => item.value);
      dispatch({ type: reservationsActions.setAvailability, payload: { availability: availableAppointments } });
    };
    if (room !== undefined && date !== undefined && room.length > 0 && date.length > 0) {
      void fetchAppointments();
    }
  }, [date, dispatch, room]);

  return (
    <StyledChipsContainer>
      {appointments.map((appointment) => (
        <AppointmentChip
          color={getColor(appointment.status)}
          id={appointment.id}
          isDisabled={appointment.status !== 'available'}
          key={appointment.id}
          label={appointment.value}
          onClick={handleClick}
          status={appointment.status}
          variant={getVariant(appointment.value, appointment.status)}
        />
      ))}
    </StyledChipsContainer>
  );
};
export default AppointmentChips;

import type { TextComponentProps, TextIndex } from '../../../utils/types/text';

import { StyledParagraph1, StyledParagraph2 } from './styles';

const Paragraph = ({ className, content, variant }: TextComponentProps) => {
  const paragraph: TextIndex = {
    paragraph1: <StyledParagraph1 className={className}>{content}</StyledParagraph1>,
    paragraph2: <StyledParagraph2 className={className}>{content}</StyledParagraph2>,
  };

  return paragraph[`paragraph${variant}`];
};

export default Paragraph;

import { ListItem } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from '../../../../../context/Hooks';
import Subtitle from '../../../../../components/ui/Subtitle';
import type { IconType } from '..';

import { StyledListItemIcon, StyledListItemButton } from './styles';

type DrawerItemProps = {
  icon: IconType;
  label: string;
  isSecondary?: boolean;
  url?: string;
  onClick?: () => void;
};

const DrawerItem = ({ icon, label, isSecondary = false, onClick, url }: DrawerItemProps) => {
  const navigate = useNavigate();
  const { setPage } = useAppContext();
  const Icon = icon;

  const handleClick = useCallback(() => {
    if (url !== undefined) {
      setPage(label);
      navigate(url);
    }
    if (onClick) {
      onClick();
    }
  }, [navigate, onClick, url, setPage, label]);
  return (
    <ListItem disablePadding onClick={handleClick}>
      <StyledListItemButton>
        <StyledListItemIcon secondary={isSecondary}>
          <Icon />
        </StyledListItemIcon>
        <Subtitle content={label} variant={4} />
      </StyledListItemButton>
    </ListItem>
  );
};

export default DrawerItem;

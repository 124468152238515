import { useTranslation } from 'react-i18next';

import { StyledTotalAmount, StyledHeading } from './styles';

type BillingTotalAmountProps = {
  amount: string;
};

const BillingTotalAmount = ({ amount }: BillingTotalAmountProps) => {
  const { t } = useTranslation('translation');
  return (
    <StyledTotalAmount>
      <StyledHeading content={t('billing.total')} variant={3} />
      <StyledHeading content={amount} variant={6} />
    </StyledTotalAmount>
  );
};

export default BillingTotalAmount;

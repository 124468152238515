import type { IAppState } from '../Initials/app';
import type { IAppAction } from '../Actions/app';
import type { IReservationsAction } from '../Actions/reservations';
import type { IMyReservationsAction } from '../Actions/myReservations';
import { appActions } from '../Actions/app';

import { reservationsReducer } from './reservations';
import { myReservationsReducer } from './myReservations';

const appBaseReducer = (state: IAppState, action: IAppAction): IAppState => {
  const { type, payload } = action;

  switch (type) {
    case appActions.setCurrentPage:
      return { ...state, currentPage: payload?.currentPage };
    case appActions.startLoading:
      return { ...state, loading: true };
    case appActions.finishLoading:
      return { ...state, loading: false };
    case appActions.launchModal:
      return { ...state, modalVisible: true };
    case appActions.closeModal:
      return { ...state, modalVisible: false };
    case appActions.setModalName:
      return { ...state, modalName: payload?.modalName };
    default:
      return state;
  }
};

const reducersArray = [appBaseReducer, reservationsReducer, myReservationsReducer];

type ReducerType = typeof appBaseReducer | typeof myReservationsReducer | typeof reservationsReducer;

type CombinedActionType = IAppAction | IMyReservationsAction | IReservationsAction;

const combineReducers = (reducers: ReducerType[]) => (state: IAppState, action: CombinedActionType) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
  reducers.reduce((newState: IAppState, reducer: any) => reducer(newState, action), state);

const appReducer = combineReducers(reducersArray);

export { appReducer };

/* eslint-disable id-length */
import React, { useLayoutEffect } from 'react';

import { useAuth } from '../../../context/Hooks';

import { StyledAnimatedContainer } from './styles';

const animations = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, x: 100 },
};

const transition = { duration: 0.6 };

const AnimatedPage = ({ children }: React.PropsWithChildren) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { isAdmin } = useAuth();
  return (
    <StyledAnimatedContainer
      animate="animate"
      exit="exit"
      initial="initial"
      isAdmin={isAdmin()}
      transition={transition}
      variants={animations}
    >
      {children}
    </StyledAnimatedContainer>
  );
};

export default AnimatedPage;

import { Button } from '@mui/material';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  border-radius: 31px;
  box-shadow: 0px 3px 8px 2px rgb(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 3px 8px 2px rgb(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 8px 2px rgb(0, 0, 0, 0.1);
  position: fixed;
  bottom: 32px;
  margin: 0;
  right: 24px;
  color: white;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
`;

export { StyledButton };

import { useCallback, useId } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List } from '@mui/material';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import { useAuth } from '../../../../context/Hooks';
import Logo from '../../../../assets/images/logo.png';

import DrawerItem from './Item';
import DrawerAccount from './Account';
import { StyledDrawerBox, StyledLogoContainer, StyledLogo, StyledList } from './styles';

type DrawerProps = {
  handleDrawerToggle: () => void;
};

type IconType = typeof LogoutOutlinedIcon;

const Drawer = ({ handleDrawerToggle }: DrawerProps) => {
  const { t } = useTranslation('translation');
  const { logoutHelper, isAdmin } = useAuth();
  const id = useId();

  const logoutItem = { label: t('layout.account.signOut'), icon: LogoutOutlinedIcon };

  const profesionalItems = [
    { label: t('layout.navbar.newReservation'), icon: InsertInvitationOutlinedIcon, url: '/reserve/new' },
    { label: t('layout.navbar.myReservations'), icon: EventAvailableOutlinedIcon, url: '/myReservations' },
    { label: t('layout.navbar.billing'), icon: AccountBalanceWalletOutlinedIcon, url: '/billing' },
    { label: t('layout.navbar.rooms'), icon: HomeOutlinedIcon, url: '/rooms' },
  ];

  const adminItems = [
    { label: t('layout.navbar.billing'), icon: AccountBalanceWalletOutlinedIcon, url: '/admin/billing' },
    { label: t('layout.navbar.rooms'), icon: HomeOutlinedIcon, url: '/admin/rooms' },
    { label: t('layout.navbar.password'), icon: VpnKeyOutlinedIcon, url: '/admin/changePassword' },
  ];
  const renderItems = isAdmin() ? adminItems : profesionalItems;

  const handleLogout = useCallback(() => {
    void logoutHelper();
  }, [logoutHelper]);

  return (
    <StyledDrawerBox onClick={handleDrawerToggle}>
      <StyledLogoContainer>
        <StyledLogo alt="Logo Casa Escondida" src={Logo} />
      </StyledLogoContainer>
      <DrawerAccount />
      <Divider />
      <StyledList>
        {renderItems.map((item) => (
          <DrawerItem icon={item.icon} key={`${item.label}-${id}`} label={item.label} url={item.url} />
        ))}
      </StyledList>
      <List>
        <DrawerItem icon={logoutItem.icon} isSecondary label={logoutItem.label} onClick={handleLogout} />
      </List>
    </StyledDrawerBox>
  );
};

export type { IconType };
export default Drawer;

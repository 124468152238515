import moment from 'moment';
import { useTranslation } from 'react-i18next';

import type { IReservation } from '../../../../context/Initials/reservations';
import type { MyReservationsParsedItemType } from '../../../../utils/api/reservations';
import { dateFormat } from '../../../../utils/schemas/datetime';
import { getRoomData } from '../../../../utils/services/rooms';
import { StyledStack, StyledStackBox, StyledStackItem, StyledSubtitle } from '../styles';

import { StyledCancelOutlinedIcon, StyledParagraph } from './styles';

type AppointmentStackItemType = IReservation & {
  id: number;
  handleClick: () => void;
};

type AppointmentStackProps = {
  items: MyReservationsParsedItemType[];
  areSelections?: boolean;
};

const formatItemDate = (date: string) => moment(date).format(dateFormat);

const AppointmentStack = ({ items, areSelections = false }: AppointmentStackProps) => {
  const { t } = useTranslation('translation');
  return (
    <StyledStackBox>
      {items.map((item) => (
        <StyledStackItem hasSubtitle key={item.id}>
          <StyledStack spacing={0}>
            <StyledSubtitle content={String(getRoomData(Number(item.room))?.name)} variant={3} />
            <StyledParagraph content={`${item.date} | ${item.startTime} - ${item.endTime}`} variant={2} />
          </StyledStack>
          {Boolean(item.cancelled) && (
            <StyledSubtitle content={t('reservations.myReservations.canceled')} variant={4} />
          )}
          {moment(areSelections ? item.date : formatItemDate(item.date), dateFormat).diff(moment(), 'days') >= 0 &&
            !item.cancelled && <StyledCancelOutlinedIcon onClick={item.handleClick} />}
        </StyledStackItem>
      ))}
    </StyledStackBox>
  );
};

export type { AppointmentStackItemType };

export default AppointmentStack;

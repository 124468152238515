import type { IAuthAction } from '../Actions/auth';
import { authActions } from '../Actions/auth';
import type { IAuthState } from '../Initials/auth';

const authReducer = (state: IAuthState, action: IAuthAction): IAuthState => {
  const { type, payload } = action;

  switch (type) {
    case authActions.loginPending:
      return { ...state, loading: true, isAuthenticated: false };
    case authActions.loginSuccessful:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        accessToken: payload?.accessToken,
        refreshToken: payload?.refreshToken,
      };
    case authActions.loginFailed:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: payload?.error,
      };
    case authActions.logoutPending:
      return { ...state, loading: true };
    case authActions.newPasswordSuccessful:
      return { ...state, newPasswordSuccessful: true, newPasswordFailed: false };
    case authActions.newPasswordFailed:
      return { ...state, newPasswordFailed: true, error: payload?.error };
    case authActions.logoutSuccessful:
      return { ...state, loading: false, isAuthenticated: false };
    case authActions.setCurrentUser:
      return { ...state, currentUser: payload?.currentUser };
    default:
      return state;
  }
};

export { authReducer };

import { apiFetch } from '.';

/* eslint-disable @typescript-eslint/naming-convention */

type RoomData = {
  id?: number;
  description?: string;
  name: string;
  price?: number;
  created_at?: string;
  updated_at?: string;
};
type RoomsResponseData = {
  data: [RoomData];
};

type DisabledDaysResponse = {
  data: string[];
};

const getRooms = async () => {
  const response = await apiFetch('GET', 'api/v1/rooms');
  const result = (await response.json()) as RoomsResponseData;
  return result.data;
};

const editRoom = async (room: RoomData) =>
  await apiFetch('PATCH', `api/v1/rooms/${room.id!}`, {
    body: { name: room.name, description: room.description },
  });

const getDisabledDays = async (roomId: string, month: string, year: string) => {
  const response = await apiFetch('GET', `api/v1/rooms/${roomId}/disabled_days?month=${month}&year=${year}`);
  const result = (await response.json()) as DisabledDaysResponse;
  return result.data;
};

export type { RoomData };
export { getRooms, editRoom, getDisabledDays };

/* eslint-enable @typescript-eslint/naming-convention */

import { apiFetch } from '.';

type CalendarEventType = { id: number; title: string; professional: string; start: string; end: string };
type ParsedCalendarEventsType = { id: number; title: string; professional: string; start: Date; end: Date }[];

type DisableRoomParameters = { roomId: string; fromDate: string; toDate: string };

const disableRoom = async (parameters: DisableRoomParameters) =>
  await apiFetch('PUT', `api/v1/rooms/${parameters.roomId}/disable`, {
    // eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
    body: { from_date: parameters.fromDate, to_date: parameters.toDate },
  });

const getCalendarEvents = async (roomId: number, from: string, to: string) => {
  const response = await apiFetch('GET', `/api/v1/rooms/${roomId}/calendar_data?from=${from}&to=${to}`);
  const result = (await response.json()) as { data: CalendarEventType[] };
  return result.data;
};

export type { CalendarEventType, ParsedCalendarEventsType };
export { disableRoom, getCalendarEvents };

import React from 'react';
import { createGlobalStyle, ThemeProvider as StyledThemeProvider } from 'styled-components';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import { colors } from './colors';
import { shadows } from './shadows';
import { typography } from './typography';
import { weights } from './weights';
import { breakpoints } from './breakpoints';

const baseTheme = {
  colors: { ...colors },
  shadows: { ...shadows },
  textSizing: { ...typography },
  weights: { ...weights },
  screenBreakpoints: { ...breakpoints },
};

/* eslint-disable @typescript-eslint/naming-convention */

const muiTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary2,
      light: colors.primary3,
      dark: colors.primary1,
    },

    secondary: {
      main: colors.secondary2,
      light: colors.secondary3,
      dark: colors.secondary1,
    },

    info: {
      main: colors.neutral2,
      light: colors.neutral3,
      dark: colors.neutral1,
    },
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        elevation: {
          boxShadow: '0px 3px 8px 2px rgb(0 0 0 / 10%)',
        },
      },
    },
  },
});

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Montserrat';
    margin:0;
    padding:0;
  }
`;

/* eslint-enable @typescript-eslint/naming-convention */

type ThemeAttribute = { [key: string]: string };
type ThemeProps = {
  children?: React.ReactNode;
};

const Theme: React.FC<ThemeProps> = ({ children }: ThemeProps) => (
  <ThemeProvider theme={muiTheme}>
    <GlobalStyle />
    <StyledThemeProvider theme={baseTheme}>{children}</StyledThemeProvider>
  </ThemeProvider>
);

export type { ThemeAttribute };
export default Theme;

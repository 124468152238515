import type { MyReservationsParsedItemType } from '../../utils/api/reservations';

interface IMyReservationsState {
  myReservationsPage: {
    myReservations?: MyReservationsParsedItemType[];
    cancelable?: MyReservationsParsedItemType;
  };
}

const initialMyReservationsState = {
  myReservationsPage: {
    myReservations: [],
  },
};

export type { IMyReservationsState };
export { initialMyReservationsState };

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { StyledButton } from './styles';

type StickyButtonProps = { content: string; path: string; icon: JSX.Element };

const StickyButton = ({ content, path, icon }: StickyButtonProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleClick = useCallback(() => {
    navigate(path);
  }, [navigate, path]);
  return (
    <StyledButton onClick={handleClick} startIcon={icon} variant="contained">
      {t(content)}
    </StyledButton>
  );
};

export default StickyButton;

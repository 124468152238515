import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../components/ui/Modal';
import ResponseAlert from '../../../../components/ui/Modal/ResponseAlert';
import { adminUsersActions } from '../../../../context/Actions/admin/users';
import { appActions } from '../../../../context/Actions/app';
import { useAdminContext, useAppContext } from '../../../../context/Hooks';
import { deleteUser } from '../../../../utils/api/user';

import { StyledParagraph } from './styles';

type ContentKeyType = 'error' | 'pending' | 'success';

const DeleteUserModal = () => {
  const { t } = useTranslation('translation');
  const { dispatch: appDispatch } = useAppContext();
  const {
    state: {
      deletableUser: { userId, fullname },
    },
    dispatch,
    modalStatus,
  } = useAdminContext();

  const handleCloseModal = useCallback(() => {
    dispatch({ type: adminUsersActions.clearError });
    dispatch({ type: adminUsersActions.clearSuccess });
    appDispatch({ type: appActions.setModalName, payload: { modalName: '' } });
    if (modalStatus === 'Success') window.location.reload();
  }, [appDispatch, dispatch, modalStatus]);

  const handleButtonAction = useCallback(async () => {
    const onSubmit = async (id: string) => {
      try {
        const response = await deleteUser({ id });
        if (response.ok) {
          dispatch({ type: adminUsersActions.setSuccess });
        } else {
          dispatch({ type: adminUsersActions.setError, payload: { errors: '' } });
        }
      } catch (responseError) {
        console.error(responseError);
      }
    };
    if (modalStatus === 'Pending') {
      await onSubmit(userId);
    } else handleCloseModal();
  }, [modalStatus, dispatch, userId, handleCloseModal]);

  const DeleteUserModalContent = useCallback(() => {
    const contents = {
      success: (
        <ResponseAlert color="success" severity="success">
          <StyledParagraph content={t('admin.billing.modals.deleteUser.contentSuccess')} variant={1} />
        </ResponseAlert>
      ),

      error: (
        <ResponseAlert color="error" severity="error">
          <StyledParagraph content={t('admin.billing.modals.deleteUser.contentError')} variant={1} />
        </ResponseAlert>
      ),

      pending: (
        <StyledParagraph
          content={t('admin.billing.modals.deleteUser.contentPending', { username: fullname })}
          variant={1}
        />
      ),
    };
    const contentKey = modalStatus.toLowerCase() as ContentKeyType;
    return contents[contentKey];
  }, [t, modalStatus, fullname]);

  return (
    <Modal
      buttonAction={handleButtonAction}
      buttonLabel={t(`admin.billing.modals.deleteUser.buttonLabel${modalStatus}`)}
      buttonLabelCancel={t('admin.billing.modals.deleteUser.buttonLabelCancel')}
      content={DeleteUserModalContent}
      handleClose={handleCloseModal}
      hasCancelButton={modalStatus === 'Pending'}
      modalName="deleteUser"
      shouldClose={modalStatus !== 'Pending'}
      title={t('admin.billing.modals.deleteUser.title')}
    />
  );
};

export default DeleteUserModal;

import type { PickersDayProps } from '@mui/x-date-pickers';
import type { PickerSelectionState } from '@mui/x-date-pickers/internals';
import type { Moment } from 'moment';

import { useAppContext } from '../../../../context/Hooks';
import { dateFormat } from '../../../../utils/schemas/datetime';

import { StyledPickersDay } from './styles';

const CalendarDay = (day: Moment, selectedDays: Moment[], pickersDayProps: PickersDayProps<Moment>) => {
  const {
    state: {
      reservationsPage: { reservations, room, disabledDays },
    },
  } = useAppContext();

  // eslint-disable-next-line react/destructuring-assignment
  const formatedDay = day.format(dateFormat);

  const hasSelections = reservations?.some((item) => item.date === formatedDay && item.room === room) ?? false;

  return (
    <StyledPickersDay
      classes={pickersDayProps.classes}
      day={day}
      disableHighlightToday={pickersDayProps.disableHighlightToday}
      disableMargin={pickersDayProps.disableMargin}
      disabled={(pickersDayProps.disabled ?? false) || disabledDays?.includes(formatedDay)}
      hasSelections={hasSelections}
      onDaySelect={pickersDayProps.onDaySelect as (day: unknown, isFinish: PickerSelectionState) => void}
      outsideCurrentMonth={pickersDayProps.outsideCurrentMonth}
      selected={pickersDayProps.selected}
      showDaysOutsideCurrentMonth={pickersDayProps.showDaysOutsideCurrentMonth}
      sx={pickersDayProps.sx}
      today={pickersDayProps.today}
    />
  );
};

export default CalendarDay;

import { z as zod } from 'zod';

const editRoomFormSchema = zod.object({
  name: zod.string().min(1, { message: 'form.errors.required' }),
  description: zod.string().min(1, { message: 'form.errors.required' }),
});

const defaultRoomFormValues = {
  name: '',
  description: '',
};

type EditRoomFormSchemaType = zod.infer<typeof editRoomFormSchema>;

export type { EditRoomFormSchemaType };
export { editRoomFormSchema, defaultRoomFormValues };

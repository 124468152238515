import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { SubmitHandler } from 'react-hook-form';

import Modal from '../../../../components/ui/Modal';
import { useAdminContext, useAppContext } from '../../../../context/Hooks';
import { getCurrentRate, setNewRate } from '../../../../utils/api/tariff';
import { adminActions } from '../../../../context/Actions/admin';
import { appActions } from '../../../../context/Actions/app';
import { ratesFormSchema } from '../../../../utils/schemas/rates';
import type { RatesFormSchemaType } from '../../../../utils/schemas/rates';

import RatesForm from './Content';

const RatesFormModal = () => {
  const { t } = useTranslation('translation');
  const { dispatch, modalStatus } = useAdminContext();
  const { dispatch: appDispatch } = useAppContext();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<RatesFormSchemaType>({
    resolver: zodResolver(ratesFormSchema),
  });

  const modalContent = useCallback(() => <RatesForm control={control} errors={errors} />, [errors, control]);

  const handleCloseModal = useCallback(() => {
    appDispatch({ type: appActions.setModalName, payload: { modalName: '' } });
    dispatch({ type: adminActions.clearRoomData });
    dispatch({ type: adminActions.clearError });
  }, [appDispatch, dispatch]);

  const buttonAction = useCallback(async () => {
    const onSubmit: SubmitHandler<RatesFormSchemaType> = async (rate: RatesFormSchemaType) => {
      try {
        const response = await setNewRate(rate);
        if (response.ok) {
          dispatch({ type: adminActions.setSuccess });
        } else {
          dispatch({ type: adminActions.setError, payload: { errors: '' } });
        }
      } catch (responseError) {
        console.error(responseError);
      }
    };
    if (modalStatus === 'Pending') {
      await handleSubmit(onSubmit)();
    } else {
      handleCloseModal();
      dispatch({ type: appActions.closeModal });
    }
  }, [modalStatus, dispatch, handleSubmit, handleCloseModal]);

  useEffect(() => {
    const fetchCurrentRate = async () => {
      const response = await getCurrentRate();
      reset({ hourPrice: String(response.hour_price), reducedPrice: String(response.reduced_price) });
    };
    void fetchCurrentRate();
  }, [handleCloseModal, reset]);

  return (
    <Modal
      buttonAction={buttonAction}
      buttonLabel={t(`admin.billing.modals.updateRate.buttonLabel${modalStatus}`)}
      content={modalContent}
      handleClose={handleCloseModal}
      modalName="updateTariff"
      shouldClose={modalStatus !== 'Pending'}
      title={t(`admin.billing.modals.updateRate.title`)}
    />
  );
};

export default RatesFormModal;

import styled from 'styled-components';
import { Button } from '@mui/material';
import { Calendar } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import Subtitle from '../../../../../../components/ui/Subtitle';

const StyledCalendar = styled(Calendar)`
  height: max-content;
  width: 95%;
  margin: 2rem auto;
  background: #fff;
  box-shadow: 1px 3px 5px 1px rgb(0 0 0 / 10%);
  border-radius: 20px;
  padding: 40px 0;
  position: relative;

  .rbc-time-content {
    border-top: none;
  }

  .rbc-allday-cell {
    display: none;
  }

  .rbc-time-header {
    top: 0;
    position: absolute;
    background: ${({ theme }) => theme.colors.primary3};
    height: 40px;
    border: none;
    left: 0;
    margin-bottom: 0rem;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .rbc-time-header-content {
    justify-content: center;
    border: none;
  }

  .rbc-header {
    overflow: hidden;
    flex: 1 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 3px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 90%;
    min-height: 0;
    border-bottom: 1px solid #ddd;
    border: none;
  }

  .rbc-button-link {
    color: black;
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 500;
  }
  .rbc-header + .rbc-header {
    border: none;
  }

  .rbc-today {
    background-color: inherit;
  }

  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background: ${({ theme }) => theme.colors.primary3};
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .rbc-day-slot .rbc-event,
  .rbc-day-slot .rbc-background-event {
    border: 1px solid white;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-event-content {
    height: fit-content;
    flex: 0;
    font-family: 'Montserrat';
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.neutral2};
  }

  .rbc-day-slot .rbc-events-container {
    margin: 0px;
  }

  .rbc-time-slot {
    flex: 1 0;
    width: 70px;
    background: #fff;
  }

  .rbc-time-view,
  .rbc-time-gutter > .rbc-timeslot-group {
    border: none;
  }
`;

const StyledInlineToolbar = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-around;
  align-items: center;
  margin: 2rem auto;
`;
const StyledNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  flex: 1;
`;
const StyledMonthAndYear = styled(Subtitle)``;
const StyledMonthNavigation = styled.div`
  margin: 0 1.5rem;
  display: inline-flex;
`;
const StyledArrowButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: ${({ theme }) => theme.colors.primary3};
    transform: scale(1.15);
  }
  transition: 0.2s ease-in-out;
`;

const StyledButtonLabel = styled.span`
  color: ${({ theme }) => theme.colors.secondary3};
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin-inline: 1rem;
  height: 56px;
`;
export {
  StyledCalendar,
  StyledInlineToolbar,
  StyledMonthAndYear,
  StyledMonthNavigation,
  StyledNavigationWrapper,
  StyledArrowButton,
  StyledButtonLabel,
  StyledButton,
};

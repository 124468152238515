import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import type { Moment } from 'moment';

import { calendarDateFormat } from '../../../../../utils/schemas/datetime';
import { adminRoomsActions } from '../../../../../context/Actions/admin/rooms';
import type { DisableReservationType } from '../../../../../context/Actions/admin/rooms';
import { useAdminContext } from '../../../../../context/Hooks';
import { StyledFormControl } from '../styles';

const DisableReservationForm = () => {
  const { t } = useTranslation('translation');
  const {
    state: { disableReservation },
    dispatch,
  } = useAdminContext();
  const { fromDate, toDate } = disableReservation as DisableReservationType;

  const handleDateSelection = useCallback(
    (name: string) => (value: Moment | null) => {
      if (!value) return;
      if (name === 'from')
        dispatch({
          type: adminRoomsActions.setDisablingDateFrom,
          payload: { disableReservation: { fromDate: value.format(calendarDateFormat) } },
        });
      else
        dispatch({
          type: adminRoomsActions.setDisablingDateTo,
          payload: { disableReservation: { toDate: value.format(calendarDateFormat) } },
        });
    },
    [dispatch],
  );

  return (
    <Stack alignItems="center" spacing={2}>
      <StyledFormControl size="small">
        <LocalizationProvider dateAdapter={AdapterMoment} locale="es">
          <MobileDatePicker
            disablePast
            inputFormat={calendarDateFormat}
            label={t('admin.billing.modals.disableReservation.labels.from')}
            onChange={handleDateSelection('from')}
            // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop, react/jsx-props-no-spreading
            renderInput={(props) => <TextField {...props} />}
            value={fromDate}
          />
        </LocalizationProvider>
      </StyledFormControl>
      <StyledFormControl size="small">
        <LocalizationProvider dateAdapter={AdapterMoment} locale="es">
          <MobileDatePicker
            disablePast
            inputFormat={calendarDateFormat}
            label={t('admin.billing.modals.disableReservation.labels.to')}
            onChange={handleDateSelection('to')}
            // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop, react/jsx-props-no-spreading
            renderInput={(props) => <TextField {...props} />}
            value={toDate}
          />
        </LocalizationProvider>
      </StyledFormControl>
    </Stack>
  );
};
export default DisableReservationForm;

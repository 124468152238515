import { apiFetch } from '.';

/* eslint-disable @typescript-eslint/naming-convention, camelcase */

type RequestNewPasswordResponse = {
  data: {
    access_token: string;
    refresh_token: string;
  };
  ok?: boolean;
  errors?: { email: string[] };
};

type NewUserType = {
  id?: number;
  email?: string;
  name: string;
  lastname: string;
  profession: string;
  phone: string;
};

const resetPassword = async ({ token, password, passwordConfirmation }: { [key: string]: string }) => {
  const body = { user: { token, password, password_confirmation: passwordConfirmation } };

  return await apiFetch('PATCH', 'auth/reset_password', { body });
};

const changePassword = async ({ password, passwordConfirmation }: { [key: string]: string }) => {
  const body = { user: { password, password_confirmation: passwordConfirmation } };

  return await apiFetch('PATCH', 'auth/change_password', { body });
};

const requestNewPassword = async ({ token, email }: { [key: string]: string }) => {
  const body = { user: { token, email } };

  return await apiFetch('POST', 'auth/email_reset_password', { body });
};

const createUser = async ({ email, name, lastname, profession, phone }: NewUserType) => {
  const body = { professional: { email, name, lastname, profession, phone_number: phone } };
  return await apiFetch('POST', '/api/v1/professionals', { body });
};

const editUser = async ({ id, name, lastname, profession, phone }: NewUserType) => {
  const body = { professional: { name, lastname, profession, phone_number: phone } };

  return await apiFetch('PATCH', `/api/v1/professionals/${id ?? ''}`, { body });
};

const deleteUser = async ({ id }: { id: string }) => await apiFetch('DELETE', `/api/v1/users/${id}`);

export type { RequestNewPasswordResponse, NewUserType };
export { resetPassword, requestNewPassword, createUser, editUser, deleteUser, changePassword };

/* eslint-enable @typescript-eslint/naming-convention, camelcase */

import { z as zod } from 'zod';

const minPasswordLength = 8;

const resetPaswordFormSchema = zod
  .object({
    password: zod.string().min(minPasswordLength, { message: 'auth.errors.passwordLength' }),
    passwordConfirmation: zod.string().min(minPasswordLength, { message: 'auth.errors.passwordLength' }),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: 'auth.errors.passwordMismatch',
    path: ['passwordConfirmation'],
  });

const loginFormSchema = zod.object({
  email: zod.string().email({ message: 'auth.errors.email' }),
  password: zod.string().min(minPasswordLength, { message: 'auth.errors.password' }),
});

const forgotPasswordFormSchema = zod.object({
  email: zod.string().email({ message: 'auth.errors.email' }),
});

type LoginFormSchemaType = zod.infer<typeof loginFormSchema>;
type ForgotPasswordSchemaType = zod.infer<typeof forgotPasswordFormSchema>;
type ResetPasswordFormSchemaType = zod.infer<typeof resetPaswordFormSchema>;

export type { LoginFormSchemaType, ResetPasswordFormSchemaType, ForgotPasswordSchemaType };
export { minPasswordLength, loginFormSchema, resetPaswordFormSchema, forgotPasswordFormSchema };

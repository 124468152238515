/* eslint-disable camelcase, @typescript-eslint/naming-convention */
import { apiFetch } from '.';

type RateType = {
  hourPrice: string;
  reducedPrice: string;
};

type RatesResponse = {
  data: {
    id: number;
    hour_price: number;
    reduced_price: number;
    daily_hours: number;
    weekly_hours: number;
    percentage_out_of_day: number;
    percentage_in_of_day: number;
    created_at: string;
    updated_at: string;
  };
};

const getCurrentRate = async () => {
  const response = await apiFetch('GET', 'api/v1/rates');
  const result = (await response.json()) as RatesResponse;
  return result.data;
};

const setNewRate = async (newRate: RateType) =>
  await apiFetch('PUT', `api/v1/update_rates`, {
    body: { hour_price: newRate.hourPrice, reduced_price: newRate.reducedPrice },
  });

export type { RateType };
export { getCurrentRate, setNewRate };

import type { IAdminPaymentState } from '../../Initials/admin/payment';
import type { IAdminAction } from '../../Actions/admin';
import { adminPaymentActions } from '../../Actions/admin/payment';

const adminPaymentReducer = (state: IAdminPaymentState, action: IAdminAction): IAdminPaymentState => {
  const { type, payload } = action;

  switch (type) {
    case adminPaymentActions.setPaymentTarget:
      return {
        ...state,

        paymentTarget: payload?.paymentTarget as {
          id: number;
          name: string;
        },
      };
    case adminPaymentActions.clearPaymentTarget:
      return { ...state, paymentTarget: { id: 0, name: '' } };
    default:
      return state;
  }
};

export { adminPaymentReducer };

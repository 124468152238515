import { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { useAdminHandlers, useAppContext } from '../../context/Hooks';
import RoomCard from '../../components/ui/Rooms/Card';
import LoadingScreen from '../../components/layout/LoadingScreen';
import AdminHeader from '../../components/ui/AdminHeader';
import AnimatedPage from '../../components/ui/AnimatedPage';
import { getRooms } from '../../utils/api/rooms';
import type { RoomData } from '../../utils/api/rooms';
import { setRoomsData } from '../../utils/services/rooms';
import RatesFormModal from '../Admin/Modals/RatesFormModal';

import { StyledGridItem, StyledGridWrapper } from './styles';

const gridColumns = { xs: 2, sm: 8, md: 12, lg: 24 };
const gridSpacing = { xs: 2, md: 3 };

const RoomsPage = ({ fromAdmin = false }: { fromAdmin?: boolean }) => {
  const [rooms, setRooms] = useState<RoomData[] | undefined>();
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const { setPage } = useAppContext();
  const { handleUpdateTariff } = useAdminHandlers();

  useEffect(() => {
    const fetchRooms = async () => {
      const response = await getRooms();
      setRooms(response);
      setRoomsData(response);
    };
    setPage(t('layout.navbar.rooms'));
    if (!rooms) void fetchRooms();
  }, [rooms, setPage, t]);

  const roomAction = useCallback(
    (room: number) => {
      navigate(String(room));
    },
    [navigate],
  );

  if (!rooms) return <LoadingScreen />;

  return (
    <AnimatedPage>
      {Boolean(fromAdmin) && (
        <AdminHeader
          buttonAction={handleUpdateTariff}
          buttonLabel={t('admin.updateTariff')}
          title={t('admin.headers.rooms')}
        />
      )}
      <StyledGridWrapper fromAdmin={fromAdmin}>
        <Grid columns={gridColumns} container spacing={gridSpacing}>
          {rooms.map((room) => (
            <StyledGridItem item key={room.id} sm={4} xs={2}>
              <RoomCard
                action={roomAction}
                actionTitle={t('rooms.action')}
                description={room.description ?? ''}
                id={room.id!}
                title={room.name}
              />
            </StyledGridItem>
          ))}
        </Grid>
      </StyledGridWrapper>
      {Boolean(fromAdmin) && <RatesFormModal />}
    </AnimatedPage>
  );
};

export default RoomsPage;

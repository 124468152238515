import styled from 'styled-components';

const StyledButtonLabel = styled.span`
  color: white;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  width: 100%;
  cursor: pointer;
`;

const StyledButtonWrapper = styled.div`
  max-width: 200px;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem auto 14px auto;
`;

const StyledContainer = styled.div`
  margin-top: 200px;
`;

export { StyledButtonLabel, StyledContainer, StyledButtonWrapper };

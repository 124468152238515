import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';

import AppointmentStack from '../../../components/ui/Stack/Appointments';
import Heading from '../../../components/ui/Heading';
import Paragraph from '../../../components/ui/Paragraph';
import PageContainer from '../../../components/ui/PageContainer';
import AnimatedPage from '../../../components/ui/AnimatedPage';
import { getReservationsData, removeReservationData } from '../../../utils/services/reservations';
import { reserveAppointments } from '../../../utils/api/reservations';
import { increaseTimeByHalfHour } from '../../../utils/helpers/reservations';
import { reservationsActions } from '../../../context/Actions/reservations';
import type { IReservation } from '../../../context/Initials/reservations';
import { appActions } from '../../../context/Actions/app';
import { useAppContext } from '../../../context/Hooks';

import ConfirmModal from './ConfirmModal';
import { StyledButtonLabel } from './styles';

const lastItemIndex = -1;

const ConfirmReservationPage = () => {
  const { t } = useTranslation('translation');
  const { dispatch } = useAppContext();
  const reservations = getReservationsData();
  const hasReservations = (reservations && reservations.length > 0) ?? false;

  const handleConfirm = useCallback(() => {
    dispatch({ type: appActions.launchModal });
    const fetchReserve = async (data: IReservation[]) => {
      const response = await reserveAppointments(data);
      if (response.success) dispatch({ type: reservationsActions.setSuccess, payload: { successResponse: true } });
      else dispatch({ type: reservationsActions.setError, payload: { errorResponse: true } });
    };
    if (reservations) void fetchReserve(reservations);
  }, [reservations, dispatch]);

  const handleCancel = useCallback(() => {
    window.history.back();
  }, []);

  const items = reservations?.map((reservation, index) => ({
    id: index,
    room: reservation.room,
    date: reservation.date,
    startTime: reservation.appointments[0],
    cancelled: false,
    endTime: increaseTimeByHalfHour(reservation.appointments.at(lastItemIndex)!, 1),

    handleClick: () => {
      dispatch({ type: reservationsActions.removeReservation, payload: { id: index } });
      removeReservationData(index);
    },
  }));

  return (
    <AnimatedPage>
      <PageContainer>
        <Heading content={t('reservations.confirm.title')} variant={3} />
        {hasReservations ? (
          <AppointmentStack areSelections items={items!} />
        ) : (
          <Paragraph content={t('reservations.empty')} variant={2} />
        )}
        <Stack spacing={2}>
          <Button disabled={!hasReservations} onClick={handleConfirm} variant="contained">
            <StyledButtonLabel>{t('reservations.confirm.buttonConfirm')}</StyledButtonLabel>
          </Button>
          <Button onClick={handleCancel} variant="outlined">
            <StyledButtonLabel secondary>{t('reservations.confirm.buttonCancel')}</StyledButtonLabel>
          </Button>
        </Stack>
        <ConfirmModal />
      </PageContainer>
    </AnimatedPage>
  );
};

export default ConfirmReservationPage;

import type { IMyReservationsState } from '../Initials/myReservations';
import type { IMyReservationsAction } from '../Actions/myReservations';
import { myReservationsActions } from '../Actions/myReservations';

const myReservationsReducer = (state: IMyReservationsState, action: IMyReservationsAction): IMyReservationsState => {
  const { type, payload } = action;

  switch (type) {
    case myReservationsActions.setMyReservations:
      return {
        ...state,
        myReservationsPage: { ...state.myReservationsPage, myReservations: payload.myReservations! },
      };
    case myReservationsActions.setCancelable:
      return { ...state, myReservationsPage: { ...state.myReservationsPage, cancelable: payload.cancelable! } };
    default:
      return state;
  }
};

export { myReservationsReducer };

import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountCircleOutlined, HomeOutlined } from '@mui/icons-material';

import AdminHeader from '../../../components/ui/AdminHeader';
import AnimatedPage from '../../../components/ui/AnimatedPage';

import UserBillingTable from './UserBilling/Table';
import RoomBillingTable from './RoomBilling/Table';
import { StyledTab, StyledTabs, StyledPageContent } from './styles';

type TabsType = 0 | 1;

const tableByType = {
  tab0: <UserBillingTable />,
  tab1: <RoomBillingTable />,
};

const AdminBillingPage = () => {
  const { t } = useTranslation('translation');
  const [currentTab, setCurrentTab] = useState<TabsType>(0);

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: TabsType) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <AnimatedPage>
      <AdminHeader title={t('admin.headers.billing')} />
      <StyledPageContent>
        <StyledTabs onChange={handleTabChange} value={currentTab}>
          <StyledTab icon={<AccountCircleOutlined />} iconPosition="start" label={t('admin.billing.tabs.user')} />
          <StyledTab icon={<HomeOutlined />} iconPosition="start" label={t('admin.billing.tabs.room')} />
        </StyledTabs>
        {tableByType[`tab${currentTab}`]}
      </StyledPageContent>
    </AnimatedPage>
  );
};

export default AdminBillingPage;

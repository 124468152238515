import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow, IconButton, Collapse, Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import type { RowType } from '../../../../../utils/api/admin-billing';
import BillingTotalAmount from '../../../../../components/ui/BillingTotalAmount';
import { useAdminHandlers } from '../../../../../context/Hooks';
import RegisterPaymentModal from '../../../Modals/RegisterPaymentModal';
import {
  StyledBorderlessTableRow,
  StyledTableCell,
  StyledTableCellVertical,
  StyledCollapsedContent,
  StyledBillingStack,
  StyledCollapsedContentInner,
  StyledSubtitle,
  StyledButtonLabel,
  StyledRowButtonsContainer,
} from '../../styles';

const UserBillingRow = ({ row }: RowType) => {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation('translation');
  const { handleRegisterPayment, handleDeleteUser, handleOpenUserDetail, handleEditUser } = useAdminHandlers();

  const fullname = `${row.name} ${row.lastname}`;

  const handleRowOpening = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  return (
    <>
      <StyledBorderlessTableRow>
        <StyledTableCell align="center" component="th" scope="row">
          {fullname}
        </StyledTableCell>
        <StyledTableCell align="center">{row.profession}</StyledTableCell>
        <StyledTableCell align="center">{row.phone}</StyledTableCell>
        <StyledTableCell align="center">{row.amount}</StyledTableCell>
        <StyledTableCell>
          <IconButton onClick={handleEditUser(row)} size="small">
            <ModeEditOutlinedIcon />
          </IconButton>
          <IconButton onClick={handleDeleteUser({ userId: String(row.userId), fullname })} size="small">
            <DeleteOutlinedIcon />
          </IconButton>
          <IconButton onClick={handleRowOpening} size="small">
            {opened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
      </StyledBorderlessTableRow>
      <TableRow>
        <StyledTableCellVertical colSpan={6}>
          <Collapse in={opened} timeout="auto" unmountOnExit>
            <StyledCollapsedContent>
              <StyledCollapsedContentInner>
                <StyledSubtitle content={t('billing.lastPeriod')} variant={1} />
                <StyledBillingStack items={row.lastPeriodBilling} />
                <BillingTotalAmount amount={row.amount} />
              </StyledCollapsedContentInner>
              <StyledRowButtonsContainer>
                <Button id={String(row.userId)} onClick={handleOpenUserDetail(row)} variant="text">
                  <StyledButtonLabel>{t('billing.openDetail')}</StyledButtonLabel>
                </Button>
                <Button
                  id={String(row.userId)}
                  onClick={handleRegisterPayment({ id: row.userId, name: fullname })}
                  variant="outlined"
                >
                  <StyledButtonLabel>{t('billing.registerPayment')}</StyledButtonLabel>
                </Button>
              </StyledRowButtonsContainer>
            </StyledCollapsedContent>
          </Collapse>
        </StyledTableCellVertical>
      </TableRow>
      <RegisterPaymentModal />
    </>
  );
};

export default UserBillingRow;

import { useEffect, useState } from 'react';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';

import {
  StyledHeading,
  StyledSubtitle,
  StyledAdminHeader,
  StyledCol,
  StyledAvatar,
  StyledButtonLabel,
  StyledButton,
} from './styles';

type AdminHeaderProps = {
  buttonAction?: () => void;
  buttonLabel?: string;
  isProfile?: boolean;
  subtitle?: string;
  title: string;
  initials?: string;
};

const AdminHeader = ({ title, subtitle, isProfile = false, initials, buttonAction, buttonLabel }: AdminHeaderProps) => {
  const [scrolled, setScrolled] = useState<boolean>(false);

  const scrollThreshold = 100;

  const handleScroll = () => {
    setScrolled(window.scrollY > scrollThreshold);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <StyledAdminHeader scrolled={scrolled}>
      {Boolean(isProfile) && <StyledAvatar>{initials}</StyledAvatar>}
      <StyledCol>
        <StyledHeading content={title} variant={1} />
        {!scrolled && Boolean(subtitle) && <StyledSubtitle content={subtitle!} variant={1} />}
      </StyledCol>
      {Boolean(buttonAction) && (
        <StyledButton onClick={buttonAction} variant="contained">
          <StyledButtonLabel>
            {buttonLabel}
            <PriceChangeOutlinedIcon />
          </StyledButtonLabel>
        </StyledButton>
      )}
    </StyledAdminHeader>
  );
};

export default AdminHeader;

import { motion } from 'framer-motion';
import styled from 'styled-components';

type AnimatedContainerProps = { isAdmin: boolean };

const StyledAnimatedContainer = styled(motion.div)<AnimatedContainerProps>`
  height: 100%;
  width: 100%;
  top: ${({ isAdmin = false }) => (isAdmin ? '0' : '56px')};
  position: absolute;
`;

export { StyledAnimatedContainer };

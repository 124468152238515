import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, MenuItem, Alert } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material/Select';

import Modal from '../../../../components/ui/Modal';
import { useAppContext } from '../../../../context/Hooks';
import { reservationsActions } from '../../../../context/Actions/reservations';
import {
  appointmentsFromTimeAndDuration,
  getDurationOptions,
  trimLastAvaiabilityBlocks,
} from '../../../../utils/helpers/reservations';

import { StyledInputLabel, StyledFormControl, StyledSelect } from './styles';

const ModalReservationForm = () => {
  const { t } = useTranslation('translation');
  const { state, dispatch } = useAppContext();
  const availability = state.reservationsPage.availability!;
  const selectableAvailability = trimLastAvaiabilityBlocks(availability);
  const [time, setTime] = useState<string>(state.reservationsPage.time!);
  const [durationOptions, setDurationOptions] = useState<string[]>([]);

  const handleTime = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as string;
      dispatch({ type: reservationsActions.selectTime, payload: { time: value } });
      setTime(value);
    },
    [dispatch],
  );

  const handleDuration = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      dispatch({ type: reservationsActions.selectDuration, payload: { duration: event.target.value as string } });
    },
    [dispatch],
  );

  useEffect(() => {
    const options = getDurationOptions(time, availability);
    setDurationOptions(options);
  }, [time, availability]);

  return (
    <Stack alignItems="center" spacing={2}>
      <StyledFormControl size="small">
        <StyledInputLabel id="time-label">{t('reservations.modal.time')}</StyledInputLabel>
        <StyledSelect displayEmpty labelId="time-label" onChange={handleTime} value={time}>
          {selectableAvailability.map((timeItem) => (
            <MenuItem key={timeItem} value={timeItem}>
              {timeItem}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
      <StyledFormControl size="small">
        <StyledInputLabel id="duration-label">{t('reservations.modal.duration')}</StyledInputLabel>
        <StyledSelect
          disabled={durationOptions.length === 0}
          displayEmpty
          labelId="duration-label"
          onChange={handleDuration}
        >
          {durationOptions.map((durationItem) => (
            <MenuItem key={durationItem} value={durationItem}>
              {`${durationItem} ${
                Number(durationItem) > 1 ? t('reservations.modal.hours') : t('reservations.modal.hour')
              }`}
            </MenuItem>
          ))}
        </StyledSelect>
        {durationOptions.length === 0 && <Alert severity="info">{t('reservations.modal.hoursMin')}</Alert>}
      </StyledFormControl>
    </Stack>
  );
};

const ReservationModal = () => {
  const { t } = useTranslation('translation');
  const {
    state: { reservationsPage },
    dispatch,
  } = useAppContext();

  const handleClose = useCallback(() => {
    dispatch({ type: reservationsActions.selectDuration, payload: { duration: '' } });
  }, [dispatch]);

  const handleModalAction = useCallback(() => {
    const reservation = {
      date: reservationsPage.date!,
      room: reservationsPage.room!,

      appointments: appointmentsFromTimeAndDuration(reservationsPage.time!, Number(reservationsPage.duration)),
    };
    dispatch({ type: reservationsActions.submitSelection, payload: { reservation } });
    handleClose();
  }, [handleClose, reservationsPage, dispatch]);

  return (
    <Modal
      buttonAction={handleModalAction}
      buttonLabel={t('reservations.modal.buttonLabel')}
      content={ModalReservationForm}
      handleClose={handleClose}
      isButtonDisabled={reservationsPage.duration === undefined || reservationsPage.duration === ''}
      title={t('reservations.modal.title')}
    />
  );
};

export default ReservationModal;

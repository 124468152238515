import type { NewUserType } from '../../../utils/api/user';

const adminUsersActions = {
  setNewUserData: 'SET_NEW_USER_DATA',
  setSuccess: 'SET_SUCCESS',
  setError: 'SET_ERROR',
  clearSuccess: 'CLEAR_SUCCESS',
  clearError: 'CLEAR_ERROR',
  clearUserData: 'CLEAR_USER_DATA',
  setEditingUser: 'SET_EDITING_USER',
  clearEditingUser: 'CLEAR_EDITING_USER',
  setDeletableUser: 'SET_DELETABLE_USER',
};

type AdminUsersPayloadType = {
  userData?: NewUserType;
  errors?: string;
  deletableUser?: { userId: string; fullname: string };
};

export type { AdminUsersPayloadType };
export { adminUsersActions };

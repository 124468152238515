import { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import type { Dispatch, SetStateAction } from 'react';

import { authReducer } from '../Reducers/auth';
import { authActions } from '../Actions/auth';
import { initialAuthState } from '../Initials/auth';
import { currentUser, login, logout } from '../../utils/api/auth';
import { setAuthData, removeAuthData, getAuthData } from '../../utils/services/auth';
import type { AuthResponseData } from '../../utils/api/auth';
import type { IAuthAction } from '../Actions/auth';
import type { IAuthState } from '../Initials/auth';

interface IAuthContext {
  loginHelper: (user: { email: string; password: string }) => Promise<void>;
  logoutHelper: () => Promise<void>;
  state: IAuthState;
  dispatch: Dispatch<IAuthAction>;
  isAdmin: () => boolean;
  togglePassword: (setShowPassword: Dispatch<SetStateAction<boolean>>) => void;
}

const authStateContext = createContext<IAuthContext | undefined>(undefined);

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation('translation');
  const [state, dispatch] = useReducer(authReducer, initialAuthState);

  const parseLoginResponse = useCallback((result: AuthResponseData, response: Response) => {
    if (response.ok) {
      const payload = {
        accessToken: result.data.access_token,
        refreshToken: result.data.refresh_token,
      };
      setAuthData(payload);
      dispatch({
        type: authActions.loginSuccessful,
        payload,
      });
      window.location.replace('/');
    } else {
      dispatch({
        type: authActions.loginFailed,
        payload: { error: result.errors?.login },
      });
    }
  }, []);
  const loginHelper = useCallback(
    async (user: { email: string; password: string }) => {
      try {
        dispatch({ type: authActions.loginPending });
        const response = await login(user.email, user.password);
        const parsedResponse = (await response.json()) as AuthResponseData;
        parseLoginResponse(parsedResponse, response);
      } catch {
        dispatch({
          type: authActions.loginFailed,
          payload: { error: t('auth.errors.message') },
        });
      }
    },
    [t, parseLoginResponse],
  );

  const logoutHelper = useCallback(async () => {
    dispatch({ type: authActions.logoutPending });
    try {
      await logout();
    } catch (error) {
      console.error(error);
    } finally {
      removeAuthData();
      dispatch({ type: authActions.logoutSuccessful });
      window.location.replace('/login');
    }
  }, []);

  const isAdmin = useCallback(() => state.currentUser?.type === 'Admin', [state.currentUser]);

  const togglePassword = useCallback((setShowPassword: Dispatch<SetStateAction<boolean>>) => {
    setShowPassword((previousState) => !previousState);
  }, []);

  const authValue = useMemo(
    () => ({ isAdmin, loginHelper, logoutHelper, state, dispatch, togglePassword }),
    [state, loginHelper, logoutHelper, isAdmin, dispatch, togglePassword],
  );

  useEffect(() => {
    const retrieveCurrentUser = async () => {
      const userInfo = await currentUser();
      dispatch({
        type: authActions.setCurrentUser,

        payload: {
          currentUser: userInfo.data,
        },
      });
    };
    if (getAuthData().accessToken) void retrieveCurrentUser();
  }, []);

  return <authStateContext.Provider value={authValue}>{children}</authStateContext.Provider>;
};

export { AuthProvider, authStateContext };

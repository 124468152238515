import Alert from '@mui/material/Alert';
import styled from 'styled-components';

const StyledAlert = styled(Alert)`
  background: none;
  box-shadow: none;
  justify-content: center;
  align-items: center;
  &:p {
    font-size: 1.25rem;
    font-family: 'Montserrat';
    font-weight: lighter;
  }
`;

export { StyledAlert };

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { SyntheticEvent, ReactEventHandler, MouseEvent as ReactMouseEvent } from 'react';
import { useParams } from 'react-router-dom';

import ImagePreview from '../../../../../../components/ui/ImagePreview';
import { getRoomPictureUrl } from '../../../../../../utils/helpers/rooms';
import { useAdminHandlers } from '../../../../../../context/Hooks';
import type { RoomData } from '../../../../../../utils/api/rooms';

import {
  StyledRoomDetailContainer,
  StyledDetailRow,
  StyledHeading,
  StyledParagraph,
  StyledImgGridItem,
  StyledAutoGrid,
  StyledImg,
  StyledSkeleton,
  StyledButtonLabel,
  StyledButton,
  StyledRightCol,
  StyledLeftCol,
} from './styles';

type ImageLoadedType = {
  [key: string]: boolean;
};

// eslint-disable-next-line max-statements
const RoomDetailBox = ({ room }: { room: RoomData }) => {
  const { handleEditRoom } = useAdminHandlers();
  const { t } = useTranslation('translation');
  const { id } = useParams();
  const [showPreview, setShowPreview] = useState<string>('');
  const [imageLoaded, setImageLoaded] = useState<ImageLoadedType>({
    image0: false,
    image1: false,
    image2: false,
    image3: false,
  });
  const [imageSource, setImageSource] = useState<string[]>([]);
  const numberId = Number(id);
  const parsedRoomData = { row: { roomId: numberId, name: room.name, description: room.description ?? '' } };

  const handleImageClick = useCallback((event: ReactMouseEvent<HTMLImageElement>) => {
    const target = event.target as HTMLImageElement;
    setShowPreview(target.src);
  }, []);

  const handleClose = useCallback(() => {
    setShowPreview('');
  }, []);

  const handleImageLoad: ReactEventHandler<HTMLImageElement> = useCallback(
    (event: SyntheticEvent<HTMLImageElement>) => {
      const imageId = Number((event.target as Element).id);
      setImageLoaded((previous) => ({ ...previous, [`image${imageId}`]: true }));
    },
    [],
  );

  useEffect(() => {
    if (imageSource.length === 0) {
      const numberArray: number[] = Array.from(Array.from({ length: 4 }).keys());
      numberArray.forEach((item: number) => {
        setImageSource((previous) => [...previous, getRoomPictureUrl(numberId, item)]);
      });
    }
  }, [imageSource, numberId]);

  return (
    <StyledRoomDetailContainer>
      <StyledDetailRow>
        <StyledLeftCol>
          <StyledHeading content={t('admin.roomDescription')} variant={6} />
          <StyledParagraph content={room.description ?? ''} variant={2} />
          <StyledButton onClick={handleEditRoom(parsedRoomData)} variant="outlined">
            <StyledButtonLabel>{t('admin.editRoom')}</StyledButtonLabel>
          </StyledButton>
        </StyledLeftCol>
        <StyledRightCol>
          <StyledAutoGrid container>
            {imageSource.length > 0 &&
              imageSource.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <StyledImgGridItem item key={index} xs={6}>
                  {!imageLoaded[`image${index}`] && <StyledSkeleton variant="rectangular" />}
                  <StyledImg
                    alt="Consultorio"
                    id={String(index)}
                    loaded={imageLoaded[`image${index}`]}
                    onClick={handleImageClick}
                    onLoad={handleImageLoad}
                    src={item}
                  />
                </StyledImgGridItem>
              ))}
          </StyledAutoGrid>
        </StyledRightCol>
      </StyledDetailRow>
      {Boolean(showPreview) && <ImagePreview handleClose={handleClose} image={showPreview} />}
    </StyledRoomDetailContainer>
  );
};

export default RoomDetailBox;

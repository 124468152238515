import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { ControllerRenderProps, SubmitHandler } from 'react-hook-form';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Container, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { useAuth } from '../../../context/Hooks';
import { loginFormSchema } from '../../../utils/schemas/auth';
import type { LoginFormSchemaType } from '../../../utils/schemas/auth';
import RenderField from '../../../components/form/Field';
import Heading from '../../../components/ui/Heading';
import Subtitle from '../../../components/ui/Subtitle';
import Logo from '../../../assets/images/logo.png';
import {
  StyledLogo,
  StyledPage,
  StyledContainer,
  StyledFormContainer,
  StyledButtonWrapper,
  StyledButtonLabel,
  StyledLink,
} from '../styles';

type LoginFieldType = {
  field: ControllerRenderProps<LoginFormSchemaType, 'email'> | ControllerRenderProps<LoginFormSchemaType, 'password'>;
};

const baseInputProps = {
  label: { shrink: true },
};

/* eslint-disable react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop, @typescript-eslint/no-misused-promises  */
// eslint-disable-next-line max-statements
const LoginPage = () => {
  const { t } = useTranslation('translation');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { state, loginHelper } = useAuth();
  const navigate = useNavigate();
  const togglePassword = useCallback(() => {
    setShowPassword((previousState) => !previousState);
  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginFormSchemaType>({
    resolver: zodResolver(loginFormSchema),
  });

  const propsByField = {
    email: baseInputProps,

    password: {
      ...baseInputProps,

      adornment: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={togglePassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
          </InputAdornment>
        ),
      },
    },
  };

  const onSubmit: SubmitHandler<LoginFormSchemaType> = async (user: LoginFormSchemaType) => {
    try {
      await loginHelper(user);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRequestNewPassword = () => {
    navigate('/forgotPassword');
  };
  const renderLoginField = ({ field: { onChange, onBlur, value, name, ref: reference } }: LoginFieldType) => (
    <RenderField
      errors={errors[name]?.message}
      inputProps={propsByField[name]}
      inputType={name !== 'password' || showPassword ? 'text' : 'password'}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      reference={reference}
      value={value}
    />
  );

  return (
    <StyledPage>
      <Container maxWidth="sm">
        <StyledContainer>
          <StyledLogo alt="Logo Casa Escondida" src={Logo} />
        </StyledContainer>
        <StyledFormContainer>
          <Heading content={t('auth.welcome')} variant={2} />
          <Subtitle content={t('auth.fields.email')} variant={3} />
          <Controller control={control} defaultValue="" name="email" render={renderLoginField} />
          <Subtitle content={t('auth.fields.password')} variant={3} />
          <Controller control={control} defaultValue="" name="password" render={renderLoginField} />
          <StyledButtonWrapper>
            <Button fullWidth onClick={handleSubmit(onSubmit)} variant="contained">
              <StyledButtonLabel>{t('auth.fields.submit')}</StyledButtonLabel>
            </Button>
          </StyledButtonWrapper>
          <StyledLink onClick={handleRequestNewPassword}>{t('auth.fields.forgotPassword')}</StyledLink>
          {Boolean(state.error) && (
            <Alert severity="error" variant="outlined">
              {state.error}
            </Alert>
          )}
          {Boolean(state.newPasswordSuccessful) && (
            <Alert severity="success" variant="outlined">
              {t('auth.forgotPasswordRequested')}
            </Alert>
          )}
        </StyledFormContainer>
      </Container>
    </StyledPage>
  );
};

export default LoginPage;

/* eslint-enable react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop, @typescript-eslint/no-misused-promises  */
